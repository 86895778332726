<script>
import draggable from 'vuedraggable'
import render from '../components/render/render';

const components = {
  itemBtns(h, currentItem, index, list) {
    const { copyItem, deleteItem,contextShow } = this.$listeners;
    let items = [];
    //系统组件不允许复制
    if(this.isTask){
    }
    else if(this.isApprove && currentItem?.__config__?.field == 'village'){}
    else if (currentItem?.__config__?.type == 'TYPE_SYSTEM') {
      items.push(<div class="drawing-operation">
          <div class="drawing-item-delete" title="删除" onClick={event => {
          if (this.suiteLock) {
            this.$message.error('套件已经在流程中引用，无法删除');
            return;
          }
          deleteItem(index, list); event.stopPropagation()
        }}>
          <i class="el-icon-delete" />
        </div></div>)
    } else if(currentItem?.__config__?.type != 'TYPE_INPUT' || (!this.isTask && currentItem?.__config__?.type == 'TYPE_INPUT')) {
      items.push(<div class="drawing-operation"><div class="drawing-item-copy" title="复制" onClick={event => {
        copyItem(currentItem, list); event.stopPropagation()
      }}>
        <i class="el-icon-copy-document" />
      </div><div class="fxy-divider"></div>
        <div class="drawing-item-delete" title="删除" onClick={event => {
          deleteItem(index, list); event.stopPropagation()
        }}>
          <i class="el-icon-delete" />
        </div></div>)
    }
    return items;
  }
}

let formId = 126895;

const layouts = {
  /**
   * 组件生成方法
   *
   * 自定义组件一概使用slot渲染
   *
   */
  colFormItem(h, currentItem, index, list) {
    const { activeItem,contextShow } = this.$listeners
    const config = currentItem.__config__;
    const child = renderChildren.apply(this, arguments);
    const showLabel = config?.expandParams?.showLabel !== false;
    const labelLeft = !!config?.expandParams?.labelLeft;
    config.formId = config?.formId ? config.formId : formId++;
    let className = this.activeId === config.formId ? 'drawing-item active-from-item' : 'drawing-item';
    if (this.formConf.unFocusedComponentBorder) className += ' unfocus-bordered'
    if (config.tagIcon == 'edit') className += ' edit-form-item'
    className += config?.isGroup ? ' kit-box' : ' default-item-box'
    let labelWidth = config.labelWidth ? `${config.labelWidth}px` : 'auto';
    let dialogTips = config?.expandParams?.dialogTips;
    let dialogTipsShow = 'dialogTips' in (config?.expandParams || {});
    let isSystemItem = config.type == 'TYPE_SYSTEM'
    let isInputItem = config.type == 'TYPE_INPUT';
    let descEdit = config.expandParams?.descEdit;
    let descEditIsEmpty = descEdit == '<p><br></p>' || descEdit == '' || !descEdit;
    let formClassName = `${!showLabel && config.tagIcon == 'divider' ? ' hide--mb' : ''}${!showLabel ? ' hide-label' : ''}${labelLeft ? ' el-form-item--left' : ''}${showLabel && dialogTipsShow ? ' el-form-item--drag' : ''}`
    if (showLabel === false) labelWidth = '0';
    return (
      <el-col span={config.span} class={className}
        nativeOnClick={event => { activeItem(currentItem); event.stopPropagation() }}>
        <el-form-item label-width={labelWidth}
          label={showLabel ? config.label : ''} required={config.required} class={formClassName}>
          {showLabel && dialogTipsShow  ? <template slot='label'>
            <div class={'form-item-label r-flex'+(isInputItem ? ' r-flex-column r-col-top' : '')}>
              <span>{config.label}</span>
              {dialogTips && isSystemItem ? <r-icon name="question" size="16" color="var(--primary)" hover left={'5'} nativeOnClick={event => {this.$message(dialogTips)}}></r-icon> : ''}
              {dialogTips && isInputItem ? !descEditIsEmpty ? <el-link class={'input-item-dialog-tips'} type='primary' nativeOnClick={event => {contextShow(descEdit)}}>{dialogTips}</el-link> : <el-link class={'input-item-dialog-tips info'} type='info' underline={false}>{dialogTips}</el-link> : ''}
            </div>
          </template> : ''}
          <render key={config.renderKey} conf={currentItem} onInput={event => {
            this.$set(config, 'defaultValue', event)
          }}>
            {child}
          </render>
        </el-form-item>
        {components.itemBtns.apply(this, arguments)}
      </el-col>
    )
  },
  rowFormItem(h, currentItem, index, list) {
    const { activeItem } = this.$listeners
    const config = currentItem.__config__
    const className = this.activeId === config.formId
      ? 'drawing-row-item active-from-item'
      : 'drawing-row-item'
    let child = renderChildren.apply(this, arguments)
    if (currentItem.type === 'flex') {
      child = <el-row type={currentItem.type} justify={currentItem.justify} align={currentItem.align}>
        {child}
      </el-row>
    }
    return (
      <el-col span={config.span}>
        <el-row gutter={config.gutter} class={className}
          nativeOnClick={event => { activeItem(currentItem); event.stopPropagation() }}>
          <span class="component-name">{config.componentName}</span>
          <draggable list={config.children || []} animation={340}
            group="componentsGroup" class="drag-wrapper">
            {child}
          </draggable>
          {components.itemBtns.apply(this, arguments)}
        </el-row>
      </el-col>
    )
  },
  raw(h, currentItem, index, list) {
    const config = currentItem.__config__
    const child = renderChildren.apply(this, arguments)
    return <render key={config.renderKey} conf={currentItem} onInput={event => {
      this.$set(config, 'defaultValue', event)
    }}>
      {child}
    </render>
  }
}

function renderChildren(h, currentItem, index, list) {
  const config = currentItem.__config__
  if (!Array.isArray(config.children)) return null
  return config.children.map((el, i) => {
    const layout = layouts[el.__config__.layout]
    if (layout) {
      return layout.call(this, h, el, i, config.children)
    }
    return layoutIsNotFound.call(this)
  })
}

function layoutIsNotFound() {
  throw new Error(`没有与${this.currentItem.__config__.layout}匹配的layout`)
}


export default {
  components: {
    render,
    draggable
  },
  inject: ['id','task','approve'],
  computed: {
    suiteLock() {
      return this.$store.state.approve.suiteLock;
    },
    isTask(){
      return this.task.task;
    },
    isApprove(){
      return !!this.approve?.approve;
    }
  },
  props: [
    'currentItem',
    'index',
    'drawingList',
    'activeId',
    'formConf'
  ],
  render(h) {
    const layout = layouts[this.currentItem.__config__.layout]

    if (layout) {
      return layout.call(this, h, this.currentItem, this.index, this.drawingList)
    }
    return layoutIsNotFound.call(this)
  }
}
</script>
