<template>
  <div class="dialog-cover" v-loading="loading" element-loading-text="加载中...">
    <div class="dialog-cover-detail" v-show="!isResubmit">
      <div class="dialog-cover-ctn r-flex r-col-top">
        <div class="dialog-apply-content r-flex-auto-w">
          <div class="employee-info">
            <div class="avatar"><img :src="avatar | imgUrl" alt="" v-avatar /></div>
            <div class="info-content">
              <div class="name">{{ employee.nickname || '暂无' }}</div>
              <div class="apply-status">{{ statusTips }}</div>
            </div>
            <div class="stauts-icon">
              <img :src="'wx-image/approval_revoke.png' | imgUrl" mode="" v-if="status == 4" />
              <!-- 待执行 -->
              <img :src="'wx-image/approval_handle_await.png' | imgUrl" mode="" v-else-if="status == 0 && type == 5" />
              <!-- 已执行 -->
              <img :src="'wx-image/approval_handle.png' | imgUrl" mode="" v-else-if="status == 1 && type == 5" />
              <!-- 已同意 -->
              <img :src="(isAuditor ? 'wx-image/approval_success.png' : 'wx-image/approval_success_me.png') | imgUrl"
                mode="" v-else-if="status == 1 && type != 5" />
              <img :src="'wx-image/approval_faild_n.png' | imgUrl" mode="" v-else-if="status == 2 && type != 5" />
            </div>
          </div>
          <div class="approver-info status-tips">{{ approvalTitle }}
          </div>
          <div class="section detail-main">
            <div class="apply-user-info apply-item-cover">
              <div class="item">
                <div class="label">审批编号：</div>
                <div class="value">{{ approvalNumber }}</div>
              </div>
              <div class="item">
                <div class="label">所在部门：</div>
                <div class="value">{{ employee.department || "-" }}</div>
              </div>
              <div class="item">
                <div class="label">职位：</div>
                <div class="value">{{ position || "-" }}</div>
              </div>
            </div>
            <div class="apply-item-cover">
              <template v-for="(item, index) in list">
                <div class="item date-info max" :key="index" v-if="item.date">
                  <div class="des u-flex u-col-center u-row-between">
                    <span>{{ item.label }}</span>
                  </div>
                  <div class="date-horizon">
                    <div class="date-cover">
                      <div class="date-item" v-for="(_item, _index) in item.date" :key="_index">
                        <div class="line-wrapper">
                          <div class="line-cover">
                            <div class="point"></div>
                          </div>
                        </div>
                        <span>{{ _item }}</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="item block" :key="index" v-else-if="item.block">
                  <div class="item-block-title u-flex u-col-center u-row-between">
                    <span>{{ suiteNameText[suiteName] || '详情' }}</span>
                    <span>{{ item.label }}</span>
                  </div>
                  <div class="item-block-cover r-flex r-flex-wrap">
                    <div class="item-block_item" v-for="(_item, _index) in item.block" :key="_index">
                      <div class="item-block_item-label">{{ _item.label }}：</div>
                      <div class="item-block_item-value">{{ _item.value }}</div>
                    </div>
                  </div>
                </div>
                <div class="item block max table" :key="index" v-else-if="item.table">
                  <div class="item-block-title">
                    <span>{{ item.label }}</span>
                  </div>
                  <div class="item-block-cover r-flex r-flex-wrap">
                    <el-table size="mini" :data="item.value" border>
                      <el-table-column v-for="_item in item.tableColumns" :key="_item.prop" :label="_item.label"
                        :prop="_item.prop" show-overflow-tooltip min-width="100">
                        <template slot-scope="scope">
                          <div class="r-flex r-row-bet r-col-center"
                            v-if="_item.prop == 'detailedType' && ['reimbursementSuite', 'adReimbursementSuite'].includes(suiteName) && status == 1 && $has('approve-detailed-type-edit')">
                            <span class="r-flex-auto-w" style="white-space: wrap;">{{ scope.row[_item.prop] }}</span>
                            <el-button type="text" size="small"
                              @click="editDetailedType(_item, scope.row)">修改</el-button>
                          </div>
                          <div class="r-flex r-row-bet r-col-center"
                            v-else-if="_item.prop == 'detailedDepartment' && ['reimbursementSuite', 'adReimbursementSuite'].includes(suiteName) && status == 1 && $has('approve-detailed-department-edit')">
                            <span class="r-flex-auto-w" style="white-space: wrap;">{{ scope.row[_item.prop] }}</span>
                            <el-button type="text" size="small" @click="detailedDepOpen(_item, scope.row)">修改</el-button>
                          </div>
                          <span v-else>{{ scope.row[_item.prop] }}</span>
                        </template>
                      </el-table-column>
                    </el-table>
                  </div>
                </div>
                <div class="item" :class="{
                  max: ['t-form-textarea', 'upload-image', 'upload', 'number', 'money', 'upload','tips','edit'].includes(item.tagIcon) || item.expandParams.dialogTips,
                  file: item.tagIcon == 'upload'
                }" :key="index" v-else-if="!item.isHide">
                  <div class="label" v-if="item.tagIcon != 'tips' && item.tagIcon != 'edit'" :class="{'tips':item.expandParams.dialogTips}">
                    {{ item.label }}
                    <template v-if="item.expandParams.dialogTips">
                      <span class="is-underline form-label main-color" v-if="item.expandParams.descEdit && item.expandParams.descEdit != '<p><br></p>'" @click="showEdit(item.expandParams.descEdit,item.expandParams.dialogTips)">{{ item.expandParams.dialogTips }}</span>
                      <span class="form-label main-color" v-else>{{ item.expandParams.dialogTips }}</span>
                    </template>
                    ：
                  </div>
                  <div class="value"
                    :class="{
                      'pre-line': item.tagIcon == 't-form-textarea',
                      'highlight': (item.tagIcon == 'number' || item.tagIcon == 'money') && !['realityMoney', 'money', 'balance', 'loanBalance', 'loanCommission'].includes(item.field)
                    }">
                    <template v-if="item.tagIcon == 'upload'">
                      <div class="file-list">
                        <div class="file-item" v-for="(_item, _index) in item.value" :key="_index">
                          <div @click="downloadFile(_item)" class="file-item-link">
                            <i class="el-icon-download c-icon"></i>
                            <span>{{ _item.name }}</span>
                            <a :href="_item.url | imgUrl" @click.stop target="_blank"
                              class="el-icon-view file-preview"></a>
                          </div>
                        </div>
                      </div>
                    </template>
                    <template v-else-if="item.tagIcon == 'upload-image' || item.tagIcon == 'camera'">
                      <div class="image-list">
                        <div class="image-item" v-for="(_item, _index) in item.value" :key="_index"
                          @click="previewImageShow(item.value, _index)">
                          <el-image style="width: 100%; height: 100%" :src="_item | imgUrl" fit="cover"></el-image>
                        </div>
                      </div>
                    </template>   
                    <template v-else-if="item.tagIcon == 'tips'">
                      <el-alert
                        class="form-alert"
                        :title="item.value"
                        type="warning"
                        :closable="false"
                        show-icon>
                    </el-alert>
                    </template>  
                    <template v-else-if="item.tagIcon == 'edit'">
                      <div class="edit-content" v-html="item.value"></div>
                    </template>
                    <template v-else-if="item.tagIcon == 'money'">
                      <span>{{ item.value | money }}</span>
                      <span v-if="item.expandTips">{{ item.expandTips }}</span>
                    </template>
                    <template v-else>
                      {{ item.value }}
                    </template>
                  </div>
                </div>
              </template>
            </div>
          </div>
        </div>
        <div class="flow-wrapper">
          <div class="title">流程</div>
          <div class="flow-list">
            <div class="flow-line-wrapper">
              <div class="flow-line-cover">
                <div class="line"></div>
              </div>
            </div>
            <div class="flow-list-cover">
              <div class="flow-item" v-for="(item, index) in flow" :key="index">
                <div class="flow-item-cover u-flex u-col-top">
                  <div class="avatar">
                    <img :src="(item.avatar || 'wx-image/avatar-none.png') | imgUrl" v-avatar v-if="!item.isGroup" />
                    <div class="icon-cover u-flex u-col-center u-row-center" v-else>

                      <i :class="item.type == 1
                          ? 'fxy-icon-a_6'
                          : item.type == 5 ? 'fxy-icon-zhihangren-01-copy' : 'fxy-icon-laba'
                        "></i>
                    </div>
                    <div class="status-icon" :class="{
                      success: item.status == 1,
                      err: item.status == 2,
                      wait: item.status == 3,
                    }"></div>
                  </div>
                  <div class="flow-item-content">
                    <div class="flow-item-top u-flex u-col-center u-row-between">
                      <div class="s-title">{{ item.title }}</div>
                      <div class="date">
                        <span>{{ item.date }}</span>
                        <div v-if="item.isGroup && item.type != 5" class="arrow-cover" @click="toggleMore(item)">
                          <i :class="{ open: item.show }" class="switch fxy-icon-xiala-01"></i>
                        </div>
                      </div>
                    </div>
                    <div class="tips" v-if="item.tips">{{ item.tips }}</div>
                    <div class="p-list u-flex u-col-top" v-if="item.list && item.show && item.isGroup">
                      <div class="p-item u-flex u-col-center u-row-center" v-for="(_item, index) in item.list"
                        :key="index">
                        <div class="s-avatar">
                          <img :src="_item.avatar | imgUrl" v-avatar />
                          <div class="s-status" :class="{
                            success: _item.status == 1,
                            err: _item.status == 2,
                            wait: _item.status == 3,
                          }"></div>
                        </div>
                        <div class="s-name">{{ _item.name }}</div>
                      </div>
                    </div>
                    <div class="s-remark" v-if="item.remarks">
                      <div class="s-remark-item" v-for="(remark,remarkIndex) in item.remarks" :key="remarkIndex">
                        <p>{{ remark.remark }}</p>
                        <div class="s-image-show r-flex r-flex-wrap" v-if="remark.picList && remark.picList.length > 0">
                          <div class="s-image-item" v-for="(_item,_index) in remark.picList" :key="_index" @click="remarkImageShow(remark.picList, _index)">
                            <img :src="_item.url|$staticFilter" alt="">
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="s-remark" v-else-if="item.remark">
                      <p>{{ item.remark }}</p>
                      <div class="s-image-show r-flex r-flex-wrap" v-if="item.picList && item.picList.length > 0">
                        <div class="s-image-item" v-for="(_item,_index) in item.picList" :key="_index"  @click="remarkImageShow(item.picList, _index)">
                          <img :src="_item.url|$staticFilter" alt="">
                        </div>
                      </div>
                    </div>
                    <div class="s-remark" v-if="!!item.auto"><span class="m-color">未找到审批人</span>，已自动通过</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <image-show v-model="imagePreview" :imageList="imageList" :imageIndex="imageIndex"></image-show>
        <!-- 成交单详情 -->
      </div>
      <div class="apply-detail_footer">
        <div class="apply-detai_footer_btns">
          <el-button type="danger" @click="detailClose(false)" size="small">关 闭</el-button>
          <el-button type="primary" @click="handleRefuse(1)" :disabled="loading" :loading="loading" size="small"
            v-if="detailData.isCheck === 1 && operate">{{ type == 5 ? '确认执行' : '同意' }}
          </el-button>
          <el-button type="warning" @click="handleRefuse(2)" :disabled="loading" :loading="loading" size="small"
            v-if="detailData.isCheck === 1 && operate && type != 5">驳回
          </el-button>
        </div>
        <div class="apply-detai_footer_rebtns">
          <el-button type="danger" @click="approveRevoke" :disabled="loading" :loading="loading" size="small"
            v-if="operate && revokeStatus" plain>撤回</el-button>
          <el-button type="warning" @click="resubmit" :disabled="resubmitLoading" :loading="resubmitLoading"
            size="small" v-if="operate && isSelf && (status == 2 || status == 4) && isAgainConfirm" plain>再次提交
          </el-button>
        </div>
      </div>
    </div>
    <apply-create ref="applyForm" :templateId="templateId" @close="applyCreateClose" @confirm="applyCreateConfirm"
      v-if="isResubmit" reSubmitModel @applyFormReady="setApplyFormData"></apply-create>
    <el-dialog :title="type == 5 ? '执行' : '审批' + '备注'" :visible.sync="dialogVisible" :close-on-click-modal="false"
      width="600px" append-to-body class="remark-dialog">
      <el-form :model="checkData" ref="formData" label-width="100px" size="small">
        <el-form-item :label="remarkLabel + '：'" prop="remark">
          <el-input type="textarea" :placeholder="remarkLabel" v-model="checkData.remark" 
            show-word-limit rows="4">
          </el-input>
        </el-form-item>       
        <el-form-item label="图片：" prop="picList">
          <multiUploadFile v-model="checkData.picList" :showLabel="false" accept=".jpg, .jpeg, .png, .gif, .bmp, .JPG, .JPEG, .PNG,.GIF, .BMP"></multiUploadFile>
        </el-form-item>
        
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false" size="small">取 消</el-button>
        <el-button type="primary" @click="handleRefuseCheck('formData')" size="small" :disabled="loading"
          :loading="loading">确 定</el-button>
      </span>
    </el-dialog>
    <rich-text-show ref="richTextShow"></rich-text-show>
  </div>
</template>
<script>
import { isArray, guid, isObjectNone, cloneDeep, convertCurrency,isObject } from "@/utils";
import imageShow from "@/components/imageShow.vue";
import { getApplyDetail, approveCheck, approveRevoke } from "@/api/approve";
import bigNumber from "bignumber.js";
import depRoleModal from '@/components/dialog/DepRoleModal';
import richTextShow from "@/components/rich-text-show";
import multiUploadFile from "@/components/imageUpload/multiUploadFile";
export default {
  name: 'applyDetail',
  components: {
    imageShow,
    applyCreate: () => import('@/components/approve/detail/applyCreate'),
    depRoleModal,
    richTextShow,
    multiUploadFile
  },
  props: {
    //当前选中的数据
    data: {
      type: String,
      default: '0',
    },
    //锁，用于隐藏重复引用
    lock: {
      type: Array,
      default() {
        return []
      }
    },
    operate: {
      type: Boolean,
      default: true
    },
    isShow: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      detailedDepShow: false,
      drawerDet: false,
      drawerEdit: false,
      currentApplyId: '',
      employeeId: '',
      isResubmit: false,
      isFullScreen: false,
      dialogFull: false,
      drawer: false,
      drawerItem: {},
      drawerTitle: '成交单明细',
      salaryShow: false,
      salaryDialogConfig: {
        title: '工资单明细',
        date: '',
        depId: ''
      },
      imagePreview: false,
      imageList: [],
      imageIndex: 0,
      detailData: {},
      //针对特殊套件处理用成交单
      detailBeatData: {},
      loading: true,
      flow: [],
      list: [],
      suiteName: "",
      dialogVisible: false,
      checkData: {
        status: 2,
        remark: "",
        picList:[]
      },
      approvalStaffType: {
        1: "审批人",
        2: "抄送人",
        5: "执行人",
        6: '申请人'
      },
      approvalStatus: {
        0: "待审批",
        1: "已通过",
        2: "已驳回",
        3: "审批中",
        6: '已撤回'
      },
      approvalTypeStatus: {
        1: "依次审批",
        2: "会签",
        3: "或签",
      },
      suiteNameText: {
        'reimbursementSuite': '报销单',
        'collectionSuite': '收款单',
        'punishSuite': '惩罚单',
        'rewardSuite': '奖励单'
      },
      //含有明细的套件
      detailSuite: ['collectionSuite', 'punishSuite', 'rewardSuite', 'officialStaffSuite', 'promotionSuite', 'salaryIncreaseSuite', 'shiftJobsSuite'],
      resubmitLoading: false,
      //是否要选择公账
      isSelectBankAccount: false,
      //选择公账选项列表
      bankAccountObj: {
        checkBankList: []
      },
      modalConfig: {
        type: 1,
        selectType:'radio',
        title:"选择报销部门",
        checkStrictly: true,
        model: '',
        checkList: [],

      }
    };
  },
  filters: {
    imgUrl(value) {
      if (!value) return "";
      if (value.indexOf('http:') != -1 || value.indexOf('https:') != -1) {
        return value;
      } else {
        return process.env.VUE_APP_IMG_URL + value;
      }
    },
    money(val) {
      let money = parseFloat(val);
      return `￥${money.toLocaleString()}`
    }
  },
  computed: {
    title() {
      return this.detailData?.title;
    },
    avatar() {
      return this.detailData?.employee?.avatar || "wx-image/avatar-none.png";
    },

    //公司名称
    companyName() {
      return this.$store.state.userInfo.company?.companyName || "-";
    },

    //审批编号
    approvalNumber() {
      return this.detailData?.number || "-";
    },
    //创建时间
    createdAt() {
      return this.detailData?.createdAt || "";
    },

    //用户信息
    employee() {
      return this.detailData?.employee || {};
    },

    //本人的id
    userId() {
      return this.$store.state.userInfo.employee?.userId;
    },

    //本人的员工id
    emplyeeId() {
      return this.$store.state.userInfo?.employee?.id;
    },

    //是否为本人提交的单子
    isSelf() {
      return this.employee?.id === this.emplyeeId;
    },

    //权限类型，现在有，个人，审批人，抄送人, 默认是抄送人
    permission() {
      return this.detailData?.permission || 3;
    },

    //是否为审批人
    isCheck() {
      return this.detailData?.isCheck;
    },

    //审批单的状态 默认是待审批
    status() {
      return this.detailData?.status;
    },
    //提交次数
    submitNumber() {
      return this.detailData?.submitNumber || 0;
    },
    //状态提示语
    statusTips() {
      return this.detailData?.status == 0
        ? "审批中"
        : this.detailData?.status == 1
          ? "审批通过"
          : this.detailData?.status == 2 ? "审核已驳回" : '-';
    },
    //标题
    approvalTitle() {
      return this.detailData?.subTitle;
    },
    //执行相关状态
    handleOptions() {
      return this.detailData?.handleOptions || null;
    },
    //是否为审核人
    isAuditor() {
      return this.detailData?.userId == this.$store.state?.userInfo?.employee?.userId;
    },
    guid() {
      return guid(32)
    },
    linked() {
      return this.detailData?.linked || []
    },
    type() {
      return this.detailData?.type;
    },
    remarkLabel() {
      return this.checkData.status == 1 ? this.type == 5 ? '执行备注' : '同意备注' : '驳回原因';
    },
    //是否为撤回单子
    isRevoke() {
      return !!this.detailData?.isRevoke;
    },
    //模板id
    templateId() {
      return this.detailData?.templateId;
    },
    revokeStatus() {
      return !!this.detailData?.revokeStatus;
    },
    position() {
      return this.detailData?.employee?.post?.name || '暂无 '
    },
    //是否允许再次提交
    isAgainConfirm() {
      return !!this.detailData?.isAgainConfirm;
    }

  },
  methods: {
    //文件下载
    downloadFile(item) {
      this.$downloadFile(item.url, item?.name)
    },
    //重新填充数据
    setApplyFormData() {
      //填充数据
      this.$refs.applyForm.applyFormDataInit(!isObjectNone(this.detailBeatData) ? this.detailBeatData : this.detailData);
    },
    applyCreateConfirm() {
      this.applyCreateClose();
      this.detailClose(true);
    },
    applyCreateClose() {
      this.$emit('titleChange', '');
      this.isResubmit = false;
      this.$refs.applyForm?.resetForm && this.$refs.applyForm.resetForm();
    },
    //成交单拉取分销商欠款数据
    getApplyDistributorList(type, id) {
      
    },
    //成交单相关套件特殊拉取
    getDealData(type, id) {
      if (!id) return;
      let message = this.$message.success('请稍等，正在获取相关数据....');
      this.resubmitLoading = true;
      return transactionFormData({ type, id }).then(async res => {
        //克隆数据
        this.detailBeatData = cloneDeep(this.detailData);
        // 成交
        if (this.suiteName === "dealSuite") {
          this.detailBeatData.control.forEach(el => {
            if (el.field == this.suiteName) {
              el.children.forEach(_el => {
                let field = _el.field;
                //提交审批单人
                if (field === "dealEmployee") {
                  _el.value = res?.data?.nickname;
                  if (isArray(_el.controlValue) && _el.controlValue.length > 0) {
                    _el.controlValue[0].value = res?.data?.nickname;
                  }
                }
                if (field === "customName") {
                  _el.value = res?.data?.customerName;
                  if (isArray(_el.controlValue) && _el.controlValue.length > 0) {
                    _el.controlValue[0].value = res?.data?.customerName;
                  }
                }
                if (field === "totalMoney") {
                  _el.value = res?.data?.afterDiscountTotalPrice;
                  if (isArray(_el.controlValue) && _el.controlValue.length > 0) {
                    _el.controlValue[0].value = res?.data?.afterDiscountTotalPrice;
                  }
                }
              })
            }
          })
        } else if (this.suiteName === "dealExpendSuite") {
          let disData = null;
          if ([3, 6].includes(res.data.targetType)) {
            try {
              let distributionItem = await this.getApplyDistributorList(res.data.targetType, res.data.distributorId);
              if (distributionItem.data?.list?.length > 0) {
                disData = distributionItem.data.list[0];
              }
            } catch (err) {
              disData = null;
            }
          };
          this.detailBeatData.control.forEach(el => {
            if (el.field == this.suiteName) {
              let data = res.data;
              let balance = disData?.balance || 0;
              let isHide = ![3, 6].includes(data.targetType) || balance == 0;
              el.children.forEach(_el => {
                let field = _el.field;
                //三个计算参数
                if (['realityMoney', 'money', 'balance'].includes(field)) {
                  //需要填充数据
                  if (!isHide) {
                    if (field == 'balance') {
                      _el.value = bigNumber(balance || 0).toFixed(2);
                      if (isArray(_el.controlValue) && _el.controlValue.length > 0) {
                        _el.controlValue[0].value = bigNumber(balance || 0).toFixed(2);
                      }
                    }
                    if (field == 'money') {
                      _el.value = bigNumber(data?.underwrittenTotal > 0 ? data?.underwrittenTotal : data?.afterTaxMoney).toFixed(2);
                      if (isArray(_el.controlValue) && _el.controlValue.length > 0) {
                        _el.controlValue[0].value = bigNumber(data?.underwrittenTotal > 0 ? data?.underwrittenTotal : data?.afterTaxMoney).toFixed(2);
                      }
                    }
                    if (field == 'realityMoney') {
                      //此处取应返金额和未还金额里的最小值
                      _el.value = bigNumber(Math.min(data?.underwrittenTotal > 0 ? data?.underwrittenTotal : data?.afterTaxMoney, balance)).toFixed(2);
                      if (isArray(_el.controlValue) && _el.controlValue.length > 0) {
                        _el.controlValue[0].value = bigNumber(Math.min(data?.underwrittenTotal > 0 ? data?.underwrittenTotal : data?.afterTaxMoney, balance)).toFixed(2);
                      }
                      _el.expandParams = {
                        balance,
                        realityBalance: disData?.realityBalance || 0,
                        freezeBalance: disData?.freezeBalance || 0,
                        commissionOpen: disData?.notExecuteCashBack || 0
                      }
                    }
                  }
                }
                if (field == 'receiptCustomType') {
                  _el.value = data.targetType;
                  if (isArray(_el.controlValue) && _el.controlValue.length > 0) {
                    _el.controlValue[0].value = data.targetType;
                  }
                }
                if (field == 'payee') {
                  _el.value = data.name;
                  if (isArray(_el.controlValue) && _el.controlValue.length > 0) {
                    _el.controlValue[0].value = data.name;
                  }
                }
                if (field == 'openBank') {
                  _el.value = data.bankName;
                  if (isArray(_el.controlValue) && _el.controlValue.length > 0) {
                    _el.controlValue[0].value = data.bankName;
                  }
                }
                if (field == 'collectAccount') {
                  _el.value = data.bankCard;
                  if (isArray(_el.controlValue) && _el.controlValue.length > 0) {
                    _el.controlValue[0].value = data.bankCard;
                  }
                }
                if (field === "totalMoney") {
                  /**
                 * 当未还金额 >= 应返金额时 为 0
                 * 当未还余额 < 应返金额时 为 应返金额 - 未还余额
                 */
                  if (!isHide) {
                    let borrowBalance = bigNumber(balance);
                    let realityMoney = bigNumber(data?.underwrittenTotal > 0 ? data?.underwrittenTotal : data?.afterTaxMoney);
                    if (borrowBalance.toNumber() >= realityMoney.toNumber()) {
                      _el.value = 0;
                      if (isArray(_el.controlValue) && _el.controlValue.length > 0) {
                        _el.controlValue[0].value = 0;
                      }
                    } else {
                      _el.value = realityMoney.minus(borrowBalance).toFixed(2);
                      if (isArray(_el.controlValue) && _el.controlValue.length > 0) {
                        _el.controlValue[0].value = realityMoney.minus(borrowBalance).toFixed(2);
                      }
                    }
                  } else {
                    _el.value = data?.underwrittenTotal > 0 ? data?.underwrittenTotal : data?.afterTaxMoney;
                    if (isArray(_el.controlValue) && _el.controlValue.length > 0) {
                      _el.controlValue[0].value = data?.underwrittenTotal > 0 ? data?.underwrittenTotal : data?.afterTaxMoney;
                    }
                  }
                }
                if (field === "roomNumber") {
                  _el.value = data?.roomNo;
                  if (isArray(_el.controlValue) && _el.controlValue.length > 0) {
                    _el.controlValue[0].value = data?.roomNo;
                  }
                }
                if (field === 'remark') {
                  let remark = `姓名：${data.name}${data.bankName ? '，开户行：' + data.bankName : ''}${data.bankCard ? '，卡号：' + data.bankCard : ''}${data.money ? '，税前（元）：' + data.money : ''}${data.afterTaxMoney ? '，税后（元）：' + data.afterTaxMoney : ''}${data.underwrittenTotal ? '，垫资金额（元）：' + data.underwrittenTotal : ''}${data.remark ? '，备注：' + data.remark : ''}`
                  el.value = remark;
                  if (isArray(_el.controlValue) && _el.controlValue.length > 0) {
                    _el.controlValue[0].value = remark;
                  }
                }
              })
            }
          })
        } else if (this.suiteName === "frmLossSuite") {
          this.detailBeatData.control.forEach(el => {
            if (el.field == this.suiteName) {
              let data = res.data;
              el.children.forEach(_el => {
                let field = _el.field;
                if (field === "totalMoney") {
                  _el.value = data.realCashBack;
                  if (isArray(_el.controlValue) && _el.controlValue.length > 0) {
                    _el.controlValue[0].value = data.realCashBack;
                  }
                }
              })
            }
          })
        } else if (this.suiteName === 'rewardSuite') {
          this.detailBeatData.control.forEach(el => {
            if (el.field == this.suiteName) {
              el.children.forEach(_el => {
                let field = _el.field;
                //提交审批单人 data?.afterTaxMoney
                if (['detailedMoney', 'totalMoney'].includes(field)) {
                  _el.value = res.data?.afterTaxMoney;
                  if (isArray(_el.controlValue) && _el.controlValue.length > 0) {
                    _el.controlValue[0].value = res.data?.afterTaxMoney;
                  }
                }
              })
            }
          })
        }
      }).catch(err => { console.log(err) }).finally(() => {
        let invId = setTimeout(() => {
          clearTimeout(invId);
          this.resubmitLoading = false; message.close()
        }, 500)
      })
    },
    //开票数据拉取
    getBillingData(id) {
      return billingDetail({ id }).then(res => {
        this.detailBeatData = cloneDeep(this.detailData);
        this.detailBeatData.control.forEach(el => {
          if (el.field == this.suiteName) {
            el.children.forEach(_el => {
              if (_el.field == 'billingInvoice') {
                _el.value = res.data.invoice;
                if (isArray(_el.controlValue) && _el.controlValue.length > 0) {
                  _el.controlValue[0].value = res.data.invoice;
                }
              }
              if (_el.field == 'billingIssuingUnitCn') {
                _el.value = res.data.issuingUnitStr;
                if (isArray(_el.controlValue) && _el.controlValue.length > 0) {
                  _el.controlValue[0].value = res.data.issuingUnitStr;
                }
              }
              if (_el.field == 'totalMoney') {
                _el.value = res.data.money;
                if (isArray(_el.controlValue) && _el.controlValue.length > 0) {
                  _el.controlValue[0].value = res.data.money;
                }
              }
              if (_el.field == 'billingId') {
                _el.value = res.data.id;
                if (isArray(_el.controlValue) && _el.controlValue.length > 0) {
                  _el.controlValue[0].value = res.data.id;
                }
              }
              if (_el.field == 'billingIssuingUnit') {
                _el.value = res.data.issuingUnit;
                if (isArray(_el.controlValue) && _el.controlValue.length > 0) {
                  _el.controlValue[0].value = res.data.issuingUnit;
                }
              }
            })
          }
        })
      })
    },
    //重新提交
    async resubmit() {
      //是否为需要特殊处理的成交单
      if (['dealSuite', 'dealExpendSuite', 'frmLossSuite', 'rewardSuite', 'billingSuite'].includes(this.suiteName)) {
        //获取类型
        let dealType = {
          'dealSuite': {
            type: 'transaction',
            key: 'dealId'
          },
          'dealExpendSuite': {
            type: 'cash_back',
            key: 'writeOffId'
          },
          'frmLossSuite': {
            type: 'none_other',
            key: 'writeOffId'
          },
          'rewardSuite': {
            type: 'cash_back',
            key: 'cashBackId'
          },
          'rewardSuite': {
            type: 'cash_back',
            key: 'cashBackId'
          },
          'billingSuite': {
            type: '',
            key: 'billingId'
          }
        };
        let suiteGroupItem = this.detailData.control.find(el => {
          return el.field == this.suiteName;
        });
        if (!suiteGroupItem || !isArray(suiteGroupItem?.children)) return;
        //提取id
        let keyItem = null;
        if (['rewardSuite'].includes(this.suiteName)) {
          keyItem = this.detailData.control.find(el => {
            return el.field == dealType[this.suiteName].key;
          });
        } else {
          keyItem = suiteGroupItem?.children.find(el => {
            return el.field == dealType[this.suiteName].key;
          });
        }
        //检查奖励单是不是跟成家单挂钩
        if (!keyItem) {
          if (this.suiteName !== 'rewardSuite') {
            this.$message.error('无法拉取最新数据，禁止再次发起');
          }
        } else if (this.suiteName == 'billingSuite') {
          //开票记录
          await this.getBillingData(keyItem.value);
        } else {
          await this.getDealData(dealType[this.suiteName]?.type, keyItem.controlValue?.length > 0 ? keyItem.controlValue[0]?.value : null)
        }
      }
      this.$nextTick(() => {
        this.$emit('titleChange', this.title);
        this.isResubmit = true;
        this.$nextTick(() => {
          let inv_id = setTimeout(() => {
            clearTimeout(inv_id);
            //锁住
            this.$refs?.applyForm?.resubmitLock && this.$refs?.applyForm?.resubmitLock();
            this.$refs.applyForm?.getFormData && this.$refs.applyForm.getFormData();
          }, 300)
        })
      })
    },
    //撤回
    approveRevoke() {
      this.$confirm(`是否要撤回审批单？`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.loading = true;
        approveRevoke({
          approveId: this.detailData?.id
        })
          .then(res => {
            this.$message.success('审批单撤回成功');
            //初始化
            this.formInit();
            this.$emit('resetList');
          })
          .catch(err => {
            console.log(err);
          })
          .finally(() => {
            this.loading = false;
          })
      })
    },
    detailClose(update) {
      this.$emit('close', update);
    },
    //初始化的时候合并一下选项
    formInit() {
      this.loading = true;
      getApplyDetail({ approveId: this.data })
        .then(res => {
          this.detailData = res.data;
          this.createDetailList(cloneDeep(this.detailData?.control));
          this.createFlowList(this.detailData?.workflowNodes);
          this.$emit('applyDetailReady', res.data.isCheck);
        }).catch(err => {
          console.log(err)
        }).finally(() => {
          this.loading = false;
        })
    },
    resetForm() {
      this.detailData = {};
      this.checkData = { status: 2, remark: "",picList:[] };
      this.flow = [];
      this.list = [];
      this.dialogVisible = false;
      this.imageList = [];
      this.suiteName = "";
      this.imageIndex = 0;
      this.loading = false;
      this.salaryShow = false;
      this.isResubmit = false;
      this.detailBeatData = {};
      this.salaryDialogConfig = {
        title: '工资单明细',
        date: '',
        depId: ''
      }
      this.isSelectBankAccount = false;
      this.bankAccountObj = {
        checkBankList: []
      }
    },
    //生成详情列表
    createDetailList(control) {
      let list = [];
      control.forEach((el) => {
        //套件的话，提取出来
        if (el.isGroup) {
          this.suiteName = el.field;
          let suiteItem = this.suiteInit(el.children);
          list.push(...suiteItem);
        } else {
          list.push(el);
        }
      });


      //渲染出可以进行展示的数据
      list = list.map((el) => {
        let expandParams = isObject(el.expandParams) ?  el?.expandParams : el.expandParams ? JSON.parse(el?.expandParams) : {};
        if (el?.disabled) {
          el.expandParams = expandParams;
          return el;
        } else {
          let value = this.detailItemHandle(el);
          //图片特殊处理
          return {
            label: el.label,
            value,
            tagIcon: el.tagIcon,
            expandTips:el?.expandTips || '',
            isHide:(expandParams?.isHide && !['transaction','dealDetail'].includes(el.field)) || !!el?.isHide,
            field: el.field,
            expandParams
          };

        }
      });
      this.list = list;
    },
    //数据处理
    detailItemHandle(el,isDetailSuite=false) {
      let value;
      if (el?.tagIcon == "upload" || el?.tagIcon == "upload-image") {
        if (el?.tagIcon == "upload") {
          value = el.controlValue.map((_el) => {
            return {
              name: _el.label,
              url: _el.value,
            };
          });
        } else {
          value = el.controlValue.map((_el) => {
            return _el.value;
          });
        }
      } else if (el?.field == 'transaction') {
        value = el?.controlValue?.length > 0 ? el.controlValue[0]?.value : '';
      } else if (el?.field == 'projectId') {
        el.expandParams = JSON.parse(el.expandParams);
        value = el.expandParams.constructor?.name || '';
      } else if (el?.field == 'cashBackId' || el?.field == 'tradingAdvance' || el?.field == 'billingIssuingUnit') {
        el.isHide = true;
      } else if(el?.field == 'totalMoney' && !isDetailSuite){
        value = el.value || el.value === 0 ? el.value : '';
        if(el.value){
          el.expandTips =  `（${convertCurrency(el.value)}）`
        }
      } else if(el?.field == 'tips'){
        el.expandParams = JSON.parse(el.expandParams);
        value = el.expandParams?.tips || ''
      }else if(el?.field == 'edit'){
        el.expandParams = JSON.parse(el.expandParams);
        value = el.expandParams?.content || ''
      }else if(el?.field == 'village'){
        el.expandParams = JSON.parse(el.expandParams);
        value = el.expandParams.villagePath?.length > 0 ? el.expandParams.villagePath.map(el=>el.label).join(' / ') : '-'
      }else {
        if(el.tagIcon == 'textarea'){
          el.tagIcon = 't-form-textarea'
        }
        if(el.tagIcon == 'camera'){
          value = el.value;
        }else{
          value = el.value || el.value === 0 ? el.value : '-';
        }
      }
      //进行数据解析
      return value;
    },
    //生成流程列表
    createFlowList(flow) {
      let flowList = [];
      //添加申请人入内
      flowList.push({
        avatar: this.employee?.avatar || "",
        tips: this.employee?.nickname || "暂无",
        title: "发起申请",
        status: 1,
        date: this.createdAt,
        isGroup: false,
      });

      //渲染列表生成
      try {
        flow = flow.map((el) => {
          let obj = {};
          //是否为审批组
          obj.isGroup = el.workflowNodesUser.length > 1;
          //显示开关
          obj.show = obj.isGroup;
          //是否为无审核人自动通过状态
          obj.auto = el.workflowNodesUser?.length == 0 && el?.noHanderAction === 1;
          //1已通过，2已驳回,3审批中
          obj.avatar = obj.isGroup
            ? ""
            : el.workflowNodesUser[0]?.employee?.avatar ||
            this.$siteConfig?.defaultAvatar;
          // obj.tips = !obj.isGroup
          //     ? `${
          //          el.workflowNodesUser[0]?.userId == this.userId
          //             ? "我"
          //             : el.workflowNodesUser[0]?.employee?.nickname ||
          //             el.workflowNodesUser[0]?.name ||
          //             ""
          //     }${
          //         el.workflowNodesUser[0]?.status == 1
          //             ? el.type == 5 ? '(已执行)' :"(已同意)"
          //             : el.workflowNodesUser[0]?.status == 2
          //                 ? "(已驳回)"
          //                 : el.workflowNodesUser[0]?.status == 3
          //                     ? el.type == 5 ? '(待执行)'  :  "(审批中)"
          //                     : ""
          //     }`
          //     : el.type == 1
          //         ? `${el.workflowNodesUser?.length || 0}人${
          //       this.approvalTypeStatus[el.examineMode]
          //     }${el.autoCheck === 1 ? '（'+el.nodeTxt+'）' : '中'}`
          //         : `${el.workflowNodesUser?.length || 0}人已${el.type == 5 ? '执行' : '抄送'}`;
          if (!obj.isGroup) {
            obj.tips = `${el.workflowNodesUser[0]?.userId == this.userId
                ? "我"
                : el.workflowNodesUser[0]?.employee?.nickname ||
                el.workflowNodesUser[0]?.name ||
                ""
              }${el.workflowNodesUser[0]?.status == 1
                ? el.type == 6 ? '(已撤回)' : el.type == 5 ? '(已执行)' : "(已同意)"
                : el.workflowNodesUser[0]?.status == 2
                  ? "(已驳回)"
                  : el.workflowNodesUser[0]?.status == 3
                    ? el.type == 5 ? '(待执行)' : this.status == 4 ? '(审批中止)' : "(审批中)"
                    : ""
              }`
          } else {
            if (el.type == 1 || el.type == 5) {
              obj.tips = `${el.workflowNodesUser?.length || 0}人${this.approvalTypeStatus[el.examineMode]
                }${el.autoCheck === 1 ? '（' + el.nodeTxt + '）' : '中'}`
            } else {
              obj.tips = `${el.workflowNodesUser?.length || 0}人已${el.type == 5 ? '执行' : '抄送'}`
            }
          }
          (obj.title = this.approvalStaffType[el.type]), (obj.status = el?.status);
          if (obj.status == 1) {
            let typeStatusTxt = ''
            if (el.type == 1) {
              typeStatusTxt = '已通过'
            } else if (el.type == 2) {
              typeStatusTxt = '已抄送'
            } else if (el.type == 5) {
              typeStatusTxt = '已执行'
            } else if (el.type == 6) {
              typeStatusTxt = '已撤回'
            }
            obj.title = `${obj.title}（${typeStatusTxt}）`
          }
          /**
           * 节点为同意或者已驳回的时候显示内容
           */
          if (el.status == 1 || el.status == 2) {
            obj.date = el.updatedAt;
          } else {
            obj.date = '';
          }
          obj.list = el.workflowNodesUser.map((_el) => {
            return {
              name:
                _el.employee?.userId == this.userId
                  ? "我"
                  : _el.employee?.nickname || _el?.name,
              status: _el?.status,
              avatar: _el?.employee?.avatar || '',
            };
          });
          obj.type = el.type;
          obj.remark = '';
          if (obj.isGroup) {
            let items = el.workflowNodesUser.filter(_el => {
              return !!_el.remark;
            });
            if (items.length > 0) {
              items.forEach((_el, _index) => {
                obj.remark += `${_index > 0 ? '\n' : ''}${_el?.name || '备注'}：${_el?.remark}。`
              });

              obj.remarks = items.map(_el=>{
                return {
                  remark:`${_el?.name || '备注'}：${_el?.remark}。`,
                  picList: _el?.picList || []
                }
              })
            };
          } else {
            let item = el.workflowNodesUser.find(_el => {
              return !!_el.remark;
            });
            obj.remark = `${item?.remark || ''}`;
            obj.picList = item?.picList || [];
          }

          //已执行
          if (el.type == 5 && el.status == 1) {
            //拼装支出账户及途径
            if (this.detailData?.bankAccount && this.detailData?.bankAccount != "0") {
              obj.remark += `${obj.remark == '' ? '' : '\n'}支出账户：${this.detailData?.bankAccount?.name}`
            }
            if (isArray(this.detailData?.approveBankList) && this.detailData?.approveBankList.length > 0) {
              this.detailData?.approveBankList.forEach(bank => {
                obj.remark += `${obj.remark == '' ? '' : '\n'}支出途径：${bank.bankAccountName} ${bank.account}`
              })
            }
          }
          return obj;
        });
      } catch (err) {
        console.log(err)
      }
      flowList.push(...flow);
      this.flow = flowList;
    },
    //套件单独处理初始化
    suiteInit(item) {
      let list = [];
      //请假公休套件,此套件只需要显示一个时间列表即可
      if (this.suiteName == "leaveSuite") {
        //渲染为数组以便调用
        let obj = {};
        item.forEach((el) => {
          obj[el.field] = {
            label: el.label,
            value: el.value
          }
        });
        list.push({
          label: `${obj['leaveType'].value}，` + obj["duration"].value || '-',
          date: [
            `${obj["leaveStartDate"].value} ${obj["leaveStartTime"].value}`,
            `${obj["leaveEndDate"].value} ${obj["leaveEndTime"].value}`,
          ],
          //不需要处理
          disabled: true,
        });
      } else if (this.suiteName == "WorkAttendanceCardSuite") {
        //补卡套件
        let obj = {};
        item.forEach((el) => {
          obj[el.field] =
            isArray(el.controlValue) && el.controlValue.length > 0
              ? el.controlValue[0]
              : "";
          if (obj[el.field]) {
            obj[el.field].label = el.label;
          }
        });
        list = [
          {
            label: obj["workAttendanceCardDate"].label,
            value: obj["workAttendanceCardDate"].value,
            //不需要处理
            disabled: true,
          },
          {
            label: obj["workAttendanceCardTime"].label,
            value: obj["workAttendanceCardTime"].value,
            //不需要处理
            disabled: true,
          },
          {
            label: obj["workAttendanceCardShift"].label,
            value: obj["workAttendanceCardShift"].value,
            //不需要处理
            disabled: true,
          },
        ];
      } else if (this.suiteName == 'reimbursementSuite' || this.suiteName == 'adReimbursementSuite') {
        //单独处理报销单数据，处理成表格形式
        item.forEach(el => {
          //针对明细单独书写
          if (el.field != 'detailed' && el.field != 'salaryArchivesKit') {
            let expandParams = {};
            try {
              expandParams = el?.expandParams ? JSON.parse(el.expandParams) : {};
            } catch (err) { }
            list.push({
              label: el.label,
              value: this.detailItemHandle(el),
              tagIcon: el?.tagIcon,
              disabled: true,
              isHide: expandParams?.isInstead === 0,
              expandTips:el?.expandTips || ''
              //附加选项
            })
          }
        })
        //提取所有的明细
        let detailed = item.filter(el => el.field == "detailed");
        if (detailed.length > 0) {
          //处理数据主要是处理一个表头一个数据源
          //表头
          let tableData = [];
          let columns = detailed[0].children.map(el => {
            return {
              label: el.label,
              prop: el.field
            }
          });
          detailed.forEach(el => {
            let values = {};
            el.children.forEach(_el => {
              values[_el.field] = _el.tagIcon == 'money' ? parseFloat(_el.value).toLocaleString() : _el.value;
              values[_el.field + 'ControlValue'] = _el.controlValue;
            })
            tableData.push(values);
          });
          //此明细一定要插入在金额的后面
          list.push({
            label: '报销明细',
            value: tableData,
            tableColumns: columns,
            tagIcon: 'table',
            table: true,
            disabled: true
          })
        };
      }
      else if (this.detailSuite.includes(this.suiteName)) {
        //报销套件
        item.forEach(el => {
          //针对明细单独书写
          if (el.field != 'detailed' && el.field != 'salaryArchivesKit') {
            let expandParams = {};
            try {
              expandParams = el?.expandParams ? JSON.parse(el.expandParams) : {};
            } catch (err) { }
            list.push({
              label: el.label,
              value: this.detailItemHandle(el),
              tagIcon: el?.tagIcon,
              disabled: true,
              isHide: expandParams?.isInstead === 0,
              expandTips:el?.expandTips || ''
            })
          } else {
            if (!el?.children) {
              el.children = [];
            }
            list.push({
              label: el.label,
              block: el.children.map(_el => {
                let value = {
                  label: _el.label,
                  value: this.detailItemHandle(_el)
                }
                return value;
              }),
              disabled: true
            })
          }
        })
        //工资单套件
      } else if (this.suiteName == 'salarySuite') {
        //提取出部门以及时间
        let options = {};
        item.forEach(el => {
          if (el.field == 'salaryDepartment') {
            options['dep'] = el.controlValue[0]?.value || '';
            options['depName'] = el.controlValue[0]?.label || '';
          } else if (el.field == 'salaryDate') {
            options['date'] = el.controlValue[0]?.value || '';
          } else {
            el.value = el.controlValue[0]?.value || '';
            el.disabled = true;
          }
        });

        item.forEach(el => {
          if (el.field == 'salaryBill') {
            el.value = options;
          }
        })
        list.push(...item);
      } else if (['inductionSuite'].includes(this.suiteName)) {
        //获取当前员工id;
        let staffId = item.filter(el => {
          return el.field == 'inductionEmployeeId'
        });
        //添加一个员工明细
        list = [...item];
        list.push({
          label: '员工明细',
          value: staffId.length > 0 ? staffId[0]?.controlValue[0]?.value : '',
          tagIcon: 'staffDetail',
          disabled: true
        });
      } else if (['dealExpendSuite', 'dealSuite', 'frmLossSuite'].includes(this.suiteName)) {
        item.forEach(el => {
          //进行隐藏的处理
          if (['dealId', 'writeOffId', 'receiptCustomType'].includes(el.field)) {
            el.isHide = true;
          }
        })
        if (this.suiteName == 'dealExpendSuite') {
          //获取是否隐藏
          let receiptCustomType = item.find(el => el.field == 'receiptCustomType');
          let balance = item.find(el => el.field == 'balance');
          if (receiptCustomType) {
            item.forEach(el => {
              if (['realityMoney', 'money', 'balance'].includes(el.field)) {
                el.isHide = !['3', '6'].includes(receiptCustomType.value) || !balance?.value || balance?.value == '0';
              }
            })
          }
        }
        //成交单特殊处理
        list = [...item];
        let dealId = item.find(el => {
          return el?.field == 'dealId';
        })
        if (!this.lock.includes('delTabs')) {
          //添加一个明细查看
          if (this.suiteName == 'dealSuite') {
            list.push({
              label: '成交单明细',
              value: dealId?.controlValue[0]?.value,
              tagIcon: 'dealDetail',
              disabled: true
            });
          } else if (['frmLossSuite', 'dealExpendSuite'].includes(this.suiteName)) {
            //成交支出和报销
            //提取出成交单专用
            let linkList = this.linked.filter(el => {
              return el?.type == 'transaction'
            });

            linkList.forEach(el => {
              list.push({
                label: el.label,
                value: el.value,
                tagIcon: 'dealDetail',
                disabled: true
              });
            })
          }
        }
      } else if (this.suiteName == 'carSuite') {
        item.forEach(el => {
          if (el.field == 'carApproveId') {
            el.expandParams = el.expandParams ? JSON.parse(el?.expandParams) : {};
            //车辆
            el.expandParams.isHide = false;
            el.label = '车辆申请记录';
          }
        })
        list = [...item];
      } else {
        list = [...item];
      }
      return list;
    },
    toggleMore(item) {
      item.show = !item.show;
    },
    //图片预览
    previewImageShow(list, index) {
      this.imageList = list.map(el => {
        return el.indexOf('http:') != -1 || el.indexOf('https:') != -1 ? el : process.env.VUE_APP_IMG_URL + el;
      })
      this.imageIndex = index;

      this.$nextTick(() => {
        this.imagePreview = true;
      })
    },
    remarkImageShow(list,index){
      let _list = list.map(el => el.url);
      this.previewImageShow(_list,index);
    },
    //查看用车记录
    checkCarDetail(item) {
      this.currentApplyId = item.value;
      this.drawerDet = true;
    },
    //查看员工明细
    checkStaffDetail(item) {
      if (!item?.value) return;
      this.employeeId = { id: item.value };
      this.drawerEdit = true;
    },
    //查看工资单明细
    checkDepPayroll(item) {
      //拉出套件数据
      this.salaryDialogConfig = {
        title: `${item?.value?.date}${item?.value?.depName || ''}工资单明细`,
        date: item?.value?.date,
        approveId: this.data,
        salaryAdjustShow: false
      }
      this.salaryShow = true
    },
    closeTabs() {
      this.drawer = false;
    },
    checkDealDetail(item) {
      if (!item.value || item.value == '-') {
        return;
      }
      this.drawer = true;
      this.drawerItem = item;
    },
    drawerInit() {
      this.$nextTick(() => {
        this.$refs.tabs.id = this.drawerItem.value;
        this.$refs.tabs.getDetail();
        this.$refs.tabs.activeName = 'uploadFile';
      })
    },
    handleAdopt() {
      this.$confirm(`是否${this.type == 5 ? '执行' : '同意'}审批单？`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.loading = true;
        check({ approveId: this.detailData.id, status: 1, remark: "" })
          .then((response) => {
            this.loading = false;
            this.$message.success("审核成功");
            this.detailClose(true);
          })
          .catch(() => {
            this.loading = false;
          });
      }).catch(() => {

      });
      return;
    },
    handleRefuse(status) {
      //选择支出途径，当存在支出账户且存在支出途径时才选择
      if (this.type == 5 && this.detailData?.bankAccount && this.detailData?.checkBankList?.length > 0) {
        this.bankAccountObj.bankAccount = this.detailData?.bankAccount?.name;
        //设置银行卡号选择
        this.bankAccountObj.checkBankList = this.detailData?.checkBankList;
        this.$set(this.checkData, 'bankAccount', this.bankAccountObj.checkBankList[0].value)
        this.isSelectBankAccount = true;
      } else {
        this.isSelectBankAccount = false;
        this.bankAccountObj.bankAccount = '';
        this.bankAccountObj.checkBankList = [];
        this.checkData.bankAccount = [];
      }
      this.checkData.remark = "";
      this.dialogVisible = true;
      this.checkData.status = status;
    },
    handleRefuseCheck(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.loading = true;
          let options = {
            approveId: this.detailData.id,
            status: this.checkData.status,
            remark: this.checkData.remark,
            picList:this.checkData?.picList || []
          }
          //获取银行卡号
          if (this.isSelectBankAccount && this.checkData.bankAccount) {
            options.bankAccount = this.bankAccountObj.checkBankList.find(el => {
              return el.value == this.checkData?.bankAccount;
            });
            if (!options.bankAccount) {
              options.bankAccount = [];
            }
          }
          approveCheck(options).then((response) => {
            this.loading = false;
            this.dialogVisible = false;
            this.$message.success("审核成功");
            this.detailClose(true);
          }).catch(() => {
            this.loading = false;
          });
        }
      });
    },
    //开票明细查看
    checkBillingDetail(item) {
      if (this.lock.includes('billing')) {
        this.$message.warning('明细已打开，无法重复展示');
        return;
      }
      this.$refs.billingDialog.handleEdit({ id: item.value }, 'cat')
    },
    //修改报销类型
    editDetailedType(item,row) {
      this.$refs.selectDetailedType.open(this.data, row.detailedTypeControlValue);
    },
    detailedDepOpen(item,row) {
      this.modalConfig.model = cloneDeep(row.detailedDepartmentControlValue);
      this.modalConfig.checkList = row.detailedDepartmentControlValue.map(el=>{
        return {
          id:el.value,
          name:el.label
        }
      });
      
      this.detailedDepShow = true;
    },
    showEdit(content,title){
      this.$refs.richTextShow.open(content,title)
    }
  }

};
</script>

<style lang="scss" scoped>
.abow_dialog {
  display: flex;
  justify-content: center;
  align-items: Center;
  overflow: hidden;

  ::v-deep .el-dialog {
    margin: 0 auto !important;
    height: 99%;
    overflow: hidden;

    .el-dialog__body {
      position: absolute;
      left: 0;
      top: 54px;
      bottom: 0;
      right: 0;
      padding: 0;
      z-index: 1;
      overflow: hidden;
      overflow-y: auto;
    }
  }
}

.remark-dialog {
  ::v-deep .el-input.is-disabled .el-input__inner {
    background: #fff;
    color: #666666;
  }

  ::v-deep .el-radio {
    display: block;
    margin-top: 10px;
  }
}

/*dialog header*/
.el-dialog__header {
  background: #e3eaed;
}

.avue-crud__dialog__header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.el-dialog__title {
  color: rgba(0, 0, 0, .85);
  font-weight: 500;
  word-wrap: break-word;
}

::v-deep .edit-content{
  img,video{
    max-width: 100%;
  }
}

.avue-crud__dialog__menu {
  padding-right: 25px;
  float: left;
}

.avue-crud__dialog__menu i {
  color: #909399;
  font-size: 15px;
}

.el-icon-full-screen {
  cursor: pointer;
}

.el-icon-full-screen:before {
  content: "\e719";
}

.form-label{
  font-size:12px;
  margin-left:5px;
  &.is-underline{
    border-bottom:1px solid var(--primary);
    cursor: pointer;
    &:hover{
      opacity: .7;
    }
  }
}



.dialog-cover {
  height: 100%;

  .dialog-cover-detail {
    display: flex;
    height: 100%;
    flex-direction: column;
  }

  ::v-deep .el-alert{
        align-items: flex-start;
        .el-alert__icon{
            position: relative;
            font-size:20px;
        }
        .el-alert__content{
            line-height:20px;
            padding: 0 12px;
            white-space: pre-line;
            .el-alert__title{
                font-size:14px;
            }
        }
    }

  .apply-detail_footer {
    padding: 20px 30px;
    background-color: #ffffff;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  .dialog-cover-ctn {
    flex: 1 1 auto;
    width: 100%;
    height: 0;
    overflow: hidden;
    overflow-y: auto;
    padding: 0 30px;
  }

  .u-flex {
    display: flex;
    align-items: center;
  }

  .u-row-between {
    justify-content: space-between;
  }

  .u-col-top {
    align-items: flex-start;
  }

  .u-col-center {
    align-items: center;
  }

  .u-row-center {
    justify-content: center;
  }

  .approver-info {
    color: #4285F6;
    font-size: 14px;
    padding-bottom: 12px;
    border-bottom: 1px solid #F4F4F4;
  }

  .employee-info {
    display: flex;
    align-items: center;
    padding-bottom: 12px;
    position: relative;

    .stauts-icon {
      width: 120px;
      height: 101px;
      position: absolute;
      top: 0;
      right: 42px;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .avatar {
      width: 60px;
      height: 60px;
      margin-right: 10px;

      img {
        width: 100%;
        height: 100%;
        border-radius: 50%;
      }
    }

    .name {
      font-size: 14px;
      padding-top: 2px;
      padding-bottom: 3px;
    }

    .apply-status {
      font-size: 14px;
      color: #999999;
    }
  }

  .detail-main {
    padding: 16px 0 8px;

    .item {
      margin-bottom: 22px;

      .label {
        color: #8f98a8;
        font-size: 14px;
        line-height: 1;
      }

      .value {
        font-size: 14px;
        line-height: 20px;
        color: #333333;

        &.pre-line {
          white-space: pre-wrap;
        }

        .file-list,
        .image-list {
          padding-top: 10px;
        }

        .file-item {


          .file-preview {
            padding: 0 15px;
            height: 30px;
            line-height: 30px;
            background: #4285F6;
            border-radius: 5px;
            color: #fff;
            opacity: .6;
            transition: opacity .3s;

            &:hover {
              opacity: 1;
            }
          }

          .file-item-link {
            display: flex;
            width: 100%;
            height: 50px;
            line-height: 50px;
            margin-bottom: 2px;
            background: rgba(0, 0, 0, .04);
            color: #666666;
            font-size: 14px;
            padding: 0 10px;
            cursor: pointer;
            align-items: center;

            .c-icon {
              font-size: 16px;
              padding-right: 5px;
            }

            span {
              flex: 1 1 auto;
              width: 0;
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
            }

            &:hover {
              background: rgba(0, 0, 0, .08);
            }
          }
        }

        .image-list {
          display: flex;
          flex-wrap: wrap;
        }

        .image-item {
          width: 80px;
          height: 80px;
          margin-right: 10px;
          margin-bottom: 10px;
          border-radius: 5px;
          cursor: zoom-in;
          overflow: hidden;
        }
      }

      &.date-info {
        background: #f0fbff;
        border-radius: 5px;
        padding: 11px;

        &.max {
          display: block;
        }

        .des {
          font-size: 15px;
          line-height: 1;
          margin-bottom: 10px;
          color: #333333;
        }

        .item-link {
          color: #4285f6;
        }

        .date-horizon {
          .date-item {
            line-height: 1;
            font-size: 15px;
            color: #8f98a8;
            padding: 7px 0 7px 15px;
            position: relative;

            .line-wrapper {
              position: absolute;
              width: 7px;
              height: 100%;
              top: 1px;
              left: 0;

              .line-cover {
                display: flex;
                height: 100%;
                flex-direction: column;
                align-items: center;

                .point {
                  width: 7px;
                  height: 7px;
                  border: 1px solid #a0a7b4;
                  border-radius: 50%;
                }

                &::after,
                &::before {
                  content: "";
                  flex: 1 1 auto;
                  height: 0;
                  background: #a0a7b4;
                  width: 1px;
                }
              }
            }

            &:first-child {
              margin-top: 0;

              .line-wrapper .line-cover {
                &::before {
                  background: transparent;
                }
              }
            }

            &:last-child {
              .line-wrapper .line-cover {
                &::after {
                  background: transparent;
                }
              }
            }
          }
        }
      }

      &.block {
        background: #f0fbff;
        border-radius: 10px;
        padding: 15px 15px 20px;
        flex-direction: column;

        .item-block-title {
          font-size: 16px;
          padding-bottom: 10px;
          width: 100%;

          span:last-child {
            color: #4285F6;
          }
        }

        .item-block-cover {
          width: 100%;

          .item-block_item {
            color: #8F98A8;
            line-height: 20px;
            font-size: 14px;
            padding-top: 8px;
            width: 50%;
            display: flex;
            align-items: flex-start;

            .item-block_item-label {
              width: 6em;
              padding-right: 10px;
              box-sizing: content-box;
            }

            .item-block_item-value {
              display: inline;
            }
          }
        }

        &.table {
          padding: 0;
          background: transparent;
        }
      }
    }
  }

  .dialog-apply-content {
    padding-right: 40px;

    .apply-item-cover {
      &.apply-user-info {
        margin-bottom: 22px;
        border-bottom: 1px solid #F4F4F4;
      }

      display: flex;
      flex-wrap: wrap;
      align-items: flex-start;

      .item {
        width: 50%;
        display: flex;
        align-items: flex-start;

        .label {
          margin-bottom: 0;
          width: auto;
          line-height: 20px;
          box-sizing: content-box;
          &.tips{
            width: auto;
          }
        }

        .value {
          flex: 1 1 auto;
          width: 0;

          line-height: 20px;

          &.highlight {
            color: #1F87FE;
            font-size: 18px;
            font-weight: bold;
          }
        }

        &.block {
          width: 100%;
        }

        &.max {
          width: 100%;
        }

        &.file {
          display: block;

          .label,
          .value {
            display: block;
            width: 100%;
          }
        }
      }
    }

    .apply-user-info {
      display: flex;
      flex-wrap: wrap;

    }
  }

  .apply-detai_footer_rebtns {
    margin-left: 10px;
  }

  .flow-wrapper {
    padding: 40px 0 13px;
    background: #fff;
    max-width: 562px;
    flex: 0.55;
    padding-left: 40px;
    height: 100%;
    position: relative;

    &::before {
      content: '';
      position: absolute;
      top: 44px;
      left: 0;
      bottom: 13px;
      width: 1px;
      background: #F4F4F4;
    }

    .title {
      font-size: 16px;
      line-height: 1;
      color: #666666;
    }

    .flow-list {
      padding-top: 16px;
      position: relative;

      .flow-line-wrapper {
        position: absolute;
        left: 0;
        top: 16px;
        width: 49px;
        bottom: 0;
        z-index: 10;

        .flow-line-cover {
          width: 100%;
          height: 100%;
          display: flex;
          justify-content: center;

          .line {
            width: 3px;
            background: #d1d1d1;
            height: 100%;
          }
        }
      }

      .flow-item {
        padding-bottom: 35px;

        &:last-child {
          background: #fff;
          position: relative;
          z-index: 20;
        }

        .flow-item-top {
          position: relative;
        }

        .date {
          .switch {
            transition: transform 0.3s;
            color: #d2d2d2;
            font-size: 15px;
            transform: rotate(0deg);
            display: block;
          }

          .open {
            transform: rotate(180deg);
          }

          .arrow-cover {
            padding: 12px;
            position: absolute;
            top: 16px;
            right: 0;
          }
        }

        .avatar {
          width: 49px;
          height: 49px;
          color: #fff;
          font-size: 24px;
          background: #eff5ff;
          text-align: center;
          line-height: 49px;
          border-radius: 5px;
          margin-right: 16px;
          position: relative;
          z-index: 20;

          .icon-cover {
            background: #4285f6;
            border-radius: 5px;
            width: 100%;
            height: 100%;

            i {
              font-size: 30px;
            }
          }

          img {
            width: 100%;
            height: 100%;
            border-radius: 5px;
          }

          .status-icon {
            position: absolute;
            width: 18px;
            height: 18px;
            bottom: -3px;
            right: -5px;

            &.success {
              background: url(https://static.fxyos.com/wx-image/approval-icon-success.png) no-repeat scroll center center / 100% 100%;
            }

            &.err {
              background: url(https://static.fxyos.com/wx-image/approval-icon-err.png) no-repeat scroll center center / 100% 100%;
            }

            &.wait {
              background: url(https://static.fxyos.com/wx-image/approval-icon-wait.png) no-repeat scroll center center / 100% 100%;
            }
          }
        }

        .flow-item-content {
          flex: 1 1 auto;
          width: 0;
          padding-top: 6px;

          .flow-item-top {
            padding-right: 5px;

            .s-title,
            .date {
              line-height: 1;
            }

            .s-title {
              font-size: 16px;
              color: #333333;
            }

            .date {
              font-size: 12px;
              color: #8f98a8;
            }
          }

          .tips {
            font-size: 13px;
            padding-top: 4px;
            color: #8f98a8;
          }

          .p-list {
            padding-top: 8px;
            flex-wrap: wrap;

            .p-item {
              margin-right: 9px;
              width: 48px;
              margin-top: 9px;
              flex-direction: column;

              .s-avatar {
                width: 35px;
                height: 35px;
                text-align: center;
                line-height: 35px;
                background: #eff5ff;
                border-radius: 5px;
                font-size: 12px;
                color: #fff;
                position: relative;

                img {
                  width: 100%;
                  height: 100%;
                  border-radius: 5px;
                }

                .s-status {
                  position: absolute;
                  top: -5px;
                  right: -5px;
                  width: 13px;
                  height: 13px;

                  &.success {
                    background: url(https://static.fxyos.com/wx-image/approval-icon-success.png) no-repeat scroll center center / 100% 100%;
                  }

                  &.err {
                    background: url(https://static.fxyos.com/wx-image/approval-icon-err.png) no-repeat scroll center center / 100% 100%;
                  }

                  &.wait {
                    background: url(https://static.fxyos.com/wx-image/approval-icon-wait.png) no-repeat scroll center center / 100% 100%;
                  }
                }
              }

              .s-name {
                width: 100%;
                color: #8f98a8;
                padding-top: 8px;
                font-size: 13px;
                text-align: center;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
              }
            }
          }

          .s-remark {
            background: #EFF5FF;
            border-radius: 6px;
            padding: 8px;
            margin-top: 10px;
            color: #777777;
            font-size: 13px;
            line-height: 20px;
            white-space: pre-line;

            .m-color {
              color: #4285f6;
            }
            .s-remark-item{
              margin-top:15px;
              padding-top:11px;
              border-top:1px solid #e5e5e5;
              &:first-child{
                margin-top:0;
                border-top:0;
                padding-top:0;
              }
            }
          }
          .s-image-show{
            padding-top:6px;
          }
          .s-image-item{
            width: 80px;
            height:80px;
            margin-right:10px;
            cursor: pointer;
            img{
              width: 100%;
              height:100%;
              border-radius: 6px;
            }
          }
        }
      }
    }
  }

  .fade-enter-active,
  .fade-leave-active {
    transition: opacity .3s;
  }

  .fade-enter,
  .fade-leave-to

  /* .fade-leave-active below version 2.1.8 */
    {
    opacity: 0;
  }
}

@media screen and (max-width: 1440px) {
  .dialog-cover .dialog-apply-content .apply-item-cover .item{
    width: 100%!important;
  }
}
</style>
