<template>
<div class="form-tips">
     <div class="fxy-tips-text-wrapper">
        <i class="el-icon-warning-outline icon"></i>
        <p>{{value || '无提示语'}}</p>
    </div>
</div>
</template>
<script>

export default {
    props:['value']
}
</script>

<style lang="scss" scoped>
  .fxy-tips-text-wrapper{
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 10px 0;
        .icon{
            font-size:18px;
            padding-right:10px;
            color:#00a0e4;
        }
        .fxy-tips-text{
            font-size:16px;
        }
    }
</style>