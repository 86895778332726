<template>
<div class="t-form-input">
    <el-input v-model="inputValue" :placeholder="placeholder" @blur="check" :ref="ref" @change="suiteDataCheck"></el-input>
</div>
</template>
<script>
import test from "@/utils/test";
import {guid} from "@/utils"
export default {
 inject: ['root'],
 props:{
    value:{
        default:''
    },
    formData:{
        type:Object,
        default:()=>({})
    }
 },
 data(){
     return{   
        ref:guid()
     }
 },
 computed:{
    inputValue:{
        get(){
            return this.value;
        },
        set(val){
            this.$emit('input',val);
        }
    },
    placeholder(){
        return this.formData?.placeholder || '请输入'
    }
 },
 methods:{
     suiteDataCheck(){
        this.root.suiteDataCheck && this.root.suiteDataCheck(this.formData);
     },
     //检查格式
     check(){
         if(!test.idCard(this.value)){
            this.$message.error(`${this.formData.config?.label || '身份证'}格式错误`)
            this.$refs[this.ref].focus();
         }
     }
 }



}
</script>

<style lang="scss" scoped>

</style>