import request from '@/utils/request'

export function contractList(data) {
  return request({
    url: `/api/contract/contract-list?page=${data.page}&pageSize=${data.pageSize}`,
    method: 'post',
    data
  })
}

export function contractSave(data) {
  return request({
    url: `/api/contract/contract-save`,
    method: 'post',
    data
  })
}

export function contractDetail(data) {
  return request({
    url: `/api/contract/contract-detail`,
    method: 'post',
    data
  })
}

export function contractDelete(data) {
  return request({
    url: `/api/contract/contract-delete`,
    method: 'post',
    data
  })
}


export function contractTypeList(data) {
  return request({
    url: `/api/contract/contract-type-list?page=${data.page}&pageSize=${data.pageSize}`,
    method: 'post',
    data
  })
}

export function contractTypeSave(data) {
  return request({
    url: `/api/contract/contract-type-save`,
    method: 'post',
    data
  })
}

export function contractTypeDelete(data) {
  return request({
    url: `/api/contract/contract-type-delete`,
    method: 'post',
    data
  })
}

//参与方列表
export function contractPartyList(data) {
  return request({
    url: `/api/contract/contract-party-list?page=${data.page}&pageSize=${data.pageSize}`,
    method: 'post',
    data
  })
}

//参与方新建或编辑
export function contractPartySave(data) {
  return request({
    url: `/api/contract/contract-party-save`,
    method: 'post',
    data
  })
}

//参与方删除
export function contractPartyDelete(data) {
  return request({
    url: `/api/contract/contract-party-delete`,
    method: 'post',
    data
  })
}
//合同任务新建或编辑
export function contractTaskSave(data) {
  return request({
    url: `/api/contract/contract-task-save`,
    method: 'post',
    data
  })
}

//合同任务删除
export function contractTaskDelete(data) {
  return request({
    url: `/api/contract/contract-task-delete`,
    method: 'post',
    data
  })
}

//合同已收款新建或编辑
export function contractReceivedSave(data) {
  return request({
    url: `/api/contract/contract-received-save`,
    method: 'post',
    data
  })
}

//合同任务删除
export function contractReceivedDelete(data) {
  return request({
    url: `/api/contract/contract-received-delete`,
    method: 'post',
    data
  })
}