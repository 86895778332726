<template>
  <!-- <div class="apply-list-dialog"> -->
    <el-dialog
    :title="dialogTitle"
    :visible.sync="dialogShow"
    :destroy-on-close="true"
    :close-on-click-modal="false"
    width="800px"
    top="10vh"
    @open="formInit"
    @closed="resetForm"
    @close="onClose(false)"
    append-to-body
  >
    <div class="apply-list-dialog"
    v-loading="loading"
    element-loading-text="加载中...">
        <div class="section">
            <div class="approval-form-list r-flex r-flex-wrap r-col-top">
                <div class="approval-form-item" v-for="(_item,_index) in list" :key="_index" @click="openApplyForm(_item)">
                    <div class="icon r-flex r-col-center r-row-center" :style="{background:_item.iconColor}"><i :class="_item.icon" class="apply-icon"></i></div>
                    <div class="text">{{_item.title}}</div>
                </div>
            </div>
        </div>
    </div>
  
  <apply-create-dialog
      ref="applyCreateDialog"
      @astrict='astrict'
      @applyFormReady="applyFormReady"
      @applyConfirm="applyConfirm"
      @close="onClose"
      >
    </apply-create-dialog>
  </el-dialog>
</template>
<script>
import {approveTemplateList} from "@/api/approve";
import applyCreateDialog from "./applyCreateDialog";
export default {
  components:{
    applyCreateDialog
  },
  data(){
      return{
        list:[],
        pageErr:{
            err:true,
            tips:'',
            type:'page'
        },
        loading:true,
        dialogApproveVisible:false,
        templateId:'',
        applyDialogTitle:'',
        dialogTitle:'',
        dialogShow:false
      }
  },
  methods: {
    handleOpen(options){
      this.dialogTitle = options?.dialogTitle || "审批单列表";
      this.filter = options?.filter || ((data)=>{return true});
      this.submitLock = !!options?.submitLock;
      this.filterOptions = options?.filterOptions || [];
      this.dialogShow = true;
    },  
    //初始化的时候合并一下选项
    formInit() {
        this.getApprovalList()
    },
    resetForm(){
      // this.$refs.applyDetail.resetForm();
    },
    //获取表单列表
    getApprovalList(){
        this.loading=true;
        approveTemplateList(
          {
            controlField:this.filterOptions
          }
        )
        .then((res)=>{
            this.pageErr.err=false;
            this.list = res.data.filter(el=>{
              return this.filter(el);
            });
            if(this.list.length == 0){
                this.pageErr.err=true;
                this.pageErr.type='list'
                this.pageErr.tips="审批单列表为空";
            }
        })
        .catch(err=>{
            console.log(err);
            this.pageErr.err=true;
            this.pageErr.type='page';
            this.pageErr.tips="获取审批单列表失败";
        })
        .finally(()=>{
            this.loading=false;
        })
    },
    openApplyForm(item){
      this.$refs.applyCreateDialog.handleOpen({
        submitLock:this.submitLock,
        templateId:item.id,
        applyDialogTitle:item.title
      })
    },
    //关闭的时候关闭列表
    astrict(val){
      if(val){
        this.dialogShow = false;
        this.$emit('close',true);
      }
    },
    suiteDataCheck(item){
      this.$refs.applyCreateDialog?.suiteDataCheck && this.$refs.applyCreateDialog?.suiteDataCheck(item);
    },
    applyFormReady(list){
      this.$emit('applyFormReady',list);
    },
    applyConfirm(){
      this.$emit('applyConfirm')
    },
    onClose(isUpdate){
      this.$emit('close',isUpdate)
    }
  },
};
</script>

<style lang="scss" scoped>
.apply-list-dialog{
  min-height:50vh;
  width: 100%;
}
.section{
    background: #fff;
    margin-bottom:8rpx;
    .section-title{
      height:45px;
      line-height:45px;
      font-size: 18px;
      font-weight: bold;
      color:#666666;
        border-bottom:1px solid #f1f1f1;
    }
            .approval-form-list{
                padding: 12px 0;
                .approval-form-item{
                    width: 15%;
                    display: flex;
                    flex-direction: column;
                    justify-items: center;
                    align-items: center;
                    padding: 12px 0;
                    cursor: pointer;
                    &:nth-child(4){
                        margin-right:0;
                    }
                    .icon{
                        width: 50px;
                        height:50px;
                        background: #f6f6f6;
                        border-radius:15px;
                    }
                    .apply-icon{
                      color:#fff;
                      font-size:24px;
                    }
                    .text{
                        font-size:13px;
                        line-height:18px;
                        color: #666666;
                        padding-top:11.5px;
                        text-align: center;
                        overflow: hidden;
                        -webkit-line-clamp: 2;
                        text-overflow: ellipsis;
                        display: -webkit-box;
                        -webkit-box-orient: vertical;
                        transition: color .3s;
                    }

                    &:hover{
                      .text{
                        color:rgb(64, 158, 255);
                      }
                    }
                }
            }
        }
</style>
