<template>
  <div class="t-form-input">
    <el-select
      v-model="inputValue"
      :placeholder="placeholder"
      :disabled="disabled"
      @change="
        (val) => {
          linkFormData(val, false);
        }
      "
      v-if="autocomplete && autocompleteUrl && inputSelect"
      :loading="loading"
      filterable
      remote
      reserve-keyword
      :remote-method="search"
    >
      <el-option
        v-for="item in options"
        :key="item.id"
        :label="item.value"
        :value="item.value"
      >
      </el-option>
    </el-select>
    <el-autocomplete
      v-model="inputValue"
      :placeholder="placeholder"
      :disabled="disabled"
      @change="suiteDataCheck"
      :fetch-suggestions="autoSearch"
      :trigger-on-focus="false"
      v-else-if="autocomplete && autocompleteUrl"
    ></el-autocomplete>
    <el-input
      v-model="inputValue"
      :placeholder="placeholder"
      :disabled="disabled"
      @change="suiteDataCheck"
      v-else
    >
      <div slot="suffix" class="select-employee" v-if="isPayee">
        <el-link
          size="mini"
          type="primary"
          :underline="false"
          @click="handleSelectEmp('选择收款人')"
          >选择人员</el-link
        >
      </div>
    </el-input>
    <employee-modal
      :config="modalConfig"
      v-model="empDialogVisible"
      @confirm="handleConfirm"
    ></employee-modal>
  </div>
</template>
<script>
import { guid, isArray } from "@/utils";
import { getCommonOptions } from "@/api/approve";
import employeeModal from "@/components/dialog/employeeModal";
import {detail} from "@/api/employee";
import bigNumber from "bignumber.js";
export default {
  inject: ["root"],
  components: { employeeModal },
  props: {
    value: {
      default: "",
    },
    formData: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      ref: guid(),
      options: [],
      loading: false,
      empDialogVisible: false,
      modalConfig:{}
    };
  },
  computed: {
    inputValue: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
    placeholder() {
      return this.formData?.placeholder || "请输入";
    },
    disabled() {
      return (
        this.formData.config.field == "workAttendanceCardShift" ||
        !!this.formData?.config?.expandParams?.disabled
      );
    },
    //是否使用远程搜索
    autocomplete() {
      return !!this.formData?.config?.expandParams?.autocomplete;
    },
    //远程搜索URL
    autocompleteUrl() {
      return this.formData?.config?.expandParams?.autocompleteUrl || "";
    },
    inputSelect() {
      return !!this.formData?.config?.expandParams?.inputSelect;
    },
    autoCompleteQueryKey() {
      return this.formData?.config?.expandParams?.autoCompleteQueryKey || "";
    },
    suiteName() {
      return this.formData?.suiteName || "";
    },
    isPayee() {
      return (
        ["reimbursementSuite","adReimbursementSuite"].includes(this.suiteName) &&
        this.formData?.config?.field == "payee"
      );
    },
  },
  mounted() {
    if(this.formData?.config?.defaultValue && !this.value){
      this.$emit('input', this.formData?.config?.defaultValue)
    }
  },
  methods: {
    //获取分销商相关数据
    async getDistributorInfo(item){
      //获取此次返现金额
      let money = await this.getFormItem('money');
      return {
        realityMoney:bigNumber(Math.min(parseFloat(money.value) || 0,parseFloat(item.borrowBalance))).toFixed(2),
        totalMoney:bigNumber(item.borrowBalance).toNumber() >= bigNumber(money.value) ? 0 : bigNumber(money.value).minus(bigNumber(item.borrowBalance)).toFixed(2),
        balance:bigNumber(item.borrowBalance).toFixed(2)
      }
    },
    getFormItem(field){
      return new Promise((res,rej)=>{
        this.$emit('getFormItem',{
          field,
          callback:(data)=>{
            res(data)
          }
        })
      })
      .catch(err=>{
        rej();
      })
    },
    //联动其他数据
    async linkFormData(val, check = false) {
      //收款人
      if (this.formData.config?.field == "payee") {
        let item = this.options.find((el) => val == el.value);
        if (!item) return;
        //发起计算
        let data = await this.getDistributorInfo(item);
        this.$emit("setFormData", {
          openBank: item?.bankName || "",
          collectAccount: item?.bankNo || "",
          ...data
        });
      }
      if (check) {
        this.suiteDataCheck();
      }
    },
    suiteDataCheck() {
      this.root.suiteDataCheck && this.root.suiteDataCheck(this.formData);
    },
    search(val) {
      let list = [{}];
      if (val) {
        this.$emit('getFormItem',{
                field:'receiptCustomType',
                callback:(data)=>{
                  let params = {
                    companyLicenseName: val,
                    type: 1,
                    page: 1,
                    pageSize: 50,
                    top:data.value == 6 ? 1 : 0
                  };
                  if (this.autoCompleteQueryKey) {
                    params[this.autoCompleteQueryKey] = val;
                  }
                  getCommonOptions(this.autocompleteUrl, params, "get").then((res) => {
                    if (res.code == 200) {
                      //处理成自己想要的格式
                      list = res.data.list.map((item) => {
                        return {
                          id: `${item.id}`,
                          value: `${item.companyLicenseName}`,
                          bankName: item.defaultDictionaryBankOne
                            ? item.defaultDictionaryBankOne.bankName
                            : "",
                          bankNo: item.defaultDictionaryBankOne
                            ? item.defaultDictionaryBankOne.bankNo
                            : "",
                          borrowBalance: item.borrowBalance || 0
                        };
                      });
                      this.options = list;
                    }
                  });
                }
        });
      }
    },
    autoSearch(queryString, callback) {
      var list = [{}];
      if (queryString && queryString.length > 0) {
        let params = {
          type: 1,
          page: 1,
          pageSize: 100,
        };
        if (this.autoCompleteQueryKey) {
          params[this.autoCompleteQueryKey] = queryString;
        }
        getCommonOptions(this.autocompleteUrl, params, "get").then((res) => {
          //处理成自己想要的格式
          list = res.data.list.map((item) => {
            return {
              id: `${item.id}`,
              value: `${item.bankName}`,
            };
          });
          callback(list);
        });
      }
    },
    handleSelectEmp(title, selectType, value) {
      if (this.disabled) return;
      this.modalConfig = {
        type: 1,
        selectType: "radio",
        title,
        isStaff: 1,
        onlyStaff: true,
        checkStrictly: true,
        model: "",
        hideDepLock: true,
        checkList: [],
      };
      this.empDialogVisible = true;
    },
    handleConfirm({list}) {
        this.empDialogVisible = false;
        if(list.length == 0) return;
        detail({employee_id:list[0]?.id})
        .then(res=>{
            let options = {
                payee:res.data?.nickname || '',
                openBank:res.data?.bankName || '',
                collectAccount:res.data?.bankNumber || ''
            };
            options.processDepartment= res.data?.costDepartment?.id ? [{
                label:res.data?.costDepartment?.name,
                id:res.data?.costDepartment?.id,
                value:res.data?.costDepartment?.id,
                name:res.data?.costDepartment?.name
            }] : [];
            //存在对公账户
            if(res?.data?.companyOwner){
              //获取选项值
              this.$emit('getFormItem',{
                field:'bankAccount',
                callback:(data)=>{
                  if(isArray(data?.slot.options)){
                    //判断选项是否存在
                    let item = data.slot.options.find(el=>{
                      return el.value == res.data.companyOwner;
                    });
                    //存在才写入
                    if(item){
                      options.bankAccount = res?.data?.companyOwner;
                    }
                  }
                }
              });
            }
            this.$emit("setFormData", options)
        })
    },
  }
};
</script>

<style lang="scss" scoped>
::v-deep .el-select,
::v-deep .el-autocomplete {
  width: 100%;
}
.select-employee {}
</style>
