<template>
    <div class="kpi-wrapper r-page">
        <div class="cover">
            <el-form :model="form" :disabled="loading">
                <el-form-item :label="item.label" 
                v-for="(item,index) in form.presentDepartmentMany" :key="item.level" label-width="90px">
                    <r-tag-select v-model="item.department" labelKey="name" :placeholder="`请选择到场部门`" max
                    @focus="handleDep('radio', '请选择到场部门', index)" valueKey="id"></r-tag-select>
                </el-form-item>
                <el-form-item>
                    <div class="r-flex r-row-right">
                        <el-button type="primary" :loading="loading" @click="handleSubmit">保存</el-button>
                    </div>
                </el-form-item>
            </el-form>
        </div>
        <dep-role-modal :config="modalConfig" v-model="dialogVisible" @confirm="handleConfirm"></dep-role-modal>
    </div>
</template>
<script>
import DepRoleModal from "@/components/dialog/DepRoleModal";
import {save,getConfig} from "@/api/project"
export default {
    components:{
        DepRoleModal
    },
    data() {
        return {
            form:{
                presentDepartmentMany:[
                    {
                        level:1,
                        department:[],
                        label:'一到场监管'
                    },
                    {
                        level:2,
                        department:[],
                        label:'二到场监管'
                    },
                    {
                        level:3,
                        department:[],
                        label:'三到场监管'
                    },
                    {
                        level:4,
                        department:[],
                        label:'四到场监管'
                    },
                    {
                        level:5,
                        department:[],
                        label:'五到场监管'
                    }
                ]
            },
            dialogVisible:false,
            modalConfig:{},
            loading:false
        }
    },
    methods: {
        handleDep(selectType, title, index) {
            this.modalConfig = {
                type: 1,
                selectType,
                title,
                checkStrictly: true,
                model:index,
                checkList: this.form.presentDepartmentMany[index].department,
            };
            this.dialogVisible = true;
        },
        handleConfirm(val) {
            this.form.presentDepartmentMany[this.modalConfig['model']].department = val;
            this.dialogVisible = false;
        },
        handleSubmit(){
            this.loading = true;
            save(this.form).then(res=>{
                this.$message.success('保存成功');
                this.init();
            })
            .finally(()=>{
                this.loading = false;
            })
        },
        init(){
            getConfig().then(res=>{
                if(res.data.length == 0) return;
                res.data.sort((a,b)=>a.level-b.level)
                this.form.presentDepartmentMany = res.data;
            })
        }
    },
    created() {
        this.init();
    }
}
</script>

<style lang="scss" scoped>
.kpi-wrapper {
    .cover{
        width: 600px;
    }
}
</style>