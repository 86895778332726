<template>
<div class="drawer-title-wrapper r-flex r-col-center r-row-bet">
    <p class="drawer-title">{{dialogTitle}}</p>
    <div class="drawer-tools r-flex r-col-center r-row-right">
        <div class="pager r-flex" v-if="list.length>0">
            <div class="page-btn" :class="{disabled:prevDisabled}" @click="pageChange('prev')">
                <i class="el-icon-arrow-left"></i>
              </div>
              <div class="page-btn" :class="{disabled:nextDisabled}" @click="pageChange('next')">
                <i class="el-icon-arrow-right"></i>
              </div>
        </div>
        <div class="full-screen" @click="fullScreenChange">
            <el-tooltip class="item" effect="dark" :content="!fullScreen ? '全屏' : '退出全屏'" placement="top">
            <i style="color:#1F87FE" :class="{'fxy-icon-quanping1':!fullScreen,'fxy-icon-tuichuquanping':fullScreen}"></i>
            </el-tooltip>
        </div>
        <div class="close-item" @click="close">
            <i class="fxy-icon-guanbi2"></i>
        </div>
    </div>
</div>
</template>
<script>
/**
 * 通用的表头组件
 */
import {findIndex} from "@/utils";
export default {
    props:{
        dialogTitle:{
            type:String,
            default:''
        },
        fullScreen:{
            type:Boolean,
            default:false
        },
        list:{
            type:Array,
            default(){
                return []
            }
        },
        active:{
            type:String,
            default:''
        }
    },
    data(){
        return{
            index:0,
            nextDisabled:false,
            prevDisabled:false
        }
    },
    methods:{    
        fullScreenChange(){
            this.$emit('fullScreenChange',!this.fullScreen);
        },
        close(){
            this.$emit('close')
        },
        initPager(){
            let index = findIndex(this.list,el=>el.id == this.active);
            this.prevDisabled=false;
            this.nextDisabled = false;
            //标记一下
            if(index !=-1){
                if(index == 0){
                    this.prevDisabled = true;
                }
                if(index == this.list.length-1){
                    this.nextDisabled = true;
                }
                this.index = index;
            }
        },
        pageChange(type){
            if(type == 'next'){
                if(this.nextDisabled) return;
                let id = this.list[++this.index]?.id;
                this.$emit('pagerChange',id);
                this.disabledChange();
            }else{
                if(this.prevDisabled) return;
                let id = this.list[--this.index]?.id;
                this.$emit('pagerChange',id);
                this.disabledChange();
            }
        },
        disabledChange(){
            if(this.index == 0){
                this.prevDisabled = true;
            }
            if(this.index == this.list.length - 1){
                this.nextDisabled = true;
            }

            if(this.index > 0){
                this.prevDisabled = false;
            }

            if(this.index < this.list.length -1){
                this.nextDisabled = false;
            }
        }
    }
}
</script>

<style lang="scss" scoped>
    .drawer-title-wrapper{
        display: flex;
        justify-content: space-between;
        .drawer-title{
            position: relative;
            padding-left:15px;
            font-size:18px;
            &::before{
                content:'';
                position: absolute;
                background: #1F87FE;
                width: 4px;
                height:18px;
                left:0;
                top:50%;
                transform: translateY(-50%);
            }
        }
        .drawer-tools{
            .page-btn{
                width: 20px;
                height:20px;
                border:2px solid #1F87FE;
                border-radius: 2px 2px 2px 2px;
                margin-left:17px;
                display: flex;
                align-items: center;
                justify-content: center;
                cursor: pointer;
                i{
                color:#1F87FE;
                font-size:12px;
                }
                &:first-child{
                margin-left:0;
                }

                &.disabled{
                border-color:#999999;

                cursor: not-allowed;
                opacity:.4;
                i{
                    color:#999999;
                }
                }
            }
            .full-screen{
                height:20px;
                line-height:20px;
                margin-left:17px;
                i{
                font-size:18px;
                vertical-align: top;
                }
                cursor: pointer;
                &:hover{
                opacity: 1;
                }
            }
            .close-item{
                color:#1F87FE;
                display: flex;
                align-content: center;
                align-items: center;
                margin-left:17px;
                cursor: pointer;
                i{
                    font-size:22px;
                }
            }
        }
    }
</style>