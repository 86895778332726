<template>
  <div class="workflow-wrapper">
    <div class="fd-nav-content">
      <section class="dingflow-design">
        <div class="zoom">
          <div :class="'zoom-out'+ (nowVal==50?' disabled':'')" @click="zoomSize(1)"></div>
          <span>{{ nowVal }}%</span>
          <div :class="'zoom-in'+ (nowVal==300?' disabled':'')" @click="zoomSize(2)"></div>
        </div>
        <div class="box-scale" id="box-scale"
             :style="'transform: scale('+nowVal/100+'); transform-origin: 50% 0px 0px;'">
          <nodeWrap :nodeConfig.sync="nodeConfig" 
                    :isTried.sync="isTried" :directorMaxLevel="directorMaxLevel" :tableId="tableId"></nodeWrap>
          <div class="end-node">
            <div class="end-node-circle"></div>
            <div class="end-node-text">流程结束</div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>
<script>
import componentRegister from "./plugins/registerComponent";
export default {
  props: ["isTried"],
  data() {
    return {
      nowVal: 100,
    };
  },
  inject: ['id','task','approve'],
  computed: {
    workflowData() {
      return this.$store.state.approve.form?.workflow || null
    },
    flowPermission: {
      get() {
        return this.workflowData?.flowPermission || [];
      },
      set(val) {
        this.$store.commit('approveSetData', {
          'form.workflow.flowPermission': val
        })
      }
    },
    nodeConfig: {
      get() {
        return this.workflowData?.nodeConfig || {};
      },
      set(val) {
        this.$store.commit('approveSetData', {
          'form.workflow.nodeConfig': val
        })
      }
    },
    directorMaxLevel: {
      get() {
        return this.workflowData?.directorMaxLevel || 0;
      },
      set(val) {
        this.$store.commit('approveSetData', {
          'form.workflow.directorMaxLevel': val
        })
      }
    },
    workFlowDef: {
      get() {
        return this.workflowData?.workFlowDef || {};
      },
      set(val) {
        this.$store.commit('approveSetData', {
          'form.workflow.workFlowDef': val
        })
      }
    },
    tableId: {
      get() {
        return this.workflowData?.tableId || "";
      },
      set(val) {
        this.$store.commit('approveSetData', {
          'form.workflow.tableId': val
        })
      }
    },
    isApprove(){
      return !!this.approve?.approve;
    }

  },
  created() {
    if(this.isApprove){
      if(this.$store.state.approve.form.workflow.flowPermission.length === 0){
        this.$store.commit('approveSetData', {
          'form.workflow.flowPermission': [this.$store.state.userInfo.company?.depatment]
        })
      }
    }else{
      this.$store.commit('approveSetData', {
        'form.workflow.flowPermission': this.workflowData.flowPermission
      })
    }
    componentRegister.init();
  },
  methods: {
    toReturn() {
      //window.location.href = ""
    },
    saveSet() {
      this.processConfig.flowPermission = this.flowPermission;
    },
    zoomSize(type) {
      if (type == 1) {
        if (this.nowVal == 50) {
          return;
        }
        this.nowVal -= 10;
      } else {
        if (this.nowVal == 300) {
          return;
        }
        this.nowVal += 10;
      }
    }
  }
};
</script>
<style lang="scss" scoped>
@import "./css/workflow.css";

.error-modal-list {
  width: 455px;
}
</style>
