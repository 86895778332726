<template>
  <el-dialog :title="dialogTitle" :visible.sync="show" :destroy-on-close="true" custom-class="approve-create-dialog"
    :fullscreen="true" :close-on-click-modal="false" append-to-body modal-append-to-body :before-close="beforeClose"
    @open="formInit" @closed="resetForm" @close="onClose(false)">
    <div class="approve-create-wrapper" v-loading="loading" element-loading-text="正在加载..."
      element-loading-background="rgba(255,255, 255, 0.8)">
      <div class="back-cover r-flex r-ft-20">
        {{ title }}
      </div>
      <div class="save">
        <el-button type="primary" @click="saveForm" size="mini" icon="el-icon-position" plain>保存</el-button>
        <el-button @click="userClose" size="mini">取消</el-button>
      </div>
      <el-tabs v-model="activeName">
        <el-tab-pane label="基础设置" name="baseInfo" v-if="task || (!task && !isGlobal)"></el-tab-pane>
        <el-tab-pane label="表单设计" name="form"></el-tab-pane>
        <el-tab-pane label="流程设计" name="workflow" v-if="!isGlobal && !batchTask"></el-tab-pane>
      </el-tabs>
      <el-row v-if="activeName == 'baseInfo' && (task || (!task && !isGlobal))">
        <base-info></base-info>
      </el-row>
      <el-row v-if="activeName == 'form' && formShow">
        <formGenerator></formGenerator>
      </el-row>
      <el-row>
        <workflow v-if="activeName == 'workflow'" :isTried="isTried"></workflow>
      </el-row>
    </div>
    <el-dialog title="提示" :visible.sync="tipVisible" :append-to-body="true" width="40%" class="tip-dialog">
      <div class="ant-confirm-body">
        <i class="anticon anticon-close-circle" style="color: #f00;"></i>
        <span class="ant-confirm-title">当前无法发布</span>
        <div class="ant-confirm-content">
          <div>
            <p class="error-modal-desc">以下内容不完善，需进行修改</p>
            <div class="error-modal-list">
              <div class="error-modal-item" v-for="(item, index) in tipList.info">
                <div class="error-modal-item-label">基础设置</div>
                <div class="error-modal-item-content">{{ item.name }}</div>
              </div>
              <div class="error-modal-item" v-for="(item, index) in tipList.workflow">
                <div class="error-modal-item-label">流程设计</div>
                <div class="error-modal-item-content">{{ item.name }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="tipVisible = false" size="small">我知道了</el-button>
        <el-button type="primary" @click="tipVisible = false" size="small">前往修改</el-button>
      </span>
    </el-dialog>
  </el-dialog>
</template>
<script>
import formGenerator from "./formGenerator/view/Home";
import baseInfo from "./baseInfo";
import workflow from "./workflow";
import dialogCommon from "@/mixins/dialogCommon"
import { getDetail, create, edit, createTask, detail, getGlobalTemplate, addGlobalTemplate, editGlobalTemplate, editTask, batchCreate, createApproveTemplate, getTemplateDetail,editTemplate } from "@/api/approve"
import { cloneDeep, isObject, isArray, findIndex, dateContrast, getDateRange } from "@/utils/index"
const defaultTipList = {
  workflow: [],
  info: []
}
export default {
  mixins: [dialogCommon],
  components: {
    formGenerator,
    baseInfo,
    workflow
  },
  props: {},
  provide() {
    return {
      id: this.taskObj,
      task: this.taskObj,
      approve: this.approveObj
    }
  },
  computed: {
    dialogTitle() {
      return `${this.id ? '编辑' : '新增'}${this.title || '表单'}`
    },
    task() {
      return this.taskObj.task;
    },
    batchTask() {
      return this.taskObj.batchTask;
    },
    filings() {
      return this.taskObj.filings;
    },
    approve() {
      return this.approveObj.approve;
    }
  },

  data() {
    return {
      activeName: 'form',
      loading: false,
      tipList: Object.assign({}, defaultTipList),
      defaultWorkflow: Object.assign({}, this.$store.state.approve?.form?.workflow),
      tipVisible: false,
      isTried: false,
      id: 0,
      show: false,
      copy: false,
      title: '',
      indicatorId: 0,
      taskObj: {
        task: false,
        id: 0,
        batchTask: false,
        //是否为备案任务
        filings: false,
        //备案任务状态
        filingsStatus: ''
      },
      //报销预审任务
      approveObj: {
        approve: false
      },
      formShow: false,
      isGlobal: false,
      isGlobalNone: false
    }
  },
  methods: {
    //{id = 0, title = '新建任务', indicatorId = 0, task = false, indicatorName = '', indicatorDate = ''}
    handleOpen(options) {
      this.id = options?.id || 0;
      this.title = options?.title || '新建任务';
      this.indicatorId = options?.indicatorId || 0;
      this.taskObj.task = !!options?.task;
      this.taskObj.id = options?.id || 0;
      this.taskObj.batchTask = !!options?.batchTask;
      this.taskObj.filings = !!options?.filings;
      this.approveObj.approve = !!options?.approve;
      this.isGlobal = !!options?.isGlobal;
      if (!this.isGlobal && !options?.task) this.activeName = 'baseInfo';
      if (!!options?.task) this.taskInit(options?.indicatorName, options.indicatorDate, options?.leader || [], options?.employee || [], !!options?.batchTask, options?.department);
      if (this.isGlobal || (!this.id && !this.task && !this.approve)) this.globalTemplate(options?.leader || []);
      if (options?.approve) this.approveInit();
      history.pushState({ type: 'open' }, '', this.$route.path + '#open');
      this.show = true;
    },
    async taskInit(indicatorName, indicatorDate, leader, employee, isBatchTask, personDepId) {
      this.activeName = this.task ? 'baseInfo' : 'form';
      //新建的时候拉取数据
      if (this.id == 0) {
        // this.globalTemplate(indicatorName,indicatorDate,leader,employee);
        //以后再说
        await this.getTemplate(indicatorName, indicatorDate, leader, employee, isBatchTask, personDepId);
        // if(!this.formShow){
        //   this.globalTemplate(indicatorName,indicatorDate);
        // }
      }
    },
    globalTemplate(leader) {
      this.loading = true;
      let options = {};
      if (this.taskObj.filings) {
        options.module = 'filings';
      }
      return getGlobalTemplate(options).then(res => {
        if (res.data?.formGennerator && res.data?.formGennerator?.length > 0) {
          this.isGlobalNone = false;
          let controlData = cloneDeep(res.data.formGennerator);
          this.formatControlData(controlData);
          this.$store.commit('initApproveFormData', {
            formGennerator: controlData,
            workflow: this.$store.state.approve?.form.workflow,
            info: this.$store.state.approve?.form?.info,
          });
          //注入数据
          this.$store.commit('approveSetData', {
            'form.info.taskLeader': leader.map(el => { el.employeeId = el.id; return el; })
          });

          this.$nextTick(() => {
            this.$store.commit('updatePropertyValue');
          })
        } else {
          if (this.task) {
            this.$message.error('未找到对应的任务模板，请先新建全局模板');
            this.onClose(false);
            return;
          }
          //默认编辑
          this.isGlobalNone = true;
        }
      })
        .finally(() => {
          this.loading = false;
          this.formShow = true;
        })
    },
    getTaskDetail() {
      this.loading = true;
      detail({
        taskId: this.id
      })
        .then(res => {
          let controlData = cloneDeep(res.data.taskTemplate);
          this.formatControlData(controlData);
          this.$store.commit('initApproveFormData', {
            formGennerator: controlData,
            info: {
              "createTask": res.data.indicatorId,
              "name": res.data.name,
              "parentName": res.data.indicator.name,
              "date": [res.data.startDate, res.data.endDate],
              "createRule": res.data.createRule,
              "overdueValidDay": res.data.overdueValidDay,
              "personId": res.data.person?.id ? [{ id: res.data.person.id, name: res.data.person.nickname, employeeId: res.data.person.id }] : [],
              "personDepId": res.data.personDep ? res.data.personDep : [],
              "collaborator": res.data.collaborator || [],
              "collaborationDep": res.data.collaborationDep || [],
              "indicatorDate": getDateRange(res.data.indicator.startTime, res.data.indicator.endTime),
              "taskLeader": res.data.taskLeader ? res.data.taskLeader.map(el => { el.id = el.employeeId; el.name = el.nickname; return el; }) : [],
              "taskReviewer": res.data.taskReviewer ? res.data.taskReviewer.map(el => { el.id = el.employeeId; el.name = el.nickname; return el; }) : [],
              "taskScorePerson": res.data.taskScorePerson ? res.data.taskScorePerson.map(el => { el.id = el.employeeId; el.name = el.id == '00000000-0000-0000-0000-000000000000' ? '自动评分' : el.nickname; return el; }) : [],
              "taskFilingsPersonMany": res.data.taskFilingsPersonMany.map(el => {
                el.employeeId = el.id;
                return el;
              }) || [],
              "nodeType": res.data.nodeType
            },
            workflow: res.data.workflow
          });
          //备案任务
          if (res.data.type == 2) {
            this.taskObj.filings = true;
            this.taskObj.filingsStatus = res.data?.filingsStatus || '';
          }
        })
        .finally(() => {
          this.formShow = true;
          this.loading = false;
        })
    },
    //生成任务编辑权限
    taskEditStatusInit(status) {
      let safe = ['status_not_performed', 'status_in_progress', 'status_reject', 'status_revoke'];
      let editStatus = {};
      //此时任何状态都可以修改
      if (safe.includes(status)) return;
      //禁用审批流
      editStatus.workflow = false;
      //禁用计划开始时间
      editStatus.startDate = false;
      //计划结束时间
      editStatus.endDate = status == 'status_completed' ? false : 'add';
      //逾期空间
      editStatus.overdueValidDay = ['status_overdue_completion', 'status_completed'].includes(status) ? false : 'add';

      this.$store.commit('approveSetData', {
        editStatus
      })
    },
    //拉取模板数据
    getTemplate(indicatorName, indicatorDate, leader, employee, isBatchTask, personDepId) {
      return new Promise((pRes, pRej) => {
        if (!this.indicatorId) {
          pRes();
          return;
        }
        this.loading = true;
        getDetail({ id: this.indicatorId })
          .then((res) => {
            if (res.data.formGennerator && res.data.formGennerator?.length > 0) {
              this.formShow = true;
            } else {
              pRes();
              return;
            }
            let controlData = cloneDeep(res.data.formGennerator);
            this.formatControlData(controlData)
            res.data.workflow.directorMaxLevel = 6;
            this.$store.commit('initApproveFormData', {
              formGennerator: controlData,
              info: this.$store.state.approve?.form?.info,
              //备案任务套一层
              workflow: this.filings ? this.filingsWorkflowInit(res.data.workflow) : res.data.workflow
            });
            let taskLeader = res.data.info.taskLeader.map(el => { el.name = el.employeeOne.nickname; return el; });
            let taskReviewer = res.data.info.taskReviewer.map(el => { el.name = el.employeeOne.nickname; return el; });
            let taskScorePerson = res.data.info.taskScorePerson.length > 0 ? res.data.info.taskScorePerson.map(el => { el.name = el.employeeOne.nickname; return el; }) : [{
              "id": "00000000-0000-0000-0000-000000000000",
              "userId": "00000000-0000-0000-0000-000000000000",
              "name": "自动评分",
              "parentId": "00000000-0000-0000-0000-000000000000",
              "employeeId": "00000000-0000-0000-0000-000000000000",
              "type": 2,
              "avatar": ""
            }];
            //注入数据
            this.$store.commit('approveSetData', {
              'form.info.createTask"': this.indicatorId,
              'form.info.parentName"': indicatorName,
              'form.info.indicatorDate': indicatorDate,
              'form.info.date': [indicatorDate[0], indicatorDate[indicatorDate.length - 1]],
              'form.info.personId': employee.map(el => { el.employeeId = el.id; return el; }),
              'form.info.taskLeader': taskLeader,
              'form.info.taskReviewer': taskReviewer,
              'form.info.taskScorePerson': taskScorePerson,
              'form.info.personDepId': personDepId || [],
              "form.info.defaultBatchItem": {
                indicatorDate,
                "date": [indicatorDate[0], indicatorDate[indicatorDate.length - 1]],
                "createRule": 1,
                "overdueValidDay": 0,
                "personId": [],
                "personDepId": [],
                "collaborator": [],
                "collaborationDep": [],
                "taskLeader": taskLeader,
                "taskReviewer": taskReviewer,
                "taskScorePerson": taskScorePerson,
                "ruleEnd": 0,
                "ruleStart": 0,
                "workflow": this.updatePropertyValue(taskLeader, taskReviewer, taskScorePerson)
              }
            });
            this.$store.commit('resetApproveBatchForm')
            pRes();
          })
          .catch(err => {
            pRes();
            console.log(err)
          })
          .finally(() => {
            this.loading = false;
          })
      })
    },
    //请求详情数据
    getDetail() {
      if (!this.id) return;
      this.loading = true;
      if (this.approve) {
        getTemplateDetail({ id: this.id }).then(res => {
          this.formatControlData(res.data.formGennerator);
          this.$store.commit('initApproveFormData', {
            formGennerator: res.data.formGennerator,
            workflow: res.data.workflow,
            info: res.data.info
          })
        })
        .catch(err => {
          console.log(err)
        })
        .finally(() => {
          this.loading = false;
          this.formShow = true;
        })
      } else {
        getDetail({ id: this.id })
          .then((res) => {
            let controlData = cloneDeep(res.data.formGennerator);
            this.formatControlData(controlData)
            res.data.workflow.directorMaxLevel = 6;
            if (res.data.info) {
              res.data.info.taskLeader = res.data.info.taskLeader.map(el => { el.name = el.employeeOne?.nickname; return el; })
              res.data.info.taskReviewer = res.data.info.taskReviewer.map(el => { el.name = el.employeeOne?.nickname; return el; })
              res.data.info.taskScorePerson = res.data.info.taskScorePerson.map(el => { el.name = el.employeeOne?.nickname; return el; })
            }
            this.$store.commit('initApproveFormData', {
              formGennerator: controlData,
              workflow: res.data.workflow,
              info: res.data?.info || this.$store.state.approve?.form?.info
            });
          })
          .catch(err => {
            console.log(err)
          })
          .finally(() => {
            this.loading = false;
            this.formShow = true;
          })
      }
    },
    dialogHideBefore(done) {
      if (this.loading) {
        this.$message({
          type: 'warning',
          message: `正在${this.dialogTitle ? '提交' : '获取'}数据，请不要关闭此窗口`
        })
        done(false);

      } else {
        done(true);
      }
    },
    //关闭窗口的时候清空整个页面
    resetForm() {
      this.$nextTick(() => {
        this.activeName = 'form';
        this.title = '';
        this.id = 0;
        this.indicatorId = 0;
        this.taskObj.task = false;
        this.taskObj.batchTask = false;
        this.$store.commit('resetApproveForm');
        this.formShow = false;
        this.isGlobalNone = false;
        this.isGlobal = false;
      })
    },
    formInit() {
      if (!this.id && !this.isGlobal && !this.task) {
        this.formShow = true;
      }
      //监听用户点击后退事件
      window.onpopstate = (event) => {
        let hash = document.location.hash;
        if (!hash) {
          this.show = false;
        }
      };
      this.$nextTick(() => {
        //请求数据
        if (this.task && this.id) {
          this.getTaskDetail();
        } else {
          this.getDetail();
        }
      })
    },
    onClose(isUpdate) {
      this.$emit('close', isUpdate);
      this.show = false;
      window.onpopstate = null;
    },
    beforeClose(done) {
      this.$confirm('取消后系统将不会保存您配置的表单信息,确定取消么?')
        .then(_ => {
          done()
        })
        .catch(_ => { });
    },
    //用户手点退出
    userClose() {
      this.$confirm('取消后系统将不会保存您配置的表单信息,确定取消么?')
        .then(_ => {
          this.onClose(false);
        })
        .catch(_ => { });
    },
    infoCheck() {
      //判断区间及部门
      let form = this.$store.state.approve.form.info;
      let checkDep = form.collaborationDep.some(el => form['personDepId'].find(_el => el.id == _el.id));
      let checkEmployee = form.collaborator.some(el => el.id == form['personId']?.[0]?.id);
      let scoreEmployee = form.taskScorePerson.length == 0;
      let errList = [];
      if (form.name == '' && !this.approve) {
        errList.push({ name: '请填写任务名称' })
      }
      if (!this.batchTask) {
        if (form.personId.length == 0) {
          errList.push({ name: '请选择负责人' })
        }
        if (form.date.length == 0) {
          errList.push({ name: '请选择计划日期' })
        }
        if (checkEmployee) {
          errList.push({ name: '协作人不能包含负责人' })
        }
        if (checkDep) {
          errList.push({ name: '协作部门不能包含负责部门' })
        }
        if (scoreEmployee) {
          errList.push({ name: '请选择评分人或勾选自动评分' })
        }
        if (this.filings && form.taskFilingsPersonMany.length == 0) {
          errList.push({ name: '请添加备案审批人' })
        }
      } else {
        if (form.batchList.length == 0) {
          errList.push({ name: '至少添加一条任务数据' })
        }

        form.batchList.forEach((item, index) => {
          let _checkEmployee = item.collaborator.some(el => el.id == item['personId']?.[0]?.id);
          let _checkDep = item.collaborationDep.some(el => item['personDepId'].find(_el => el.id == _el.id));

          if (_checkEmployee) {
            errList.push({ name: `第${index + 1}条数据：协作人不能包含负责人` })
          }
          if (_checkDep) {
            errList.push({ name: `第${index + 1}条数据：协作部门不能包含负责部门` })
          }
        })
      }
      return errList;
    },
    approveInfoCheck() {
      //判断区间及部门
      let form = this.$store.state.approve.form.info;
      let errList = [];
      if (form.title == '') {
        errList.push({ name: '请填写任务名称' })
      }
      return errList;
    },
    workflowCheck() {
      let form = this.$store.state.approve.form.workflow;
      let errList = [];
      let nodeList = this.nodeFlat(form.nodeConfig, []);
      //评分人校验
      let scoreList = nodeList.find(el => el.type == 5);
      // if(!scoreList){
      //   errList.push({ name: '请配置评分节点' });
      // }
      if (scoreList && scoreList.nodeUserList.length == 0) {
        errList.push({ name: '请选择评分人' });
      }
      // if(nodeList && nodeList.length>0 && nodeList[nodeList.length-1].type != 5){
      //   errList.push({ name: '评分人必须为最后一个节点' });
      // }
      return errList;
    },
    approveWorkflowCheck() {
      let form = this.$store.state.approve.form.workflow;
      let errList = [];
      let nodeList = this.nodeFlat(form.nodeConfig, []);
      //评分人校验
      let approveCheck = nodeList.filter(el => el.type == 1);
      if (approveCheck.length == 0) {
        errList.push({ name: '请添加审批人' });
      }
      if (approveCheck.some(el => el?.nodeUserList?.length == 0)) {
        errList.push({ name: '存在未选择人员的审批人节点' });
      }
      return errList;
    },
    scoreLock(val) {
      let nodeList = this.nodeFlat(val.nodeConfig, []);
      this.$store.commit('setScoreLock', nodeList.find(el => el.type == 5));
    },
    //保存表单数据
    saveForm() {
      this.tipList = cloneDeep(defaultTipList);
      if (this.task) {
        this.tipList.info.push(...this.infoCheck());
      }
      if (this.approve) {
        this.tipList.info.push(...this.approveInfoCheck());
      }

      if (!this.task && !this.isGlobal) {
        if (this.approve) {
          this.tipList.workflow.push(...this.approveWorkflowCheck());
        } else {
          this.tipList.workflow.push(...this.workflowCheck());
        }
      }
      let form = cloneDeep(this.$store.state.approve.form);
      if (this.tipList.info.length !== 0 || this.tipList.workflow.length !== 0) {
        this.tipVisible = true;
        return;
      }
      this.isTried = true;
      //此处为报销预审
      if (this.approve) {
        if(this.id){
          this.$confirm('确定要保存当前修改的内容吗？', '提示', { type: 'warning' }).then(
            () => {
              this.loading = true;
              let options = { ...form };
              editTemplate({ ...options }).then((res) => {
                this.$message.success('表单编辑成功');
                this.onClose(true)
              }).catch(err => {
              }).finally(() => {
                this.loading = false
              })
            }
          )
        }else{
          createApproveTemplate({ ...form }).then(res => {
            this.$message.success('新增表单成功');
            this.onClose(true)
          })
        }
        return;
      }

      //特殊处理
      if (this.task && !this.batchTask) {
        form.info.startDate = form.info.date[0];
        form.info.endDate = form.info.date[1];
        if (this.id) {
          form.info.id = this.id;
        }
        if (this.filings) {
          form.taskType = 2;
        }
      } else if (this.batchTask) {
        form.info.batchList.forEach(el => {
          el.startDate = el.date[0];
          el.endDate = el.date[1];
          el.indicatorDate = [];
        });
        form = {
          info: {
            "batchList": form.info.batchList,
            "indicatorId": form.info.indicatorId,
            "name": form.info.name,
            "parentName": form.info.parentName,
            'createTask': form.info.createTask
          },
          formGennerator: form.formGennerator
        }
        this.loading = true;
        batchCreate({ ...form, indicatorId: this.indicatorId }).then((res) => {
          this.$message.success('批量新建成功');
          this.onClose(true)
        }).catch(err => {
        }).finally(() => {
          this.loading = false
        })
        return;
      }
      if (this.isGlobal) {
        this.$confirm('确定要保存当前修改的内容吗？', '提示', { type: 'warning' }).then(
          () => {
            this.loading = true;
            let options = { ...form };
            if (this.taskObj.filings) {
              options.module = 'filings';
            }
            editGlobalTemplate({ ...options }).then((res) => {
              this.$message.success('全局任务模板编辑成功');
              this.onClose(false)
            }).catch(err => {
            }).finally(() => {
              this.loading = false
            })
          }
        )
        return;
      }

      if (this.id) {
        this.$confirm('确定要保存当前修改的内容吗？', '提示', { type: 'warning' }).then(
          () => {
            this.loading = true;
            (this.task ? editTask : edit)({ ...form, indicatorId: this.indicatorId }).then((res) => {
              this.$message.success('编辑成功');
              this.onClose(true)
            }).catch(err => {
            }).finally(() => {
              this.loading = false
            })
          }
        )
      } else {
        this.loading = true;
        (this.task ? createTask : create)({ ...form, indicatorId: this.indicatorId }).then((res) => {
          this.$message.success('新建成功');
          this.onClose(true)
        }).catch(err => {
        }).finally(() => {
          this.loading = false
        })
      }
    },

    /* 格式化控件数据 */
    formatControlData(data) {
      data.forEach((item) => {
        item.__config__ = item.config

        item.__slot__ = item.slot || {};
        if (item.__config__.tagIcon === 'checkbox') {
          item.value = [];
        }
        if(this.approve){
          item.__config__.type = 'TYPE_INPUT'
        }
        if (item.__config__.tagIcon === 'upload' || item.__config__.tagIcon === 'upload-image') {
          item.action = '';
          item.__config__.buttonText = '点击上传';
          this.$set(item.__slot__, 'list-type', true);
        }
        item.__config__.layout = 'colFormItem'
        item.__config__.span = 24
        item.__config__.regList = []
        item.__config__.required = item.config.required === 1 ? true : false;
        item.__config__.showLabel = item.config.isGroup === 1 ? false : true;
        item.__config__.showLabel = item.config.tag === 'fxy-tips' ? false : item.__config__.showLabel;
        item.disabled = item.disabled === 1 ? true : false
        delete item.config
        delete item.slot
        if (item.__config__.isGroup === 1) {
          this.formatControlData(item.__config__.groupItem)
        }
      })
    },
    /* 判断错误信息 */
    reErr(data) {
      if (data.childNode) {
        if (data.childNode.type == 1) {//审批人
          if (data.childNode.error) {
            this.tipList.workflow.push({ name: data.childNode.nodeName, type: "审核人" })
          }
          this.reErr(data.childNode)
        } else if (data.childNode.type == 2) {
          if (data.childNode.error) {
            this.tipList.workflow.push({ name: data.childNode.nodeName, type: "抄送人" })
          }
          this.reErr(data.childNode)
        } else if (data.childNode.type == 3) {
          this.reErr(data.childNode.childNode)
        } else if (data.childNode.type == 4) {
          this.reErr(data.childNode)
          for (var i = 0; i < data.childNode.conditionNodes.length; i++) {
            if (data.childNode.conditionNodes[i].error) {
              this.tipList.workflow.push({ name: data.childNode.conditionNodes[i].nodeName, type: "条件" })
            }
            this.reErr(data.childNode.conditionNodes[i])
          }
        }
      } else {
        data.childNode = null
      }
    },
    //平铺node数据
    nodeFlat(node, arr) {
      if (node.childNode) {
        arr.push(node.childNode)
        this.nodeFlat(node.childNode, arr)
      }
      return arr;
    },
    //递归检查node数据
    readNodes(obj, arr = []) {
      if (isArray(obj?.conditionNodes)) {
        arr.push(...obj.conditionNodes)
      }
      if (isObject(obj)) {
        if (obj.childNode) this.readNodes(obj.childNode, arr);
        if (obj?.conditionNodes) this.readNodes(obj?.conditionNodes, arr);
      } else if (isArray(obj)) {
        obj.forEach(el => {
          if (el.childNode) this.readNodes(el.childNode, arr);
          if (el?.conditionNodes) this.readNodes(el?.conditionNodes, arr);
        })
      }
      //判断数据
      return arr;
    },
    //生成流程
    updatePropertyValue(taskLeader, taskReviewer, taskScorePerson) {
      let obj = {
        "directorMaxLevel": 6,
        "flowPermission": [1],
        "nodeConfig": {
          "pkId": "sid-start-node",
          "nodeName": "发起人",
          "type": 0,
          "priorityLevel": 0,
          "setType": 0,
          "selectMode": 0,
          "selectRange": 0,
          "examineRoleId": 0,
          "directorLevel": 0,
          "examineMode": 0,
          "noHanderAction": 0,
          "examineEndType": 0,
          "examineEndRoleId": 0,
          "examineEndDirectorLevel": 0,
          "ccSelfSelectFlag": 0,
          "conditionList": [],
          "nodeUserList": [],
          "conditionNodes": [],
        }
      };
      let infoData = [{
        "type": 1,
        "level": 1,
        "nodeUserList": [],
        "nodeName": "审核人",
        "priorityLevel": 0,
        "setType": 1,
        "selectMode": 0,
        "selectRange": 0,
        "directorLevel": 1,
        "examineMode": 1,
        "noHanderAction": 1,
        "examineEndDirectorLevel": 0,
        "ccSelfSelectFlag": 0,
        "nodeUserList": taskReviewer,
      },
      {
        "type": 5,
        "level": 1,
        "nodeUserList": [],
        "nodeName": "评分人",
        "priorityLevel": 0,
        "setType": 1,
        "selectMode": 0,
        "selectRange": 0,
        "directorLevel": 1,
        "examineMode": 1,
        "noHanderAction": 1,
        "examineEndDirectorLevel": 0,
        "ccSelfSelectFlag": 0,
        "nodeUserList": taskScorePerson,
      },
      {
        "type": 2,
        "level": 1,
        "nodeUserList": [],
        "nodeName": "抄送人",
        "priorityLevel": 0,
        "setType": 1,
        "selectMode": 0,
        "selectRange": 0,
        "directorLevel": 1,
        "examineMode": 1,
        "noHanderAction": 1,
        "examineEndDirectorLevel": 0,
        "ccSelfSelectFlag": 0,
        "nodeUserList": taskLeader,
      }];
      infoData = infoData.filter(el => el.nodeUserList.length > 0 && el.nodeUserList[0].id !== '00000000-0000-0000-0000-000000000000');
      for (let i = 0; i < infoData.length; i++) {
        if (infoData[i + 1]) {
          infoData[i].childNode = infoData[i + 1];
        }
      }
      if (infoData.length > 0) {
        obj.nodeConfig.childNode = infoData[0];
        return obj;
      } else {
        return []
      }
    },
    //初始化
    filingsWorkflowInit(workflow) {
      let nodeConfig = cloneDeep(workflow.nodeConfig);
      let employee = cloneDeep(this.$store.state.userInfo.employee);
      employee.employeeId = employee.id;
      employee.name = employee.nickname;
      workflow.nodeConfig = {
        "pkId": "sid-start-node",
        "nodeName": "创建人",
        "type": 8,
        "priorityLevel": 0,
        "setType": 0,
        "selectMode": 0,
        "selectRange": 0,
        "examineRoleId": 0,
        "directorLevel": 0,
        "examineMode": 0,
        "noHanderAction": 0,
        "examineEndType": 0,
        "examineEndRoleId": 0,
        "examineEndDirectorLevel": 0,
        "ccSelfSelectFlag": 0,
        "conditionList": [],
        "nodeUserList": [],
        "conditionNodes": [],
        "childNode": nodeConfig,
        "nodeUserList": [employee],
      }
      return workflow;
    },
    //初始化报销预审
    approveInit() {
      //替换掉默认数据
      this.$store.commit('approveSetData', {
        'form.info': {
          "title": '',
          "icon": 'fxy-icon-a_1',
          "iconColor": "#409EFF",
          "remark": '',
          "adminList": []
        },
        'form.formGennerator': [
          {
            __config__: {
              label: '村庄',
              tag: 'village',
              tagIcon: 'village',
              field: 'village',
              defaultValue: null,
              span: 24,
              showLabel: true,
              labelWidth: null,
              required: true,
              layout: 'colFormItem',
              regList: [],
              changeTag: true,
              type:"TYPE_INPUT",
              expandParams:{
                showLabel:true,
                descEdit:'',
                dialogTips:'',
                villagePath:[]
              }
            },
            placeholder: '请选择',
            style: {width: '100%'},
            disabled: true,
            clearable: true,
            readonly: false
          }
        ],
        'form.workflow': {
          "directorMaxLevel": 6,
          "flowPermission": [],
          "nodeConfig": {
            "pkId": "sid-start-node",
            "nodeName": "发起人",
            "type": 0,
            "priorityLevel": 0,
            "setType": 0,
            "selectMode": 0,
            "selectRange": 0,
            "examineRoleId": 0,
            "directorLevel": 0,
            "examineMode": 0,
            "noHanderAction": 0,
            "examineEndType": 0,
            "examineEndRoleId": 0,
            "examineEndDirectorLevel": 0,
            "ccSelfSelectFlag": 0,
            "conditionList": [],
            "nodeUserList": [],
            "conditionNodes": []
          }
        }
      })
    }
  },
  watch: {
    '$store.state.approve.form.workflow': {
      handler: function (val) {
        this.scoreLock(val);
        let arr = this.readNodes(val.nodeConfig, []);
        let checkList = [];
        arr.forEach(el => {
          if (isArray(el.conditionList)) {
            checkList.push(...el.conditionList)
          }
        });
        //获取是否有使用套件的数据
        let check = findIndex(checkList, (el) => {
          return el?.columnKey && el?.columnKey != 'default';
        });
        //是否锁住套件
        if (check == -1) {
          this.$store.commit('setSuiteLock', false);
        } else {
          this.$store.commit('setSuiteLock', true);
        }
      },
      deep: true
    },
    show(val) {
      if (!val && window.location.hash) {
        window.history.back();
      }
    },
  }

}
</script>

<style lang="scss" scoped>
::v-deep .approve-create-dialog {
  .el-dialog__header {
    display: none;
  }

  .el-dialog__body {
    padding: 0;
    height: 100%;
  }

  .approve-create-wrapper {
    height: 100%;
    overflow: hidden;
    position: relative;

    .save {
      position: absolute;
      right: 10px;
      height: 60px;
      z-index: 10;
      padding: 10px;
    }

    .back-cover {
      position: absolute;
      left: 10px;
      top: 0;
      height: 50px;
      z-index: 10;
      padding: 8px 10px 10px;

      .close-cover {
        border: 1px solid rgba(25, 31, 37, 0.18);
        border-radius: 50%;
        width: 32px;
        height: 32px;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        transition: box-shadow .3s;

        i {
          font-size: 18px;
        }

        &:hover {
          box-shadow: 0 2px 8px rgba(0, 0, 0, .1);
        }
      }
    }

    .el-tabs__header {
      margin-bottom: 0
    }

    .el-tabs__nav-scroll {
      text-align: center;

      .el-tabs__nav {
        float: none;
        display: inline-block;

        .el-tabs__item {
          font-size: 14px;
          height: 50px;
          line-height: 50px;
          color: #999999;

          &.is-active {
            color: #1890ff;
          }
        }
      }
    }

    .el-row {
      height: calc(100% - 49px);
    }
  }
}

.tip-dialog {
  ::v-deep .el-dialog__body {
    padding: 20px;
  }
}
</style>
