<template>
<div class="form-number-wrapper">
    <el-input :disabled="disabled" :value="value" @input="setValue" :placeholder="placeholder" @change="suiteDataCheck"></el-input>
</div>
</template>
<script>

export default {
    inject: ['root'],
    props:{
        value:{
            default:''
        },
        formData:{
            type:Object,
            default:()=>({})
        }
    },
    computed:{
        numberValue:{
            get(){
                return this.value;
            },
            set(val){
                this.$emit('input',val);
            }
        },
        disabled(){
            return this.formData?.config?.field == 'duration' || !!this.formData?.config?.expandParams?.disabled;
        },
        placeholder(){
            return this.formData?.config?.field == 'duration' ? '自动获取' : this.formData?.placeholder ? this.formData?.placeholder :  '请输入';
        }
    },
    methods:{
        suiteDataCheck(){
            this.root.suiteDataCheck && this.root.suiteDataCheck(this.formData);
        },
        setValue(val){
            val = val.toString().replace(/[^\d.]/g, "");
            val = /^[0]+\d+/.test(val) ? val.replace(/^[0]+/, "") : val;
            val = val.replace(/\.{2,}/g, ".");
            //必须保证第一个为数字而不是.
            val = val.replace(/^\./g, "");
            //保证.只出现一次，而不能出现两次以上
            val = val.replace(".", "$#$").replace(/\./g, "").replace("$#$", ".");
            //只能输入两个小数
            val = val.replace(/^(\\-)*(\d+)\.(\d\d).*$/, "$1$2.$3");
            //转化为数字进行判断
            let check = parseFloat(val);
            if (/[0]+\./.test(val)) {
            val = val;
            } else {
            if (!check || check <= 0) {
                val = 0;
            } else {
                val = val;
            }
            }
            this.$emit("input", val);
        }
    }
}
</script>

<style lang="less" scoped>

</style>