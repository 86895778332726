import {getValues} from "@/utils/arr"

export default {
  data() {
    return {}
  },
  computed: {
    promoterCheckList: {
      get: function () {
        let checkList = this.flowPermission1;
        return checkList.length > 0 ? checkList : [{name:'所有人',id:''}] // ['所有人']
      },
      set: function (value) {
      }
    },
    conditionNodeUserList: {
      get: function () {
        let checkList = this.conditionConfig.nodeUserList;
        return checkList.length > 0 ? checkList : []
      },
      set: function (value) {
      }
    },
    workflowDisabled(){
      return !this.$store.state.approve?.editStatus?.workflow;
    }
  },
  methods: {
    arrToStr(arr) {
      if (arr) {
        return arr.map(item => {
          return this.isApprove ? item.name : item === 1 ? '负责人' : '协作人'
        }).toString()
      }
    },
    arrApproveToStr(arr) {
      if (arr) {
        return arr.map(item => {
          return item.name
        }).toString()
      }
    },
    clickEvent(index) {
      if(this.workflowDisabled) return;
      if (index || index === 0) {
        this.$set(this.isInputList, index, true)
      } else {
        this.isInput = true;
      }
    },
    blurEvent(index) {
      if (index || index === 0) {
        this.$set(this.isInputList, index, false)
        this.nodeConfig.conditionNodes[index].nodeName = this.nodeConfig.conditionNodes[index].nodeName ? this.nodeConfig.conditionNodes[index].nodeName : "条件"
      } else {
        this.isInput = false;
        this.nodeConfig.nodeName = this.nodeConfig.nodeName ? this.nodeConfig.nodeName : this.placeholderList[this.nodeConfig.type]
      }
    },
    conditionStr(item, index) {
      var {conditionList, nodeUserList} = item;
      if (conditionList.length == 0) {
        return (index == this.nodeConfig.conditionNodes.length - 1) && this.nodeConfig.conditionNodes[0].conditionList.length != 0 ? '其他条件进入此流程' : '请设置条件'
      } else {
        let str = ""
        for (var i = 0; i < conditionList.length; i++) {
          var {
            columnKey,
            columnType,
            showType,
            showName,
            optType,
            zdy1,
            opt1,
            zdy2,
            opt2,
            fixedDownBoxValue
          } = conditionList[i];
          if (columnKey == 'default') {
            if (nodeUserList.length != 0) {
              str += '发起人属于：'
              str += nodeUserList.map(item => {
                return item.name
              }).join("或") + " 并且 "
            }
          }
          if (columnType == "String" && showType == "3") {
            if (zdy1) {
              str += showName + '属于：' + this.dealStr(zdy1, fixedDownBoxValue) + " 并且 "
            }
          }
          if (columnType == "Double") {
            if (optType != 6 && zdy1) {
              var optTypeStr = ["", "<", ">", "≤", "=", "≥"][optType]
              str += `${showName} ${optTypeStr} ${zdy1} 并且 `
            } else if (optType == 6 && zdy1 && zdy2) {
              str += `${zdy1} ${opt1} ${showName} ${opt2} ${zdy2} 并且 `
            }
          }
        }
        return str ? str.substring(0, str.length - 4) : '请设置条件'
      }
    },
    dealStr(str, obj) {
      let arr = [];
      let list = str.split(",");
      for (var elem in obj) {
        list.map(item => {
          if (item == obj[elem].value) {
            arr.push(obj[elem].label)
          }
        })
      }
      return arr.join("或")
    },
  }
}
