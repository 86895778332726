<template>
<div class="salary-table-wrapper">
    <el-button type="primary" plain icon="el-icon-link" @click="checkDepPayroll">点击此处查看工资单明细</el-button>
</div>
</template>
<script>

export default {
    inject: ['root'],
    methods:{
        //查看工资单明细
        checkDepPayroll(){
            this.root.checkDepPayroll && this.root.checkDepPayroll();
        }
    }

}
</script>

<style scoped>

</style>