<template>
  <el-drawer :title="applyDetailDialogTitle" :visible.sync="dialogShow" :show-close="false" direction="rtl"
    @open="formInit" :size="fullScreen ? '100%' : '80%'" @closeDrawer="close" @closed="resetForm" append-to-body :wrapperClosable="false">
    <div slot="title">
      <drawerTitle ref="drawerTitle" :dialogTitle="applyDetailDialogTitle" :fullScreen="fullScreen"
        @fullScreenChange="fullScreenChange" @close="close" :list="list" @pagerChange="pageChange" :active="id">
      </drawerTitle>
    </div>
    <apply-detail ref="applyDetail" :data="pageId || id" @close="close" :lock="lock" :operate="operate"
      @titleChange="setApplyDialogTitle" @resetList="resetList" :isShow="dialogShow"></apply-detail>
  </el-drawer>
</template>
<script>
import applyDetail from "./applyApproveDetail";
import drawerTitle from "@/components/drawerTitle";
import { isArray } from "@/utils";
export default {
  data() {
    return {
      title: '',
      fullScreen: false,
      pageId: '',
      dialogShow: false,
      id: '',
      lock: [],
      operate: true,
      list: [],
      dialogTitle: '审批单详情'
    }
  },
  components: {
    applyDetail,
    drawerTitle
  },
  computed: {
    applyDetailDialogTitle() {
      return this.title || this.dialogTitle;
    }
  },
  methods: {
    handleOpen(options) {
      this.id = options?.id || '';
      this.list = options?.list || [];
      this.operate = options?.operate === undefined ? true : options?.operate;
      this.lock = options?.lock || [];
      this.dialogShow = true;

    },
    //初始化的时候合并一下选项
    formInit() {
      this.$nextTick(() => {
        this.$refs.applyDetail?.formInit && this.$refs.applyDetail.formInit();
        if (isArray(this.list) && this.list.length > 0) {
          this.$refs.drawerTitle?.initPager && this.$refs.drawerTitle?.initPager();
        }
      })
    },
    pageChange(id) {
      this.pageId = id;
      //清空
      this.$refs.applyDetail?.resetForm && this.$refs.applyDetail.resetForm();
      this.$nextTick(() => {
        this.$refs.applyDetail?.formInit && this.$refs.applyDetail.formInit();
      })
    },
    resetForm() {
      this.$refs.applyDetail?.resetForm && this.$refs.applyDetail.resetForm();
      this.pageId = '';
      this.close()
    },
    close(update = false) {
      this.dialogShow = false;
      this.$emit('close', update);
    },
    setApplyDialogTitle(title) {
      this.title = title;
    },
    resetList() {
      this.$emit('resetList');
    },
    fullScreenChange(fullScreen) {
      this.fullScreen = fullScreen;
    }
  }
};
</script>

<style lang="scss" scoped>
::v-deep .el-drawer__header {
  margin-bottom: 32px;
}
</style>
