<template>
<div class="date-range-wrapper">
     <el-date-picker
      v-model="dateValue"
      type="daterange"
      :format="format"
      :value-format="format"
      range-separator="至"
      start-placeholder="开始日期"
      end-placeholder="结束日期">
    </el-date-picker>
</div>
</template>
<script>

export default {

    props:['value','formData'],
    inject: ['root'],
    computed:{
        dateValue:{
            get(){
                return this.value
            },
            set(val){
                return this.$emit('input',val);
            }
        },
        placeholder(){
            return this.formData?.placeholder || '请选择'
        },
        format(){
            return 'yyyy-MM-dd';
        },
    }


}
</script>

<style lang="scss" scoped>
    ::v-deep .el-range-editor{width: 100%!important;}
</style>