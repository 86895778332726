import request from '@/utils/request'

export function list(data) {
  return request({
    url: `/api/question/question-list?page=${data.page}&pageSize=${data.pageSize}`,
    method: 'post',
    data
  })
}

export function exportList(data) {
  return request({
    url: `/api/question/question-list?page=${data.page}&pageSize=${data.pageSize}`,
    method: 'post',
    data,
    responseType: 'blob',
    timeout: 60000
  })
}

export function questionDelete(data) {
  return request({
    url: `/api/question/question-delete`,
    method: 'post',
    data
  })
}

export function detail(data) {
  return request({
    url: `/api/question/question-detail`,
    method: 'get',
    params:data
  })
}

export function questionLogList(data) {
  return request({
    url: `/api/question/question-log-list`,
    method: 'get',
    params:data
  })
}


export function approve(data) {
  return request({
    url: `/api/question/question-approve`,
    method: 'post',
    data
  })
}


export function approveList(data) {
  return request({
    url: `/api/question/question-approve-list?page=${data.page}&pageSize=${data.pageSize}`,
    method: 'get',
    params:data
  })
}

export function statisticsList(data) {
  return request({
    url: `/api/question/question-statistics`,
    method: 'get',
    params:data
  })
}

//问题审核状态列表
export function approveStatusList(data) {
  return request({
    url: `/api/question/question-approve-status-list`,
    method: 'get',
    params:data
  })
}

//问题状态列表
export function statusList(data) {
  return request({
    url: `/api/question/question-status-list`,
    method: 'get',
    params:data
  })
}

//问题分类列表
export function questionCategoryList(data) {
  return request({
    url: `/api/question/question-category-list`,
    method: 'get',
    params:data
  })
}

//问题分类编辑新增
export function questionCategorySave(data) {
  return request({
    url: `/api/question/question-category-save`,
    method: 'post',
    data
  })
}

//问题分类删除
export function questionCategoryDelete(data) {
  return request({
    url: `/api/question/question-category-delete`,
    method: 'post',
    data
  })
}


//保存
export function save(data) {
  return request({
    url: `/api/question/question-save`,
    method: 'post',
    data
  })
}