import {isArray, isPhone, cloneDeep, isObjectNone, findIndex, isObject,isEqual,findLastIndex,guid,isString,sumBy,readNodes,isMoney} from "@/utils";
import timeFormat from "@/utils/timeFormat";
import test from "@/utils/test";
import {formatDate} from "@/utils/date";
import {attendanceDuration,getApproveWorkflow,clockClassPlan,getSalaryEmployeeArchives,getShiftJobsControl} from "@/api/approve";
import {detail} from "@/api/employee"
export default {
    /**
     *
     * 用于处理表单数据创建
     *
     */
    data() {

        return {
            //工资单弹窗数据
            salaryDialogConfig:{
                title:'工资单明细',
                date:'',
                depId:''
            },
            //表单对应类型列表
            formItemList: {},
            //渲染用列表
            formList: [],
            //用于比较的缓存数据
            formListBak:[],
            //提交用数据
            formData: [],
            labelPosition: 'top',
            hideLabelTags: {
                'tips': true,
                'upload-image': true,
                'upload': true
            },
            dateIndex: 0,
            //时间区间参数格式
            timeParams: {
                //小时+分钟
                'hh:MM': {
                    hour: true,
                    minute: true,
                    timestamp: true
                },
                //小时+分钟+秒数
                'hh:MM:ss': {
                    hour: true,
                    minute: true,
                    second: true,
                    timestamp: true
                }
            },
            //所选表单参数列表
            timeList: [
                [
                    {
                        label: '0时',
                        value: 0
                    }, {
                    label: '01时',
                    value: 1
                }, {
                    label: '02时',
                    value: 2
                }, {
                    label: '03时',
                    value: 3
                }, {
                    label: '04时',
                    value: 4
                }, {
                    label: '05时',
                    value: 5
                }, {
                    label: '06时',
                    value: 6
                }, {
                    label: '07时',
                    value: 7
                }, {
                    label: '08时',
                    value: 8
                }, {
                    label: '09时',
                    value: 9
                }, {
                    label: '10时',
                    value: 10
                }, {
                    label: '11时',
                    value: 11
                }, {
                    label: '12时',
                    value: 12
                }, {
                    label: '13时',
                    value: 13
                }, {
                    label: '14时',
                    value: 14
                }, {
                    label: '15时',
                    value: 15
                }, {
                    label: '16时',
                    value: 16
                }, {
                    label: '17时',
                    value: 17
                }, {
                    label: '18时',
                    value: 18
                }, {
                    label: '19时',
                    value: 19
                }, {
                    label: '20时',
                    value: 20
                }, {
                    label: '21时',
                    value: 21
                }, {
                    label: '22时',
                    value: 22
                }, {
                    label: '23时',
                    value: 23
                }
                ],
                [
                    {
                        label: "0分",
                        value: 0
                    },
                    {
                        label: "15分",
                        value: 15
                    },
                    {
                        label: "30分",
                        value: 30
                    }, {
                    label: "45分",
                    value: 45
                }
            ]],
            popupCover: {},
            //保存上一次筛选条件
            conditionOption:null,
            //缓存的各种块级数据
            suiteBlocks:{},
            //含有明细的套件
            detailSuite:['collectionSuite','reimbursementSuite','punishSuite','salaryIncreaseSuite'],
            //需要回填的套件
            reloadSuite:['dealSuite','dealExpendSuite','frmLossSuite','rewardSuite'],
            //回流的数据
            reloadFormData:{},
            //当前所包含的套件名称
            suiteName:'',
            //回调列表
            callbacks:[],
            approveId:'',
            flowLoading:false
        }

    },

    filters: {
        imageUrl(value) {
            if (!value) return "";
            if (value.indexOf("http:") != -1 || value.indexOf("https:") != -1) {
              return value;
            } else {
              return process.env.VUE_APP_IMG_URL + value;
            }
          },
    },

    computed: {

        //当前的申请人
        petitioner() {
            return this.$store.state.userInfo?.employee;
        },

        //获取当前时间
        nowDate() {
            return (new Date()).getHours();
        },
        //获取当前时间戳
        nowTime(){
            return (new Date()).getTime();
        }
    },

    methods: {
        //每次异步请求完毕后执行
        updateNext(callback){
            this.callbacks.push(callback);
        },
        //读取item label
        getItemLabel(item){
            return item.tagIcon == 'tips' || item.tagIcon == 'edit' ? '' : item.label
        },
        //是否显示切换年份按钮
        changeYearBtn(item) {
            return item.config.field != 'workAttendanceCardDate';
        },
        //表单校验
        formUpdateCheck() {
            //生成处理用数据
            let formData = [];
            this.formList.forEach(el=>{
                if(!el?.config?.isInfo && !el.config?.expandParams?.readonly){
                    if(el.config.notRender){
                        let groupItem = el.config.groupItem.filter(_el=>{
                            return !_el.config?.expandParams?.readonly;
                        });
                        formData.push(...groupItem);
                    }else{
                        formData.push(el);
                    }
                }
            });
            //此处剔除掉时间和只读元素
            formData = formData.filter(el=>{
                return el.config?.tagIcon != 'time';
            });
            for (let i = 0; i < formData.length; i++) {
                let item = formData[i];
                let tips = item.config.tagIcon == 'radio' || item.config.tagIcon == 'checkbox' || item.config.tagIcon == 'date' || item.config.tagIcon == 'date-range' || item.config.tagIcon == 'department' || item.config.tagIcon == 'contact' ? '请选择' : item.config.tagIcon == 'upload' || item.config.tagIcon == 'upload-image' ? '请添加' : '请填写';
                //进行必填校验
                if (!!item.config.required) {
                    //单独校验
                    if (item.config.tagIcon == 'radio' && !item.value && item.value?.length == 0) {
                        this.$message.error(`${tips}${item.config.label}`);
                        return false;
                    }

                    if (item.config.tagIcon == 'checkbox' && item.value.length == 0) {
                        this.$message.error(`${tips}${item.config.label}`);
                        return false;
                    }

                    if (item.config.tagIcon == 'contact' && item.value.length == 0) {
                        this.$message.error(`${tips}${item.config.label}`);
                        return false;
                    }

                    if ((item.config.tagIcon == 'upload' || item.config.tagIcon == 'upload-image') && item.value.length == 0) {
                        this.$message.error(`${tips}${item.config.label}`);
                        return false;
                    }

                    if (item.value === '' || item.value === undefined || item.value === null || (isArray(item.value) && item.value.length == 0)) {

                        this.$message.error(`${tips}${item.config.label}`);
                        return false;

                    }

                }
                if(!this.formatCheck(item)){
                    return false;
                }

            }

            //底部进行特殊校验
            let check = this.suiteCheck();
            return check;

        },
        //套件特殊校验
        suiteCheck(){
            //还款套件及成交单套件校验
            if(['dealExpendSuite','payBackSuite'].includes(this.suiteName)){
                //获取金额组件 , 成交支出获取用户还款组件
                let totalMoney = this.getFormItem({field:this.suiteName == 'dealExpendSuite' ? 'realityMoney' : 'totalMoney'});
                //未还余额
                let balance = totalMoney?.config?.expandParams?.balance || 0;
                //当前填写的返现金额
                let money = isNaN(parseFloat(totalMoney.value)) ? 0 : parseFloat(totalMoney.value);
                //实际返费金额(减去已冻结金额)
                let realityBalance = totalMoney?.config?.expandParams?.realityBalance || 0;
                //被冻结的金额
                let freezeBalance = totalMoney?.config?.expandParams?.freezeBalance || 0;
                //未还金额小于或等于0 不拦截
                if(balance <=0){
                    if(this.suiteName == 'payBackSuite'){
                        this.$message.error('当前无欠款，无法还款');
                        return false;
                    }else{
                        return true;
                    }
                }
                //应返金额小于或等于实际返费金额的时候，不需要拦截
                if(money <= realityBalance) return true;
                //应返金额大于实际返费金额，阻止提交
                if(money > realityBalance){
                    if(freezeBalance > 0){ 
                        this.$message.error('当前该分销商/员工有涉及到还款的审批单正在审批中，无法发起该审批');
                        return false;
                    }else{
                        this.$message.error(`当前可还最大额度为 ${realityBalance} ， 请修改${totalMoney.config.label}`);
                        return false;
                    }
                }

            }
            return true;
        },
        //生成提交用数据
        createUploadFormData() {
            let formList = cloneDeep(this.formList).filter(el=>{
                return !el?.config?.isInfo
            });
            let formData = [];
            //套件数据修改
            this.suiteUploadFormDataHandle(formList);
            //将所有对象修改成一维数组
            formList.forEach(el => {
                if (isObject(el.value) && !el.config.notRender) {
                    el.value = [el.value];
                }
            });
            //数据处理
            formList.forEach(el => {
                //带有明细的组件
                if(el.config.notRender){
                    el.config.groupItem.forEach(_el=>{
                        //单选框特殊处理
                        if(_el.config.tagIcon == 'radio'){
                            //根据类型进行判断
                            let item;
                            if(!_el.config?.mode || _el.config?.mode == 'single-column'){
                                item = _el.slot.options.filter(__el => {
                                    return __el.value == _el.value;
                                });
                            }else{
                                item = [{
                                    label:_el.config?.caLabel || '',
                                    value:_el.value
                                }]
                            }
                            _el.value = item;
                        };
                        if (isObject(_el.value)) {
                            _el.value = [_el.value];
                        }
                        //附件数据处理
                        if (_el.config.tagIcon == 'upload') {
                            _el.value = _el.value.map(__el => {
                                return {
                                    label: __el?.name || __el?.label,
                                    value: __el?.url || __el?.value
                                }
                            })
                        }
                        //生成checkbox 数据
                        if (_el.config.tagIcon == "t-form-checkbox") {
                            let list = [];
                            _el.value.forEach(v=>{
                                let item = _el.slot.options.find(_item=>{
                                    return _item.value == v;
                                });
                                if(item){
                                    list.push(item);
                                }
                            });

                            _el.value = list;
                        }

                        if (_el.config.tagIcon == 'upload-image') {
                            _el.value = _el.value.map(__el => {
                                return {
                                    label: '',
                                    value: __el
                                }
                            })
                        }

                        
                        if(_el.config.tagIcon == 'date-range'){
                            _el.value = isArray(_el.value) ?  _el.value.join(' 至 ') : _el.value;
                        }

                        if(['contact','department','position'].includes(_el.config.tagIcon)){   
                            _el.value = _el.value.map(item=>{
                                return {
                                    label:item?.name || item?.label,
                                    value:item?.id || item?.value
                                }
                            })
                        }
                    })
                }else{
                    //单选框特殊处理
                    if(el.config.tagIcon == 'radio'){
                         //根据类型进行判断
                         let item;
                         if(!el.config?.mode || el.config?.mode == 'single-column'){
                             item = el.slot.options.filter(_el => {
                                return _el.value == el.value;
                             });
                             if(item.length == 0 && item.slot?.optionsBack){
                                item = el.slot.optionsBack.filter(_el => {
                                    return _el.value == el.value;
                                });
                             }
                         }else{
                             item = [{
                                 label:el.config?.caLabel || '',
                                 value:el.value
                             }]
                         }
                         el.value = item;
                    }; 
                    if (isObject(el.value)) {
                        el.value = [el.value];
                    }
                    //附件数据处理
                    if (el.config.tagIcon == 'upload') {
                        el.value = el.value.map(_el => {
                            return {
                                label: _el?.name || _el?.label,
                                value: _el?.url || _el?.value
                            }
                        })
                    }
                    //生成checkbox 数据
                    if (el.config.tagIcon == "t-form-checkbox") {
                        let list = [];
                        el.value.forEach(v=>{
                            let item = el.slot.options.find(_item=>{
                                return _item.value == v;
                            });
                            if(item){
                                list.push(item);
                            }
                        });

                        el.value = list;
                    }

                    if (el.config.tagIcon == 'upload-image') {
                        el.value = el.value.map(el => {
                            return {
                                label: '',
                                value: el
                            }
                        })
                    }

                    if(el.config.tagIcon == 'date-range'){
                        el.value = isArray(el.value) ?  el.value.join(' 至 ') : el.value;
                    }

                    if(['contact','department','position'].includes(el.config.tagIcon)){
                        el.value = el.value.map(item=>{
                            return {
                                label:item?.name || item?.label,
                                value:item?.id || item?.value
                            }
                        })
                    }
                }
                

            });

            //首先提取所有套件组件
            let suite = formList.filter(el => {
                return el.isSuite;
            });

            //存在套件时才执行
            if (suite.length > 0) {
                //获取原始请求数据的套件
                let oldSuite = this.formData.filter(el => {
                    return el.config.isGroup;
                });

                //获取原始套件的index

                let index = findIndex(this.formData, el => {
                    return el.config.isGroup;
                });

                //覆盖添加
                oldSuite[0].config.groupItem = suite;

                //添加非套件组件
                let _normal = formList.filter(el => {
                    return !el.isSuite;
                });

                formData.push(..._normal);
                //按顺序添加套件
                formData.splice(index, 0, oldSuite[0]);


                return formData;

            } else {

                return formList;

            }
        },
        suiteUploadFormDataHandle(formList){
            //请假调休套件特殊处理
            if(this.suiteName == 'leaveSuite'){
                formList.forEach(el=>{
                    if(['leaveStartDate','leaveEndDate'].includes(el?.config?.field)){
                        //按天选择
                        if(el.timeUnit == 1){
                            if(el.config?.field == 'leaveStartDate'){
                                el.value = `${el.value} 00:00:00`
                            }else{
                                el.value = `${el.value} 23:59:59`
                            }
                        }
                        //切割数据
                        let date = el.value.split(' ');
                        el.value = date[0];
                        let timeKey = el?.config?.field == 'leaveStartDate' ? 'leaveStartTime' : 'leaveEndTime';
                        //获取开始时间数据
                        let timeIndex = findIndex(formList,_el=>{
                            return _el?.config?.field == timeKey;
                        });
                        if(timeIndex != -1){
                            formList[timeIndex]['value'] = date.pop();
                        }
                    }

                    if(el?.config?.field == 'duration'){
                        el.value = el.realityValue || el.value;
                    }
                    
                })
            }
        },
        //表单数据初始化
        formInit(formData) {
            if (!isArray(formData)) {
                console.error('表单生成必须要使用一个数组');
                return;
            }
            //备份数据提交用
            this.formData = cloneDeep(formData);
            //此时进行一些判断
            //为所有表单添加值变量
            formData.forEach((el) => {
                //是否为套件
                if (!!el.config?.isGroup) {
                    //套件为他添加预设好的值
                    isArray(el.config?.groupItem) && el.config.groupItem.forEach(_el => {
                        _el.value = '';
                        _el.isSuite = true;
                        _el.suiteName = el.config.field;
                        if(_el.config.tagIcon == 'date'){
                            if(['leaveEndDate','leaveStartDate'].includes(_el.config?.field)){
                                _el.format = 'yyyy-mm-dd hh:MM'
                            }else{
                                _el.format = _el.format == 'yyyy-MM-dd' || !_el?.format ? 'yyyy-mm-dd' : _el.format
                            }
                        }
                        this.formItemInit(_el);
                    })

                    //套件专属方法初始化
                    this.suiteInit(el);
                } else {

                    el.value = ''

                    this.formItemInit(el);

                    this.formList.push(el);
                }
            });

            this.formListBak = cloneDeep(this.formList);
        },
        //过滤掉级联的不限选项
        slotOptionsInfinityFilter(item){
            if(isArray(item?.children || item?.options)){
                let _item = item?.children || item?.options;
                _item.forEach(el=>{
                    if(isArray(el.children)){
                        el.children = el.children.filter(el=>{
                            return el.label != '不限'
                        });
                        if(el.children.length == 0){
                            el.children = null;
                        }
                    }
                    this.slotOptionsInfinityFilter(el);
                });
            }
        },
        //表单元素初始化
        formItemInit(el,isAdd=false) {
            el.guid = guid(32);
            el.config.isHide = !!el.config?.expandParams?.isHide;
            if((el.value || el.value === 0) && !isAdd){
                return;
            }
            //checkbox 特殊处理
            if (el.config.tagIcon == 'checkbox') {
                el.value = [];
                el.config.tagIcon='t-form-'+el.config.tagIcon;
                return;
            }
            if(el.config.tagIcon == 'time'){
                el.config.isInstead =true;
                return;
            };
            //日期特殊处理
            if (el.config.tagIcon == 'date' || el.config.tagIcon == 'date-range' || el.config.tagIcon == 'radio') {
                if (el.config.tagIcon == 'date-range') {
                    el.dateText = '';
                    el.config.dateTemplateType = 'date';
                } else if (el.config.tagIcon == 'radio') {
                    //单选时得判断该组件是否为多选
                    let list = el.slot.options.filter(el=>{
                        return isArray(el.children);
                    });
                
                    if(list.length>0){
                        if(isArray(el?.slot?.options)){
                            this.slotOptionsInfinityFilter(el?.slot,false);
                        }
                        //多列联动
                        el.config.mode = 'mutil-column-auto'
                        el.value = [];
                    }else{
                        el.config.mode = 'single-column'
                        el.value = '';
                    }
                } else if(el.config.tagIcon == 'date'){
                    //不存在对应的渲染格式时，默认为年月日
                    if(!el?.format) {el.format = 'yyyy-mm-dd'};
                    //当为日期组件时，判断需要日历组件还是需要完全弹窗组件
                    //只有选择年月日或月日时需要日历组件,其他的全部都是picker组件
                    //如果为请假调休套件，则默认值为当前时间
                    let dateType = ['yyyy-mm-dd','mm-dd'];
                    if(['leaveStartDate','leaveEndDate'].includes(el?.config?.field)){
                        this.$set(el.config,'expandParams',{disabled:true});
                    }
                    //使用日历组件
                    if(dateType.includes(el?.format)){
                        el.config.dateTemplateType = 'date';
                    }else{
                        el.config.dateTemplateType = 'picker';
                        //生成对应的显示规则
                        switch(el?.format){
                            case 'yyyy':
                                el.config.dateOptions = {
                                    year: true,
                                    timestamp: true
                                }
                                break;
                            case 'yyyy-mm':
                                el.config.dateOptions = {
                                    year: true,
                                    month:true,
                                    timestamp: true
                                }
                                break;
                            case 'yyyy-mm-dd hh:MM':
                            case "":
                                el.config.dateOptions = {
                                    year: true,
                                    month:true,
                                    day:true,
                                    hour:true,
                                    minute:true,
                                    timestamp: true
                                }
                                break;
                            case "yyyy-mm-dd hh":
                                el.config.dateOptions = {
                                    year: true,
                                    month:true,
                                    day:true,
                                    hour:true,
                                    timestamp: true
                                }
                                break;
                            case "mm-dd hh:MM":
                                el.config.dateOptions = {
                                    month:true,
                                    day:true,
                                    hour:true,
                                    minute:true,
                                    timestamp: true
                                }
                                break;
                            case "mm-dd hh":
                                el.config.dateOptions = {
                                    month:true,
                                    day:true,
                                    hour:true,
                                    timestamp: true
                                }
                                break;

                        }
                    }

                    //报销套件特殊处理
                    if(el.config.field == 'detailedDate' && ['reimbursementSuite','adReimbursementSuite'].includes(this.suiteName)){
                        let date = new Date();
                        el.value = formatDate(date,'yyyy-MM-dd');
                    }
             
                }

                // 添加进入弹窗组件中
                this.$set(this.popupCover, el.showId, false);
                return;
            }
            //申请人数据处理
            if (el.config.tagIcon == 'petitioner') {
                el.value = {
                    value: this.petitioner?.id,
                    label: this.petitioner?.nickname,
                    employeeId:this.petitioner?.id,
                    id:this.petitioner?.id,
                    userId:this.petitioner?.userId,
                    name:this.petitioner?.nickname
                }
                return;
            }
            //联系人数据初始化
            if (['department','contact','position'].includes(el.config.tagIcon)) {
                el.value = [];
                el.config.selectMode = el?.config?.expandParams?.selectMode || 'checkbox';
                //判断是否存在额外参数
                if(el.config?.expandParams?.isInstead===0){
                    el.config.isInstead = true;
                    //此时此申请人必定为本人
                    el.value = [{
                        value: this.petitioner?.id,
                        label: this.petitioner?.nickname,
                        employeeId:this.petitioner?.id,
                        id:this.petitioner?.id,
                        userId:this.petitioner?.userId,
                        name:this.petitioner?.nickname
                    }];
                    //等待组件渲染完成后执行
                    this.$nextTick(()=>{
                        this.suiteDataCheck(el);
                    })
                }else{
                    //判断是否需要填充默认值
                    if(el.config?.expandParams?.isSelf && el.config.tagIcon == 'contact' && !el.config?.expandParams?.isDeal){
                        if(this.isReSubmit) return;
                        //默认为本人
                        el.value = [{
                            value: this.petitioner?.id,
                            label: this.petitioner?.nickname,
                            employeeId:this.petitioner?.id,
                            id:this.petitioner?.id,
                            userId:this.petitioner?.userId,
                            name:this.petitioner?.nickname
                        }];
                        //等待组件渲染完成后执行
                        this.$nextTick(()=>{
                            this.suiteDataCheck(el);
                        })
                    }
                    el.config.isInstead = false;
                }
                return;
            }
            //上传数据初始化
            if (el.config.tagIcon == 'upload-image' || el.config.tagIcon == 'upload') {
                el.value = [];
                return;
            }
            if(el.config.tagIcon == 'input' || el.config.tagIcon == 'textarea'){
                el.config.tagIcon = 't-form-'+el.config.tagIcon;
                return;
            }




        },
        //判断是否存在明细组件（嵌套组件）
        isDetailed(suite){
            let detailedCheck= false;
            isArray(suite?.config?.groupItem) && suite.config.groupItem.forEach(el=>{
                if(isArray(el.config.groupItem) || el.config.field == "detailed"){
                    detailedCheck = true;
                }
            });
            
            return detailedCheck;
        },
        //套件初始化方法
        suiteInit(suite) {
            //保存套件名称
            this.suiteName = suite?.config?.field || '';
            //含有明细的套件特殊处理
            if(this.isDetailed(suite)){
                suite.config.groupItem.forEach(el=>{
                    //时间参数特殊处理，针对之前的改版兼容
                    if(el.config.field == "detailed" || isArray(el.config.groupItem)){
                        //套件为他添加预设好的值
                        isArray(el.config?.groupItem) && el.config.groupItem.forEach(_el => {
                            _el.value = '';
                            _el.isSuite = true;
                            _el.suiteName = el.config.field;
                            if(_el?.config.tagIcon == 'date'){
                                _el.format =  _el.format == 'yyyy-MM-dd' || _el?.format ? 'yyyy-mm-dd'  || 'yyyy-mm-dd' : _el.format;
                            }
                            this.formItemInit(_el);
                        });
                        
                        //此时即可缓存起来
                        this.suiteBlocks[el.config.field] = cloneDeep(el);
                        
                        el.config.showTips =  el?.config?.expandParams?.tips || '选择对应数据后展示'
                        //打上禁止渲染的标记
                        el.config.notRender=true;
                        //显示渲染按钮
                        el.config.blockAdd = true;
                        //是否允许新增,默认可以
                        el.config.addMode = el.config?.expandParams?.selectMode || 'checkbox'
                        this.formList.push(el);
                    }else{
                        this.formList.push(el);
                    }
                })
            }else{
                //存在数据时提取出来，如果为套件需要特殊处理，进入下一部
                if(suite.config?.groupItem && suite.config?.groupItem?.length>0 ){
                    //将套件里的数据提取出来
                    this.formList.push(...suite.config.groupItem);
                }
            }
   
        },

        /**
         * 
         * @param {String} field 需要要添加的块级名称
         * @param {number} index 当前元素的index
         * @param {number} dIndex 用于表示值的顺序
         * @param {Boolean} isCopy 是否复制上一个块级 
         */
        addBlock(field,index,dIndex,isCopy=false){
            let suiteBlock = {};
            //首先清除掉对应field的按钮位置
            this.formList.forEach(el=>{
                if(el.config.field == field){
                    el.config.blockAdd=false;
                }
            })
            //获取所有块级元素
            if(isCopy){  
                let items = this.formList.filter(el=> el.config.field == field);
                suiteBlock = cloneDeep(items[items.length-1]);
            }else{
                suiteBlock = cloneDeep(this.suiteBlocks[field]);
            }
            //套件为他添加预设好的值
            if(!isCopy){
                isArray(suiteBlock.config?.groupItem) && suiteBlock.config.groupItem.forEach(_el => {
                    _el.value = '';
                    _el.isSuite = true;
                    _el.suiteName = suiteBlock.config.field;
                    this.formItemInit(_el);
                });
            }
            if(dIndex != -1){
                suiteBlock.renderIndex = dIndex;
            }
            //打上禁止渲染的标记
            suiteBlock.config.notRender=true;
            //显示按钮
            suiteBlock.config.blockAdd = true;
            suiteBlock.config.addMode = suiteBlock.config?.expandParams?.selectMode || 'checkbox'
            this.formList.splice(index+1,0,suiteBlock);
            //报销套件才执行检查
            if(isCopy && ["reimbursementSuite","adReimbursementSuite"].includes(suiteBlock.suiteName)){
                //只需要制造一个假的用于触发统计
                this.suiteDataCheck({
                    suiteName:'',
                    config:{
                        expandParams:{
                            totalItem:true
                        }
                    }
                });
            }
        },
        /**
         * 
         * @param {String} field 需要要删除的块级名称
         * @param {number} index 当前元素的index
         */
        suiteBlockDelete(field,index,title){
            //首先检查一下当前块级套件是否为一个
            let only = this.formList.filter(el=>{
                return el.config.field == field;
            });

            if(only.length == 1){
                this.$message.error(`至少保留一个${title}`)
                return;
            }

            this.formList.splice(index,1);
            //获取最后一个元素的index
            let _index = findLastIndex(this.formList,(el)=>{
                return el.config.field == field;
            });
            this.formList.forEach((el,__index)=>{
                if(el.config.field == field){
                    if(_index == __index){
                        el.config.blockAdd = true;
                    }else{
                        el.config.blockAdd = false;
                    }
                }
            });
            //再次校验
            if(isArray(this.formList[_index]?.config?.groupItem)){
                //提取需要计算总数的组件
                let totalItem = this.formList[_index]?.config?.groupItem.find(el=>{
                    return el?.config?.expandParams?.totalItem;
                });
                if(!totalItem) return;
                this.suiteDataCheck(totalItem);
            }
            
        },

        //查看工资单明细
        checkDepPayroll(){
            //拉出套件数据
            let suite = this.formList.filter(el=>{
                return !!el?.isSuite;
            });
            if(suite.length == 0){
                this.$message.error('套件数据异常')
                return;
            }
            //生成套件对象
            let suiteObj= {};
            suite.forEach(el=>{
                suiteObj[el.config.field] = el;
            });
            if(isArray(suiteObj?.salaryDepartment?.value) && suiteObj?.salaryDepartment?.value?.length == 0){
                this.$message.error('请选择部门')
                return;
            }
            
            if(suiteObj?.salaryDate && !suiteObj?.salaryDate?.value){
                this.$message.error('请选择工资月份')
                return;
            }
            let date =timeFormat((new Date(suiteObj?.salaryDate?.value)).getTime(), 'yyyy年mm月');
            this.salaryDialogConfig = {
                title:`${date}${suiteObj?.salaryDepartment?.value[0]?.name || ''}工资单明细`,
                date:suiteObj?.salaryDate?.value,
                depId:suiteObj?.salaryDepartment?.value
            }
            this.salaryShow=true
        },

        //检查下一个元素是否为tips
        checkTips(index, item) {
            return (this.formList[index + 1] && this.formList[index + 1].config.tagIcon == 'tips') || item.config.tagIcon == 'tips';
        },

        //是否显示label
        isShowLabel(item) {
            return !!this.hideLabelTags[item.config.tagIcon];
        },

        //格式检查
        formatCheck(item) {
            let check=true;
            switch (item.config.tagIcon) {

                case 'phone':
                    if (item.value && !isPhone(item.value)) {
                        this.$message.error(item.config.label + '，格式错误')
                        check=false;
                    }
                    break;

                case 'idCard':
                    if (item.value && !test.idCard(item.value)) {
                        this.$message.error(item.config.label + '，格式错误')
                        check=false;
                    }
                case 'money':
                    if (item.value && !isMoney(item.value)) {
                        this.$message.error(item.config.label + '，格式错误')
                        check=false;
                    }
                    break;

            };

            return check;

        },

        //套件数据检查
        suiteDataCheck(item,callback) {
            //请假、休假套件执行
            if (item.suiteName == "leaveSuite") {
                //类型切换
                if(item.config.field == "leaveType"){
                    //根据当前选择的类型决定时间选择的方法
                    let option = isArray(item.slot?.options) ? item.slot.options.find(el=>{
                        return el.value == item.value;
                    }) : null;
                    //选项存在
                    if(option){
                        //提取出时间选择类型,不存在则为按小时请假
                        let timeUnit = option?.timeUnit || 3; 
                        //修改对应的数据
                        this.formList.forEach(el=>{
                            if(el.config.field == 'leaveStartDate' || el.config.field== 'leaveEndDate'){
                                this.$set(el.config,'expandParams',{disabled:false});
                                if(timeUnit == 3){
                                    el.format = 'yyyy-mm-dd hh:MM';
                                    let date = new Date();
                                    //数据为当天，规则不同不能直接使用
                                    if(!this.reSubmitLock){
                                        if(el.config.field == 'leaveEndDate'){
                                            el.value = formatDate(date,'yyyy-MM-dd hh:30');
                                        }else{
                                            el.value = formatDate(date,'yyyy-MM-dd hh:00');
                                        }
                                    }
                                }else{
                                    el.format = 'yyyy-mm-dd';
                                    if(!this.reSubmitLock){
                                        el.value = formatDate(new Date,'yyyy-MM-dd');
                                    }
                                }
                                el.timeUnit = timeUnit;
                            }else if(el.config.field == 'attendanceLeaveType'){
                                el.value = option?.type || '';
                            }else if(el.config.field == 'attendanceTimeUnit'){
                                el.value = timeUnit;
                            }
                        })
                    }
                }
                let suiteData = {};
                //提取所有的套件数据
                let suiteItem = this.formList.filter(el => {
                    return el.suiteName == 'leaveSuite';
                });
                //将数组转化为对象
                suiteItem.forEach(el => {
                    this.$set(suiteData, el.config.field, {value:el.value,timeUnit:el?.timeUnit || 3});
                });
                //开始判断，只有所有数据都满足的时候才修改
                if (suiteData.leaveStartDate.value && suiteData.leaveEndDate.value) {
                    let duration;
                    let startDate = (new Date(`${suiteData.leaveStartDate.value}${suiteData.leaveStartDate?.timeUnit == 1 ? ' 00:00:00' : ''}`)).getTime();
                    let endDate = (new Date(`${suiteData.leaveEndDate.value}${suiteData.leaveEndDate?.timeUnit == 1 ? ' 23:59:59' : ''}`)).getTime();
                    if (startDate >= endDate) {
                        this.$message.error('开始时间不能大于或等于结束时间')
                        item.value = '';
                        return;
                    }
                    this.formList.forEach(el => {
                        if (el.config.field == 'duration') {
                            duration = el;
                        }
                    })
                    let _startDate = suiteData.leaveStartDate.value.split(' ');
                    let _endDate = suiteData.leaveEndDate.value.split(' ');
                    attendanceDuration({
                        startDate:`${_startDate.length> 1 && suiteData.leaveStartDate?.timeUnit == 1 ? _startDate[0] : suiteData.leaveStartDate.value}${suiteData.leaveStartDate?.timeUnit == 1 ? ' 00:00:00' : ''}`,
                        endDate: `${_endDate.length> 1 && suiteData.leaveStartDate?.timeUnit == 1 ? _endDate[0] : suiteData.leaveEndDate.value}${suiteData.leaveEndDate?.timeUnit == 1 ? ' 23:59:59' : ''}`
                    })
                    .then(res => {
                        if (duration) {
                            //此处进行判断
                            duration.value = res.data.duration;
                            this.$set(duration,'realityValue',`${res.data.durationDays > 1 ? res.data.durationDays + '天，' : ''}共${res.data.duration}小时`)
                        }
                    })
                    .catch(err => {
                        console.log(err);
                        if (duration) {
                            duration.value = ''
                        }
                    })
                    .finally(()=>{
                        //关闭加载锁
                        this.reSubmitLockChange(false);
                    })
                }

            } else if (item.suiteName == 'WorkAttendanceCardSuite') {
                //补卡套件
                if (item.config.field == 'workAttendanceCardDate') {
                    //获取补卡班次
                    clockClassPlan({
                        date: item.value
                    })
                        .then((res) => {
                            //找到补卡班次选项
                            this.formList.forEach(el => {

                                if (el.config.field == 'workAttendanceCardTime') {
                                    el.slot.options = res.data.map(_el => {
                                        return {
                                            label: _el,
                                            value: _el
                                        }
                                    });
                                    if(!this.reSubmitLock){  
                                        //剔除掉当前选项
                                        el.value = '';
                                    }
                                }

                                if (el.config.field == 'workAttendanceCardShift' && !this.reSubmitLock) {
                                    el.value = '';
                                }

                            })

                        })
                        .catch((err) => {
                            console.log(err);
                        })
                        .finally(()=>{
                            //关闭加载锁
                            this.reSubmitLockChange(false);
                        })
                } else if (item.config.field == 'workAttendanceCardTime') {
                    let obj = {
                        date: '',
                        time: ''
                    }
                    //选择对应数据时
                    this.formList.forEach(el => {

                        if (el.config.field == 'workAttendanceCardDate') {
                            obj.date = el.value;
                        }

                        if (el.config.field == 'workAttendanceCardTime') {
                            obj.time = el.value;
                        }

                        if (el.config.field == 'workAttendanceCardShift') {
                            let invId = setTimeout(() => {
                                el.value = `${obj.date} ${obj.time}`;
                                clearTimeout(invId)
                            }, 0)
                        }
                    });

                }
            } else if(['rewardSuite','salaryAdjustSuite','personnelInformationSuite','loanSuite','payBackSuite'].includes(this.suiteName)){
                //奖励套件特殊处理,选择人员时自动选择支出部门以及银行以及卡号
                if(['applyEmployee','detailedContact','informationEmployee','loanName'].includes(item.config.field)){
                    //为借（还）款方却并非员工时，不继续执行
                    if(item.config.field == 'loanName' && item.config.tagIcon != 'contact') return;
                    if(isArray(item.value) && item.value.length>0){
                        let employeeId = item.value[0]?.employeeId;
                        detail({employee_id:employeeId})
                        .then(res=>{
                            //流程部门
                            //支出部门
                            let department = res.data?.costDepartment ? [{
                                label:res.data?.costDepartment?.name,
                                id:res.data?.costDepartment?.id,
                                value:res.data?.costDepartment?.id,
                                name:res.data?.costDepartment?.name
                            }] : [];
                            //开户行名称
                            let bankName = res.data?.bankName || '';
                            //卡号
                            let bankNumber = res.data?.bankNumber || '';
                            //对公账户
                            let companyOwner = res.data?.companyOwner || '';
                            let companyOwnerName = res.data?.financeBankAccountOne?.label || '暂无';
                            //未还余额
                            let balance  = res.data?.balance || 0;
                            //未结佣金
                            let commissionOpen = res.data?.commissionOpen || 0;
                            //实际未还余额
                            let realityBalance  = res.data?.realityBalance || 0;
                            //被冻结的金额
                            let freezeBalance = res.data?.freezeBalance || 0;
                            this.formList.forEach(el=>{
                                if(el.config.field == "detailedDepartment" || el.config.field == 'processDepartment' || el.config.field == 'costDepartment'){
                                    el.value = department;
                                }else if(el.config.field == "bankName" || el.config.field == 'openBank'){
                                    el.value = bankName;
                                }else if(el.config.field == "bankNumber" || el.config.field == 'loanAccount'){
                                    el.value = bankNumber;
                                }else if(el.config.field == "loanBalance"){
                                    el.value = balance;
                                }else if(el.config.field == "loanCommission"){
                                    el.value = commissionOpen;
                                }else if(el.config.field == "bankAccount"){
                                    el.value = companyOwner;
                                    //此时进行判断是否存在对应的选项，没有就给他新增一个选项
                                    if(isArray(el?.slot?.options)){
                                        let option = el.slot.options.find(el=>el.value == companyOwner);
                                        if(!option){
                                            el.slot.options.push({
                                                label:companyOwnerName,
                                                value:companyOwner
                                            })
                                        }
                                    }
                                }else if(el.config.field == 'detailedDate'){
                                    if(el.value) return;
                                    let date = new Date();
                                    el.value = formatDate(date,'yyyy-MM-dd');
                                }else if(el.config.field == 'totalMoney'){
                                    //针对还款套件进行数据填充，还款套件并无未还余额和未结佣金、冻结金额等选项
                                    if(!isObject(el.config.expandParams)){
                                        el.config.expandParams = {};
                                    }
                                    //统一写入金额的额外数据
                                    el.config.expandParams.balance= balance;
                                    el.config.expandParams.commissionOpen= commissionOpen;
                                    el.config.expandParams.realityBalance = realityBalance;
                                    el.config.expandParams.freezeBalance = freezeBalance;
                                }
                            })
                        })
                    }
                }
            } else if(['shiftJobsSuite','salaryIncreaseSuite','promotionSuite','officialStaffSuite'].includes(item.suiteName)){
                //当选择申请人的时候触发
                if(item.config.field == 'applyEmployee'){
                    //生成参数列表
                    let options={
                        userId : item?.value[0]?.userId,
                        employeeId:item?.value[0]?.id
                    };
                    if(['salaryIncreaseSuite','promotionSuite','officialStaffSuite'].includes(item.suiteName)){
                        let suiteItem = this.formList.find(el=>{
                            return el.config.field == 'salaryArchivesKit';
                        });
                        options['salaryArchivesKit'] = suiteItem?.id
                         //拉取薪资档案
                         getSalaryEmployeeArchives(options)
                         .then(res=>{
                             if(!res?.data) return;
                             //生成新的套件数据
                             let suiteGroupItem = res.data['control'];
                             //提取对应的套件数据
                             let index = findIndex(this.formList,el=>{
                                 return el.config.field == 'salaryArchivesKit';
                             });
                             if(index !=-1){
                                 //套件为他添加预设好的值
                                 isArray(suiteGroupItem) && suiteGroupItem.forEach(_el => {
                                 
                                         _el.value = _el.value || _el.value === 0 ?_el.value : '';
                                         _el.isSuite = true;
                                         _el.suiteName = this.formList[index].config.field;
                                         this.formItemInit(_el);
                                 });
                                 this.formList[index].config.groupItem = suiteGroupItem;
                             }

                             if(['salaryIncreaseSuite','promotionSuite','officialStaffSuite'].includes(item.suiteName)){
                                //生成新的套件数据
                                let _suiteGroupItem = res.data['detailedControl'];
                                //提取对应的套件数据
                                let _index = findIndex(this.formList,el=>{
                                    return el.config.field == 'detailed';
                                });
                                if(_index !=-1){
                                    //套件为他添加预设好的值
                                    isArray(_suiteGroupItem) && _suiteGroupItem.forEach(_el => {
                                            _el.value = _el.value || _el.value === 0 ?_el.value : '';
                                            _el.isSuite = true;
                                            _el.suiteName = this.formList[_index].config.field;
                                            _el.config.expandParams.disabled = true;
                                            this.formItemInit(_el,true);
                                    });
                                    this.formList[_index].config.groupItem = _suiteGroupItem;
                                }
                             }
                             
                        })
                        .finally(()=>{
                            //关闭加载锁
                            this.reSubmitLockChange(false);
                            callback && callback();
                        })
                    }
                    if(['shiftJobsSuite'].includes(item.suiteName)){
                        let suiteItem = this.formList.find(el=>{
                            return el.config.field == 'detailed';
                        });
                        options['salaryArchivesKit'] = suiteItem?.id
                        //拉取调岗信息
                        getShiftJobsControl(options)
                        .then(res=>{
                            if(!res?.data) return;
                            //生成新的套件数据
                            let suiteGroupItem = res.data['control'];
                            //提取对应的套件数据
                            let index = findIndex(this.formList,el=>{
                                return el.config.field == 'detailed';
                            });
                            if(index !=-1){
                                //套件为他添加预设好的值
                                isArray(suiteGroupItem) && suiteGroupItem.forEach(_el => {
                                        _el.value = _el.value || _el.value === 0 ?_el.value : '';
                                        _el.isSuite = true;
                                        _el.suiteName = this.formList[index].config.field;
                                        _el.config.expandParams.disabled = true;
                                        this.formItemInit(_el,true);
                                });
                                this.formList[index].config.groupItem = suiteGroupItem;
                            }
                        })
                        .finally(()=>{
                            //关闭加载锁
                            this.reSubmitLockChange(false);
                            callback && callback();
                        })
                    }
                    
                }
            } else if(item.suiteName == 'salarySuite'){
                //工资单套件,计算总金额
                //拉出套件数据
                let suite = this.formList.filter(el=>{
                    return !!el?.isSuite;
                });
                if(suite.length == 0){
                    return;
                }
                //生成套件对象
                let suiteObj= {};
                suite.forEach(el=>{
                    suiteObj[el.config.field] = el;
                });
                if(isArray(suiteObj?.salaryDepartment?.value) && suiteObj?.salaryDepartment?.value?.length == 0){
                    return;
                }
                if(suiteObj?.salaryDate && !suiteObj?.salaryDate?.value){
                    return;
                }
                //获取设置元素下标
                let totalMoneyIndex = findIndex(this.formList,(el=>{
                    return el.config.field == 'totalMoney';
                }));
                SalaryList({
                    date:suiteObj?.salaryDate?.value,
                    page:1,
                    pageSize:20,
                    department: isString(suiteObj?.salaryDepartment?.value) ? suiteObj?.salaryDepartment?.value : suiteObj?.salaryDepartment?.value[0]?.id || ''
                  }).then((res) => {
                    if(totalMoneyIndex != -1){
                        this.formList[totalMoneyIndex].value = res.data.taxPay;
                    }
                  })
                  .finally(()=>{
                      //关闭加载锁
                      this.reSubmitLockChange(false);
                  })
                  
            } else if(item.suiteName == 'leaveOfficeSuite'){
                //离职套件，此时判断是否选择了离职人员
                if(item.config.field == 'applyEmployee'){
                    //获取离职日期的值
                    let leaveOfficeDate = this.getFormItem({field:'leaveOfficeDate'});
                    //此时进行数据拉取
                    if(isArray(item.value) && item.value.length>0){
                        let employeeId = item.value[0]?.employeeId;
                        detail({employee_id:employeeId})
                        .then(res=>{
                            //存在入职时间
                            if(res.data?.entryDate){
                                let entryDate = new Date(res.data.entryDate);
                                //选择了离职时间
                                if(leaveOfficeDate?.value){
                                    let _leaveOfficeDate = new Date(leaveOfficeDate.value);
                                    //选择离职时间小于入职时间，不合法，清除掉当前选择的离职时间
                                    if(_leaveOfficeDate.getTime() < entryDate.getTime()){
                                        leaveOfficeDate.value = '';
                                        this.$message.warning('离职时间不能小于入职时间，请重新选择')
                                    }
                                }

                                //设置一下对应的数据
                                leaveOfficeDate.limitDate = res.data.entryDate;
                            }else{
                                //清除掉限制
                                leaveOfficeDate.limitDate = '';
                            }
                        })
                    }
                }
            }
            //统计计算
            if(item?.config?.expandParams?.totalItem){
                //是否为总计项
                //找到所有的总计项
                let totalItem = [];
                this.formList.forEach(el => {
                    //明细
                    if(el?.config?.field == 'detailed'){
                        if(isArray(el?.config?.groupItem)){
                            let detailedItem = el?.config?.groupItem.filter(_el=>{
                                return _el?.config?.expandParams?.totalItem;
                            });
                            totalItem.push(...detailedItem);
                        }
                    }
                    //明细以外的统计项
                    if(el?.config?.expandParams?.totalItem){
                        totalItem.push(el);
                    }
                });

                if(totalItem.length>0){
                    let total = sumBy(totalItem,(el)=>{
                        return parseFloat(el.value) || 0;
                    });

                    this.formList.forEach(el=>{
                        if(el.config?.field == 'totalMoney'){
                            el.value = total.toFixed(2);
                        }
                    })
                }
            }
            
        },
        //填充锁，为了防止某些数据请求后重置
        reSubmitLockChange(lock){
            this.reSubmitLock = lock;
        },
        resubmitLock(){
            this.isReSubmit = true;
        },
        //填充value赋值
        applyValueSet(_el,_item,isReturn){
            if(!_item?.controlValue) return;
            if(_el.config.field != _item?.field) return;
            if(['department','contact','position'].includes(_el.config.tagIcon)){
                if(_el.config.tagIcon == 'contact'){
                    _el.value =  _item.controlValue.map(__el=>{
                       return {
                        label:__el?.label,
                        value:__el?.value,
                        id:__el?.value,
                        employeeId:__el?.value,
                        name:__el?.label,
                        userId:__el.value
                    }
                    });
                }else{
                    _el.value =  _item.controlValue.map(__el=>{
                        return {
                            label:__el.label,
                            id:__el.value,
                            value:__el.value,
                            name:__el.label
                        }
                    });
                }
            }else{
                if(_el?.config?.tagIcon == 'upload' || _el?.config?.tagIcon == 'upload-image'){
                    if(_el?.config?.tagIcon == 'upload'){
                        _el.value =  _item.controlValue.map(item=>{
                            return {
                                label:item?.label,
                                value:item?.value,
                                url:item?.value,
                                name:item?.label
                            }
                        })
                    }else{
                        _el.value =  _item.controlValue.map(item=>{
                            return item.value
                        })
                    }
                }else if( _el?.config?.tagIcon == 'radio'){
                    let option =readNodes(_el.slot.options,(_option)=>{
                        return _option.value == _item.controlValue[0]?.value;
                    },[]);
                    if(option.length == 0){
                        _el.value =  _item.controlValue[0]?.value;
                        return _el;
                    }
                    if(_el?.config?.mode != 'single-column'){
                        let itemOption = option[0];
                        //递归找出对应的值,需要多选的场合此处一定要改
                        _el.config.caLabel = _item?.value || itemOption?.label;
                        _el.value = itemOption.value;
                    }else{
                        _el.value = option[0].value;
                    }
                }else if(_el?.config?.tagIcon == 't-form-checkbox'){
                    //提取选项
                    let options = _el?.slot?.options;
                    if(!options) return _el;
                    let selectOption = options.filter(el=>{
                        return _item.controlValue.find(_el=>{
                            return _el.value == el.value; 
                        })
                    })
                    _el.value = selectOption.map(el=>{
                        return el.value;
                    });
                } else if(_el?.config?.tagIcon == 'petitioner'){
                    _el.value = {
                        value: this.petitioner?.id,
                        label: this.petitioner?.nickname,
                        employeeId:this.petitioner?.id,
                        id:this.petitioner?.id,
                        userId:this.petitioner?.userId
                    }
                }else if(_el.config.tagIcon == 'village'){
                    _item.expandParams = _item.expandParams ? JSON.parse(_item.expandParams) : {};
                    _el.value = _item.value;
                    _el.config.expandParams.villagePath = _item.expandParams?.villagePath || [];
                }else if(_el?.config.tagIcon == 'date-range'){
                    let dateItem = _item.controlValue[0]?.value.replace(/ /g,'');
                    dateItem = dateItem.split('至');
                    _el.value = dateItem;
                }else{
                     _el.value =  _item.controlValue[0]?.value;
                }
            }

            if(isReturn){
                return _el;
            }
        },
        //模板校验
        applyFormTemplateCheck(formList){
            let oldFieldList = [];
            let fieldList = [];
            //提取旧数据
            formList.forEach(el => {
                if(el.field == 'detailed'){
                    oldFieldList.push({
                        field:el.field,
                        isDetailed:true,
                        list:el.children.map(el=>{return el.field}) 
                    }) 
                }else{
                    oldFieldList.push({
                        field:el.field
                    })
                }
            });
            //提取新数据
            this.formList.forEach(el => {
                if(el.config.field == 'detailed'){
                    fieldList.push({
                        field:el.config.field,
                        isDetailed:true,
                        list:isArray(el.config?.groupItem) ? el.config?.groupItem?.map(el=>{return el.config.field}) :[]
                    }) 
                }else{
                    fieldList.push({
                        field:el.config.field
                    })
                }
            });
            let isReloadSuite = this.reloadSuite.includes(this.suiteName);
            //不一致立马报错清空
            if(!isEqual(oldFieldList,fieldList)){ 
                this.$alert(`原审批单填写内容已发生变化，请重新填写，${isReloadSuite ? '已为您填写必要数据。' : ''}`, "审批单变动",{type:'warning'});
                //锁住
                this.applyFormResetLock=true;
                this.getFormData();
            }
        },
        //回流数据填充
        reloadFormDataStart(){
            if(isObjectNone(this.reloadFormData)) return;

               
            this.formList.forEach(el=>{
                if(this.reloadFormData[el.config.field]){
                    el.value = this.reloadFormData[el.config.field];
                }
            })
        },
        //数据填充
        applyFormDataInit(data){
            this.approveId = data.id;
            let suiteItem = null;
            let formList = [];
            //是否存在明细
            let detailed = [];
            //数据处理
            data.control.forEach(el=>{
                //套件
                if(!!el?.isGroup){
                    detailed = el.children.filter(_el=>{
                        return _el.field == 'detailed';
                    }) 
                    formList.push(...el.children.map(_el=>{
                        //标记套件元素
                        _el.isSuite = true;
                        return _el;
                    }));
                }else{
                    formList.push(el);
                }
            });
            
            //是否为需要提取元素
            if(this.reloadSuite.includes(this.suiteName)){
                formList.forEach(el=>{
                    //只提取不包含下级的元素
                    if(el.isSuite && !el?.children){
                        //提取对应的元素
                        let formItem = this.formList.find(item=>{
                            return item.config.field == el.field;
                        });
                        if(formItem){
                            let _item = this.applyValueSet(formItem,el,true);
                            this.reloadFormData[el.field] =_item?.value;
                        }
                    }
                    
                    //针对特殊的套件进行保存
                    if(['rewardSuite'].includes(this.suiteName) && ['cashBackId','transaction'].includes(el.field)){
                        let formItem = this.formList.find(item=>{
                            return item.config.field == el.field;
                        });
                        if(formItem){
                            let _item = this.applyValueSet(formItem,el,true);
                            this.reloadFormData[el.field] =_item?.value;
                        }
                    }
                })
            }
            //特殊变化套件
            if(this.suiteName == 'dealExpendSuite'){
                let item = formList.find(el=>el.field == 'receiptCustomType');
                let borrowBalance = formList.find(el=>el.field == 'balance')
                let isHide = ![3,6].includes(item.value) || borrowBalance.value == 0;
                //找出开始时间
                this.formList.forEach((el,index)=>{
                    let field = el.config.field;
                    if(['realityMoney','money','balance'].includes(field)){
                        this.$set(el.config,'isHide',isHide);
                        //填充用于计算的数据
                        if(!isHide && field == 'realityMoney'){      
                            let realityMoney = formList.find(el=>el.field == 'realityMoney');
                            if(!isObject(el?.config?.expandParams)){
                                el.config.expandParams = {};
                            }

                            el.config.expandParams = Object.assign(el.config.expandParams,{...realityMoney.expandParams});
                        }
                    }
                    if(field == 'payee'){
                        //区分类型为，分销商及其他，分销商需要具备搜索功能
                        el.config.expandParams.inputSelect = item?.value == 3 || item?.value == 6;
                        el.config.expandParams.autocomplete = item?.value == 3 || item?.value == 6;
                      }
                      //开户行
                      if(field == 'openBank'){
                        //区分类型为，分销商及其他，分销商需要具备搜索功能
                        el.config.expandParams.autocomplete = item?.value == 3 || item?.value == 6;
                      }
                      //卡号
                      if(field == 'collectAccount'){
                        //区分类型为，分销商及其他，分销商需要具备搜索功能
                        el.config.expandParams.autocomplete = item?.value == 3 || item?.value == 6;
                      }
                      
                });
            }
            //针对请假套件特殊处理
            if(this.suiteName == 'leaveSuite'){
                //找出开始时间
                this.formList.forEach((el,index)=>{
                    let item = formList[index];
                    let startTime = formList.find(_el=>{
                        return _el.field == 'leaveStartTime'
                    })  
                    let endTime = formList.find(_el=>{
                        return _el.field == 'leaveEndTime'
                    })
                    if(el?.config?.field == item?.field && item?.field){
                        if(isArray(item.controlValue) && item.controlValue?.length>0){
                            if(el?.config?.field  == "leaveStartDate"){
                                el.value =  item.controlValue[0]?.value + ` ${startTime.value}`;
                            }else if(el?.config?.field  == "leaveEndDate"){
                                el.value =  item.controlValue[0]?.value + ` ${endTime.value}`;
                            }else{
                                this.applyValueSet(el,item);
                            }
                        }
                    }
                });
                //用类型数据激活请求
                suiteItem = this.formList.find(el=>{
                    return el.config.field == 'leaveType';
                }); 
                //锁住
                this.reSubmitLockChange(true);
                if(suiteItem){
                    this.suiteDataCheck(suiteItem);
                }
                //校验检查
                this.applyFormTemplateCheck(formList);
            }else if(['shiftJobsSuite','salaryIncreaseSuite','promotionSuite','officialStaffSuite'].includes(this.suiteName)){
                //存在薪资档案或调岗信息的表单
                //提取出动态拉取的表单
                let groupItem = null;
                this.formList.forEach((el,index)=>{
                    let item = formList[index];
                    //提取出表单数据
                    if(el.config.groupItem){
                        groupItem = item;
                    }else if(el?.config?.field == item?.field){
                        if(isArray(item.controlValue) && item.controlValue?.length>0){
                            this.applyValueSet(el,item);
                        }
                    }
                });
                suiteItem = this.formList.find(el=>{
                    return el.config.field == 'applyEmployee';
                });
                //锁住
                this.reSubmitLockChange(true);
                if(suiteItem){
                    //请求完毕以后在执行
                    this.suiteDataCheck(suiteItem,()=>{
                        //调岗信息不需要填充数据
                        if(this.suiteName == 'shiftJobsSuite'){
                            //校验检查
                            this.applyFormTemplateCheck(formList);
                            return;
                        }
                        this.$nextTick(()=>{
                            //定时器保证刷新
                            let inv_id = setTimeout(()=>{
                                clearTimeout(inv_id);
                                this.formList.forEach(el=>{
                                    if(isArray(el.config.groupItem) && groupItem){
                                        el.config.groupItem.forEach((_el,_index)=>{
                                            let item = groupItem.children.find(_item=>{
                                                return _item?.field == _el.config.field;
                                            });
                                            if(item){
                                                this.applyValueSet(_el,item);
                                            }
                                        })
                                    }
                                });
                                //校验检查
                                this.applyFormTemplateCheck(formList);
                            },500)
                        })
                    });
                }else{
                    //校验检查
                    this.applyFormTemplateCheck(formList);
                }
            }else if (detailed.length>0){
                //存在明细的表单
                //获取第一个明细的index
                let index = findIndex(this.formList,el=>{
                    return el.config.field == 'detailed';
                });
                //首先生成对应顺序的detailed
                for(let i=1;i<detailed.length;i++){
                   this.addBlock('detailed',index+(i-1),i);
                };
                //填充一下detailed的数据
                this.formList.forEach((el,index)=>{
                    if(el.config.field == 'detailed'){
                        let item = detailed[el.renderIndex ? el.renderIndex : 0];
                        item = item?.children || [];
                        isArray(el?.config.groupItem) && el.config.groupItem.forEach((_el,_index)=>{
                            let _item = item[_index];
                            if(_item){
                                this.applyValueSet(_el,_item);
                            }
                        })
                    }else{
                        let item = formList[index];
                        this.applyValueSet(el,item);
                    }
                });
                //校验检查
                this.applyFormTemplateCheck(formList);
            }else{
                //遍历复制
                this.formList.forEach((el,index)=>{
                    let item = formList[index];
                    if(el?.config?.field == item?.field && item?.field){
                        if(isArray(item.controlValue) && item.controlValue?.length>0){
                            this.applyValueSet(el,item);
                        }
                    }
                });
                //找出套件数据
                suiteItem = this.formList.find(el=>{
                    return !!el.isSuite
                });
                //锁住
                this.reSubmitLockChange(true);
                if(suiteItem){
                    this.suiteDataCheck(suiteItem);
                }
                //校验检查
                this.applyFormTemplateCheck(formList);
            }

            //真对特殊套件处理
        },
        //流程返回
        applyWorkFlowInit(flow){
            if(!isArray(flow)) return;
            let flowList = cloneDeep(flow);
            flowList = flowList.map(el=>{
                el = el.workflowNodesUser.map(_el=>{
                    _el.employee = _el.employee || {};
                    return {
                        ..._el?.employee,
                        name:_el?.employee?.nickname
                    }
                });
                return el;
            });
            this.workFlowData.forEach((el,index)=>{
                let item = flowList[index];
                if(item){
                    el.nodeUserList = item;
                }
            })
        },

        //清空时所执行的套件数据检查
        clearSuiteDataCheck(item) {
            //检查补卡套件
            if (item.suiteName == 'WorkAttendanceCardSuite') {
                //清空补卡日期，清除掉补卡时间以及班次
                if (item.config.field == 'workAttendanceCardDate') {

                    this.formList.forEach(el => {

                        if (el.config.field == 'workAttendanceCardTime') {
                            el.slot.options = []
                            //剔除掉当前选项
                            el.value = '';
                        }

                        if (el.config.field == 'workAttendanceCardShift') {
                            el.value = '';
                        }

                    })
                }
                //清空补卡时间，清除掉补卡班次
                if (item.config.field == 'workAttendanceCardTime') {
                    this.formList.forEach(el => {

                        if (el.config.field == 'workAttendanceCardShift') {
                            el.value = '';
                        }

                    })
                }
            
            //检查请假调休套件
            }else if(item.suiteName == 'leaveSuite'){
                //判断是否为这几个数据清空
                if(['leaveStartDate','leaveStartTime','leaveEndDate','leaveEndTime'].includes(item.config.field)){
                    this.formList.forEach(el=>{
                        if(el.config.field == 'duration'){
                            el.value = ''
                        }
                    })
                }
            }else if(item?.config?.expandParams?.await && item?.config?.expandParams?.requestUrl){
                if(!item.value || (isArray(item.value) && item.value.length == 0)){
                    //删除显示
                    if(item.config?.expandParams?.infoShowKey){
                        //判断是否已经存在
                        let infoIndex = findIndex(this.formList,el=>{
                            return el.config.field == item.config.field+'ShowInfo'
                        });
                        if(infoIndex != -1){
                            this.formList.splice(infoIndex,1);
                        }
                    }
                    //删除额外表单
                    if(item.config?.expandParams?.awaitItemKey && item.config?.expandParams?.targetControlField){
                        let index = findIndex(this.formList,el=>{
                            return el.config.field == item.config?.expandParams?.targetControlField;
                        });

                        if(index != -1){
                            this.formList[index].config.groupItem = [];
                        }
                    }

                }
            }

        },
        
        //数据变化时获取
        formDataChange(data) {
            //生成需要提交的数据 套件里的元素，且为条件时
            let formData = data.filter(el => {
                return el.isSuite && !!el?.config?.isProcess;
            });
            let formDataKey = {};
            //生成提交用键值对
            formData.forEach(el => {
                let val = isObject(el.value) ? isObjectNone(el.value) ? '' : el.value.value : el.value;
                if(['inductionDepartment','salaryDepartment','dealExpendDepartment'].includes(el.config.field)){
                    if(isArray(val) && val.length>0){
                        let item =cloneDeep(val[0]);
                        item.value = item?.id;
                        formDataKey[el.config.field]  = [item];
                    }else{
                        formDataKey[el.config.field] = {}
                    }
                }else{
                    formDataKey[el.config.field] = val;
                }
            });
            //条件数据未发生改变，不执行
            if(isEqual(formDataKey,this.conditionOption)) return;
            this.flowLoading = true;
            this.conditionOption = formDataKey;
            getApproveWorkflow({
                templateId: this.templateId,
                condition: formDataKey
            }).then(res => {
                this.workFlowData = res.data.list
                this.msgTxt = res.data.msgTxt
                this.nodeId = res.data.nodeId
                this.isBreak = res.data.isBreak;
                if(this.isBreak == 0){
                    this.$emit('workFlowReady')
                }
            })
            .catch(err=>{
                console.log(err)
            })
            .finally(()=>{
                this.flowLoading = false;
            })
        },

        //审批单数据缓存
        applyCacheCheck(){
            //重新提交不填入
            if(this.isReSubmit) return;
            if(!this.$store.state?.approve?.applyFormList || this.$store.state?.approve?.applyFormId != this.templateId) return;
            this.$confirm('有未提交的报销单缓存，是否加载？', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            })
            .then(res=>{
                this.formList = cloneDeep(this.$store.state?.approve?.applyFormList);
                this.formData = cloneDeep(this.$store.state?.approve?.applyFormData);
                //清空缓存
                this.$nextTick(()=>{
                    this.$store.commit('approveSetData',{
                        'applyFormList':null,
                        'applyFormData':null,
                        'applyFormId':null
                    });
                })
            })
            .catch(()=>{
                this.$store.commit('approveSetData',{
                    'applyFormList':null,
                    'applyFormData':null,
                    'applyFormId':null
                });
            })
        },

        /**
         * 执行数据提交之前检查
         * 主要用于数据更新，由于suiteDataCheck 太过臃肿，故重新新增提取方法
         * 不管内部处理失败还是成功，都会返回成功
         * @return {promise} 返回一个promise对象，用于异步请求
         */

        submitBefore(){
            return new Promise((res,rej)=>{
                //拉取最新工资
                if(this.suiteName == 'salarySuite'){
                    this.updateSalaryData().finally(()=>{
                        res();
                    })
                }else{
                    res();
                }
            })
        },

        //拉取最新工资
        updateSalaryData(){
            //拉出套件数据
            return new Promise((res,rej)=>{
                let suite = this.formList.filter(el=>{
                    return !!el?.isSuite;
                });
                if(suite.length == 0){
                    rej();
                    return;
                }
                //生成套件对象
                let suiteObj= {};
                suite.forEach(el=>{
                    suiteObj[el.config.field] = el;
                });
                if(isArray(suiteObj?.salaryDepartment?.value) && suiteObj?.salaryDepartment?.value?.length == 0){
                    rej();
                    return;
                }
                if(suiteObj?.salaryDate && !suiteObj?.salaryDate?.value){
                    rej();
                    return;
                }
                //获取设置元素下标
                let totalMoneyIndex = findIndex(this.formList,(el=>{
                    return el.config.field == 'totalMoney';
                }));
                SalaryList({
                    date:suiteObj?.salaryDate?.value,
                    page:1,
                    pageSize:20,
                    department: isString(suiteObj?.salaryDepartment?.value) ? suiteObj?.salaryDepartment?.value : suiteObj?.salaryDepartment?.value[0]?.id || ''
                }).then((res) => {
                    if(totalMoneyIndex != -1){
                        this.formList[totalMoneyIndex].value = res.data.taxPay;
                    }
                })
                .finally(()=>{
                    res();
                })
            })
                              
        },

        //设置数据
        setFormData(data){
            if(isObjectNone(data)) return;
            let keys = Object.keys(data);
            this.formList.forEach(el=>{
                if(keys.includes(el.config.field)){
                    el.value = data[el.config.field];
                }
            });
        },
        /**
         * 获取列表中的某个项
         * @param {} field field
         */
        getFormItem({field,callback = null}){
            let item = this.formList.find(el=>el?.config?.field == field);
            if(callback){
                callback(item);
            }else{
                return item;
            }
        }
    }

}