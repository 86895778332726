<template>
<div class="department">
    <my-tag-input
    :readonly="true" 
    :placeholder="placeholder"
    class="fxy-select scale-select"
    :value="tags"
    size="small"
    @change="suiteDataCheck"
    :key="guid"
    @focus="handleSelectEmp('人员筛选', selectType , 'id')"
    :props="{icon:'el-icon-arrow-down'}">
    </my-tag-input>
    <!-- 人员选择 -->
    <dep-role-modal
        :config="modalConfig"
        v-model="dialogVisible"
        @confirm="handleConfirm"
    ></dep-role-modal>
    <employee-modal
        :config="modalConfig"
        v-model="empDialogVisible"
        @confirm="handleConfirm"
    ></employee-modal>
</div>
</template>
<script>
import depRoleModal from '@/components/dialog/DepRoleModal';
import employeeModal from "@/components/dialog/employeeModal";
import {isArray,guid} from "@/utils"
export default {
    inject: ['root'],
    props:{
        value:{
            default:''
        },
        formData:{
            type:Object,
            default:()=>({}) 
        }
    },
    components: {
        depRoleModal:depRoleModal,
        employeeModal
    },
    data(){
        return{
            modalConfig:{
                type: 1,
                selectType: "radio",
                title: `选择人员`,
                checkStrictly: false,
                model: ""
              },
            dialogVisible:false,
            empDialogVisible:false
        }
    },
    computed:{
        tags(){
            return isArray(this.value) ? this.value.map(el=>{
                return el?.name || el?.label
            }) : []
        },
        checkList(){
            return this.value.length> this.value
        },
        selectType(){
            return this.formData?.config?.selectMode || 'radio'
        },
        placeholder(){
            return this.formData?.placeholder || '请选择人员'
        },
        label(){
            return this.formData?.config?.label || '人员'
        },
        guid(){
            return guid(32);
        },
        disabled(){
            return !!this.formData?.config?.expandParams?.disabled;;
        },
        quitContact(){
            return (this.formData?.suiteName == 'rewardSuite' && this.formData?.config?.field == 'detailedContact') || (this.formData?.suiteName == 'salaryAdjustSuite' && this.formData?.config?.field == 'applyEmployee')
        }
    },
    methods:{
        suiteDataCheck(){
            this.root.suiteDataCheck && this.root.suiteDataCheck(this.formData);
        },
        handleSelectEmp(title, selectType, value) {
            if(this.disabled) return;
            this.modalConfig = {
                type: 1,
                selectType,
                title,
                isStaff: 1,
                onlyStaff:true,
                checkStrictly: true,
                model: value,
                hideDepLock:true,
                checkList: isArray(this.value)
                    ? this.value
                    : [this.value],
                
            };
            // if(this.quitContact){
            //     this.empDialogVisible=true;
            // }else{
            //     this.dialogVisible = true;
            // }
            this.empDialogVisible=true;
        },
        handleConfirm(val) {
            this.$emit('input',val.checkList);
            this.empDialogVisible=false;
/*             if(this.quitContact){
         
            }else{
                this.$emit('input',val);
                this.dialogVisible = false;
            } */
        }
    }    
}
</script>

<style  scoped>

</style>