<template>
  <div class="form-radio-wrapper">
    <template v-if="mode == 'single-column'">
      <el-select v-model="selectValue" :placeholder="placeholder" @change="suiteDataCheck" filterable :disabled="disabled" :remote="isDistributor" :remote-method="remoteMethod" :loading="loading">
        <el-option
          class="more-label"
          v-for="item in options"
          :key="item.value"
          :label="item.label"
          :value="item.value">
          <p>{{item.label || '-'}}</p>
          <p>{{item.name}}</p>
        </el-option>
      </el-select>
    </template>
    <template v-else>
      <el-cascader
        filterable
        :placeholder="placeholder+'，可搜索'"
        v-model="selectValue"
        :options="options"
        ref="cascader"
        :props="{emitPath:false}"
        :disabled="disabled"
        @change="suiteDataCheck"></el-cascader>
    </template>
  </div>
</template>
<script>
import {isArray,isObject} from "@/utils";
export default {
  props: ['value', 'formData'],
  inject: ['root'],
  data(){
    return {
      loading:false
    }
  },
  computed: {
    selectValue: {
      get() {
        return this.value
      },
      set(val) {
        return this.$emit('input', val);
      }
    },
    options() {
      return this.formData?.slot?.options || []
    },
    placeholder() {
      return this.formData?.placeholder || '请选择'
    },
    mode() {
      return this.formData?.config?.mode || 'single-column'
    },
    disabled() {
      return !!this.formData?.config?.expandParams?.disabled;
    },
    //定制 是否为分销商借还款
    isDistributor(){
      return this.formData?.config?.expandParams?.type == 'distributor' && this.formData?.config?.field == 'loanName';
    }
  },
  mounted() {
    if((!isArray(this.formData?.config?.defaultValue) && this.formData?.config?.defaultValue) || (isArray(this.formData?.config?.defaultValue) && this.formData?.config?.defaultValue.length > 0)){
      this.$emit('input', this.formData?.config?.defaultValue)
    }
    if(this.isDistributor){
      this.remoteMethod();
    }
  },
  methods: {
    suiteDataCheck(val,list=null) {
      //级联选择的时候，记录label
      if (this.mode == 'mutil-column-auto') {
        this.$nextTick(() => {
          this.formData.config.caLabel = this.$refs['cascader'].presentText;
        })
      }
      //填充其他信息
      if(this.isDistributor){
        let item = list ? list.find(el=>{
          return el.value == val;
        }) : this.options.find(el=>{
          return el.value == val;
        });
        this.$set(this.formData.slot,'optionsBack',[item]);
        if(item){
          this.$emit('setFormData',{
            loanAccount:item?.bankInfo?.bankNo || '',
            openBank:item?.bankInfo?.bankName || '',
            loanBalance:item.balance || 0,
            loanCommission:item?.notExecuteCashBack || 0
          })
          //还款套件或成交支出时才操作
          if(["payBackSuite","dealExpendSuite"].includes(this.formData?.suiteName)){
            this.$emit('getFormItem',{
              field:'totalMoney',
              callback:(el)=>{
                //未还余额
                let balance  = item?.balance || 0;
                //未结佣金
                let commissionOpen = item?.notExecuteCashBack || 0;
                //实际未还余额
                let realityBalance  = item?.realityBalance || 0;
                //被冻结的金额
                let freezeBalance  = item?.freezeBalance || 0;
                //针对还款套件进行数据填充，还款套件并无未还余额和未结佣金、冻结金额等选项
                if(!isObject(el.config.expandParams)){
                  el.config.expandParams = {};
                }
                //统一写入金额的额外数据
                el.config.expandParams.balance= balance;
                el.config.expandParams.commissionOpen= commissionOpen;
                el.config.expandParams.realityBalance = realityBalance;
                el.config.expandParams.freezeBalance = freezeBalance;
              }
            })
          }
        }else{
          this.$emit('setFormData',{
            loanAccount:'',
            openBank:'',
            loanBalance:0,
            loanCommission:item?.notExecuteCashBack || 0
          })
        }
      }
      this.root.suiteDataCheck && this.root.suiteDataCheck(this.formData);
    },
    //动态拉取数据，目前仅支持借款套件中的分销商
    remoteMethod(keyword,id,init){}
  },
  watch:{
    value(val){
      //回显数据，此时才执行
      if(this.isDistributor){
        setTimeout(()=>{
          //检查选项中是否存在对应的值
          let item = this.options.find(el=>{
            return el.value == val;
          });
          //不存在
          if(!item){
            this.$nextTick(()=>{
              this.remoteMethod('',this.value);
            })
          }else{
            //存在则立即写入
            this.suiteDataCheck(val);
          }
        },1500)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .el-select {
  width: 100%;
}

::v-deep .el-cascader {
  width: 100%;
}
.el-select-dropdown__item.more-label{
  height:auto;
  line-height:1;
  padding: 0 20px 10px;
  p:first-child{
    height:34px;
    line-height:34px;
  }
  p:last-child{
    font-size:12px;
    color:#409EFF;
  }
}
</style>
