<template>
<div>
    <el-button type="primary" icon="el-icon-camera-solid" @click="handleClick">点击拍照</el-button>
</div>
</template>
<script>

export default {

    name:'camera',
    methods:{
        handleClick(){
            this.$message.warning('请于手机端发起水印相机拍照')
        }
    }


}
</script>

<style lang="scss" scoped>

</style>