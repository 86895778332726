<template>
  <div class="dialog__wrapper">
    <el-dialog
      :title="dialogTitle"
      :visible.sync="dialogShow"
      :destroy-on-close="true"
      custom-class="disabled-none"
      top="12vh"
      :modal="dialogModal"
      :append-to-body="true"
      :close-on-click-modal="false"
      :props="{ label: 'name' }"
      width="800px"
      @close="onClose(false)"
      @closed="onClosed"
      @open="handleOpen"
    >
      <div class="dep-container">
        <el-row type="flex" class="row-bg" justify="space-between">
          <el-col :span="12" class="panel-box">
            <div class="panel-box_wrap">
              <div class="left-panel_box">
                <div class="search-box">
                  <el-row type="flex">
                    <el-input
                      placeholder="请输入搜索内容"
                      v-model="keyword"
                      size="small"
                      class="input-box"
                    >
                      <i
                        slot="prefix"
                        class="el-input__icon el-icon-search"
                      ></i>
                    </el-input>
                  </el-row>
                </div>
                <!-- @node-click="handleClick" -->
                <div class="dep-box" v-loading="loading">
                  <el-tree
                    ref="tree"
                    :data="treeOptions"
                    node-key="id"
                    highlight-current
                    :expand-on-click-node="false"
                    :default-expand-all="false"
                    show-checkbox
                    :default-expanded-keys="defaultExpandedKeys"
                    :check-strictly="config.checkStrictly"
                    @check="handleCheckTree"
                    :filter-node-method="filterNode"
                    :props="{ label: 'name' }"
                    :auto-expand-parent="true"
                    class="dep-tree"
                  >
                    <div class="custom-tree-node" slot-scope="{ node, data }">
                      <template>
                        <my-avatar
                          icon="el-icon-user"
                          style="background: #87d068"
                          :size="24"
                          v-if="!data.avatar"
                        ></my-avatar>
                        <my-avatar
                          :src="img_url + data.avatar"
                          class="fxy-avatar"
                          v-else
                        ></my-avatar>
                      </template>
                      <span class="nickname">{{ node.label }}</span>
                    </div>
                  </el-tree>
                </div>
              </div>
            </div>
          </el-col>
          <el-col :span="12" class="panel-box">
            <div class="panel-box_wrap">
              <div class="right-panel_box">
                <div class="check-txt">已选：</div>
                <div
                  class="flex flex-center"
                  v-for="(item, i) in checkList"
                  :key="i"
                >
                  <div class="flex-item">
                    <el-row type="flex" class="row-bg" justify="space-between">
                      <div class="">
                        <template>
                          <my-avatar
                            :src="img_url + item.avatar"
                            class="fxy-avatar"
                          ></my-avatar>
                        </template>
                        <span class="nickname">{{ item.name }}</span>
                      </div>
                    </el-row>
                  </div>
                  <div class="child" @click="handleDeleteNode(item)">
                    <i class="el-icon-delete"></i>
                  </div>
                </div>
              </div>
            </div>
          </el-col>
        </el-row>
        <el-row type="flex" class="modal-footer" justify="end">
          <el-checkbox v-model="isHideDep" class="switch-flex flex-left" :true-label="1" :false-label="0" v-if="typeNav == 'system' && !hideDepLock" @change="handleOpen(true)">显示隐藏部门</el-checkbox>
          <el-button type="warning" size="small" @click="onClose(false)"
            >取 消</el-button
          >
          <el-button type="primary" size="small" @click="onSubmit"
            >确 认</el-button
          >
        </el-row>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import dialogMixin from "@/mixins/dialogCommon";
import { getDepByTree } from "@/api/dep";
import { getPostListByTree } from "@/api/post/index";
import { cloneDeep, isArray } from "@/utils";

export default {
  name: "DepRoleModal",
  mixins: [dialogMixin],
  props: {
    config: {
      type: Object,
      default: () => ({}),
    }
  },
  watch: {
    config: {
      deep: true,
      immediate: true,
      handler(val) {},
    },
    keyword(val) {
      this.$refs.tree.filter(val);
    },
  },
  data() {
    return {
      loading: false,
      keyword: null,
      typeNav: "system",
      checkList: [],
      treeData: [],
      depTree: [],
      postTree: [],
      defaultExpandedKeys: [],
      childrenNodeList:[],
      includeSubDepSwitch:false,
      treeResetLock:true,
      isHideDep:0
    };
  },
  computed: {
    dialogTitle() {
      return this.config.title;
    },
    dialogModal() {
      return this.config.hasOwnProperty("modal") ? this.config.modal : true;
    },
    //
    isIncludeSubDep(){
      return this.config?.includeSubDep && this.config?.type == 1;
    },
    treeOptions(){
      return this.config?.treeData || [];
    },
    hideDepLock(){
      return !!this.config?.hideDepLock;
    }
  },
  methods: {
    filterNode(value, data) {
      if (!value) return true;
      return data.name.indexOf(value) !== -1;
    },
    onSubmit() {
      let callbackData = this.isIncludeSubDep ? {
        includeSubDep:this.includeSubDepSwitch ? 1 : 0,
        list:this.checkList
      } : this.checkList;
      this.$emit("confirm",  callbackData);
    },
    onClosed(){
      this.typeNav = "system";
      this.keyword = '';
    },
    handleCheckTree(data, node) {
      let checkList=[];
      if (this.config.selectType === "radio") {
        checkList = cloneDeep(this.checkList);
        if (node.checkedKeys.indexOf(data.id) > -1) {
         let option = {
              id: data.id,
              name: data.name,
              parentId: data.parentId,
              employeeId: data.id,
              type: data.type,
              avatar: data.avatar || "",
              userId:data.userId
          }
          //2 员工  4 职务
          if(this?.config?.type == 2 && this?.config?.isStaffDisabled == 1 && !!this?.config?.isContact){
            //判断是否有和他一样的数据
            let index = findIndex(checkList,el=>{
              return el.type == data.type;
            });
            if(index != -1){
              checkList[index] = option;
            }else{
              checkList.push(option);
            }
          }else{
            checkList = [option];
          }
        }else{
          checkList = [];
        }
      } else if (this.config.selectType === "checkbox") {
        //并选模式
        if(this.config?.isContact){
          //检查对应选项是否选中
          let check = node.checkedNodes.find(el=>{
            return el.id == data.id;
          });
          //选中
          if(check){
            //删除对应选项
            checkList = cloneDeep(this.checkList);
            checkList.push({
                id: data.id,
                name: data.name,
                parentId: data.parentId,
                type: data.type,
                employeeId: data.id,
                avatar: data.avatar || "",
                userId:data.userId
            })
          }else{
            checkList=this.checkList.filter(el=>{
              return el.id!=data.id;
            })
          }
        }else{
          node.checkedNodes.forEach((item) => {
            checkList.push({
                id: item.id,
                name: item.name,
                parentId: item.parentId,
                type: item.type,
                employeeId: item.id,
                avatar: item.avatar || "",
                userId:item.userId
              });
          });
        }
      }
      this.checkList = checkList;
      if(this.config.isContact){
        //只选择和自己一致的元素
        let selectKeys = checkList.filter(el=>{
          return el.type == data.type;
        });
        this.$refs.tree.setCheckedKeys(selectKeys.map(el=>{
          return el.id;
        }));
      }else{
        this.$refs.tree.setCheckedNodes(checkList);
      }
    },

    handleDeleteNode(node) {
      this.checkList.forEach((item, index) => {
        if (item.id === node.id) {
          this.checkList.splice(index, 1);
        }
      });
      this.$refs.tree.setCheckedNodes(this.checkList);
    },
    async handleChangeTypeNav(val) {
      //职位
      if (val === "system") {
        this.treeData = this.depTree;
      } else if (val === "post") {
        if (this.postTree.length === 0) {
          this.postTree = await this.getPostData();
        }
        this.treeData = this.postTree;
      } else if (val === "role") {
      }

      //重新选中
      if(this.config?.isContact){
          let type = val == 'system' ? 2 : 4;
          let selected = this.checkList.filter(el=>{
            return el.type == type;
          });

          this.$refs.tree.setCheckedKeys(selected.map(el=>{
            return el.id;
          }))
      }
    },
    getPostData() {
      this.loading = true;
      return getPostListByTree({
        type: this.config.type,
        isStaff: this.config.isStaff || 0,
        isStaffDisabled:this?.config?.isStaffDisabled || 0
      })
        .then((response) => {
          if (response.data.length > 0) {
            this.defaultExpandedKeys = [response.data[0].id];
          }
          this.postTree = response.data;
          this.loading = false;
          return response.data;
        })
        .catch((error) => {
        });
    },
    async handleOpen(isReset) {
       if(!isReset){
        this.checkList = this.config?.checkList
          ? cloneDeep(this.config.checkList)
          : [];
        this.includeSubDepSwitch = !!this?.config?.includeSubDepSwitch;
      }
      let res = [];
      if (this.config.type === 3) {
        res = await this.getPostData();
      } else {
        this.loading = true;
        res = await getDepByTree({
          type: this.config.type,
          isStaff: this.config.isStaff || 0,
          isStaffDisabled:this?.config?.isStaffDisabled || 0,
          isHideDep:this?.config?.hideDepLock ? 0 :this.isHideDep
        }).then((response) => {
          if (response.data.length > 0) {
            this.defaultExpandedKeys = [response.data[0].id];
          }
          this.loading = false;
          return response.data;
        });
        this.depTree = res;
      }
      this.treeData = res;
      this.$nextTick(() => {
        this.$refs.tree.setCheckedNodes(this.checkList);
      });
    },
  }
};
</script>
<style lang="scss" scoped>
::v-deep .el-dialog__body {
  padding: 0 30px 20px;
}
.switch-flex {
  display: flex;
  align-self: center;
  padding-right: 10px;

  ::v-deep .el-checkbox__input {
    position: relative;
    top: 2px;
  }
  &.flex-left{
    margin-right:auto;
  }
}
.dep-container {
  .dep-tree {
    margin-top: 20px;
  }

  .modal-footer {
    margin-top: 10px;
  }

  .panel-box {
    .left-panel_box {
      margin-top: 10px;
      padding-right: 15px;
      height: 400px;
      overflow: auto;

      ::v-deep .input-box {
        .el-input__inner {
          background: #f5f6f7;
          border-color: #f5f6f7;
        }
      }
    }

    .right-panel_box {
      padding-left: 15px;

      .check-txt {
        margin-bottom: 10px;
      }
    }

    .all-checkbox_box {
      margin: 10px 0;
    }

    .checkbox-box {
      padding: 5px 0;

      ::v-deep .el-checkbox__label {
      }

      ::v-deep .el-checkbox__input {
        vertical-align: inherit;
      }
    }

    .fxy-icon-wenjianjia {
      margin-right: 4px;
      color: #aee4fa;
      font-size: 17px;
      vertical-align: middle;
    }

    .fxy-icon-yingpinzhiwei {
      font-size: 20px;
      vertical-align: middle;
      color: #faad14;
    }

    .child {
      cursor: pointer;

      .el-icon-delete {
        font-size: 16px;
        color: #f56c6c;
      }
    }

    .nav-box {
      text-align: center;
      margin-top: 10px;
    }
  }

  .fxy-avatar {
    width: 25px;
    height: 25px;
    line-height: 25px;
    margin-right: 4px;
  }

  .nickname {
    vertical-align: middle;
  }
}
</style>
