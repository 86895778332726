<template>
  <div class="t-form-textarea">
    <el-input
      v-model="inputValue"
      :placeholder="placeholder"
      type="textarea"
      :autosize="{ minRows: 3, maxRows: 5}"></el-input>
  </div>
</template>
<script>

export default {
  inject: ['root'],
  props: {
    value: {
      default: ''
    },
    formData: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    inputValue: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val);
      }
    },
    placeholder() {
      return this.formData?.placeholder || '请输入'
    }
  },
  methods: {
    suiteDataCheck() {
      this.root.suiteDataCheck && this.root.suiteDataCheck(this.formData);
    }
  },
  mounted() {
    if(this.formData.config?.defaultValue && !this.value){
      this.$emit('input', this.formData.config?.defaultValue || '');
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
