<template>
<div class="date-wrapper">
    <div v-if="formData.config.field == 'leaveEndDate' || formData.config.field == 'leaveStartDate'">
        <el-date-picker
        v-model="dateValue"
        type="date"
        @change="suiteDataCheck"
        :format="format"
        :disabled="disabled"
        :value-format="format"
        :picker-options="pickerOptions"
        v-if="dateTypeMode == 'date' || disabled"
        :placeholder="placeholder">
        </el-date-picker>
        <div v-else class="r-flex">
            <div class="date-cover">
                <el-date-picker
                v-model="dateBeatValue"
                type="date"
                @change="suiteDataCheck"
                format="yyyy-MM-dd"
                :disabled="disabled"
                :clearable="false"
                value-format="yyyy-MM-dd"
                :picker-options="pickerOptions"
                placeholder="请选择日期">
                </el-date-picker>
            </div>
            <div class="date-g">—</div>
            <div class="date-cover">
            <el-time-select
                v-model="timeValue"
                :disabled="disabled"
                value-format="HH:mm"
                @change="suiteDataCheck"
                :clearable="false"
                :default-value="timeValue"
                :picker-options="{
                    start: '00:00',
                    step: '00:30',
                    end: '23:30',
                    format:'HH:mm',
                }"
                placeholder="请选择时间">
            </el-time-select>
            </div>
        </div>
    </div>
    <div v-else>  
        <el-date-picker
        v-model="dateValue"
        :type="dateTypeMode"
        @change="suiteDataCheck"
        :format="format"
        :disabled="disabled"
        :value-format="format"
        :picker-options="pickerOptions"
        time-arrow-control
        :placeholder="placeholder">
        </el-date-picker>
    </div>
</div>
</template>
<script>
import {formatDate} from "@/utils/date"
export default {
    inject: ['root'],
    props:{
        value:{
            default:''
        },
        formData:{
            type:Object,
            default:()=>({})
        }
    },
    data(){
        return{
            dateType:['yyyy-mm-dd','yyyy','yyyy-mm','mm-dd'],
            dateTimeType:['yyyy-mm-dd hh:MM','yyyy-mm-dd hh','mm-dd hh:MM','mm-dd hh'],
            timeType:['hh:MM:ss','hh:MM'],
            dateTypeObj:{
                'yyyy-mm-dd':'date',
                'yyyy-mm':'month',
                'mm-dd':'date',
                'yyyy':'year',
                'yyyy-mm-dd hh:MM:ss':'datetime',
                'yyyy-mm-dd hh:MM':'datetime'
            },
            randId:new Date().getTime(),
            randId1:new Date().getTime() + 10
        }
    },
    methods:{
        suiteDataCheck(){
            if(!this.value) return;
            this.root.suiteDataCheck && this.root.suiteDataCheck(this.formData);
        },
        //移动端和pc格式不一致，兼容一下
        formatChange(format){
            if(!format){
                return 'yyyy-mm-dd'
            }else{
                let _format = format.replace('yyyy-mm','yyyy-MM');
                _format = _format.replace('hh','HH');
                _format = _format.replace('HH:MM','HH:mm');
                return _format;
            }
        }
    },
    computed:{
        dateValue:{
            get(){
                return this.value;
            },
            set(val){
                this.$emit('input',val);
            }
        },
        format(){
            return this.formatChange(this.formData?.format);
        },
        
        placeholder(){
            return this.formData?.placeholder || '请选择';
        },
        dateTypeMode(){
            return this.dateTypeObj[this.formData?.format] || 'date'
        },
        disabled(){
            return this.formData?.config?.field == 'duration' || !!this.formData?.config?.expandParams?.disabled;
        },
        pickerOptions(){
            return {
                disabledDate:(date)=>{
                    //补卡套件禁止选择未来的时间
                    if(this.formData?.config?.field == "workAttendanceCardDate"){
                        let _date =(new Date(date)).getTime();
                        let _now = (new Date()).getTime();
                        return _date > _now;
                    }else if(this.formData?.limitDate){
                        let _date =(new Date(date)).getTime();
                        let _end = (new Date(this.formData?.limitDate)).getTime();
                        return _date < _end;
                    }else{
                        return false;
                    }
                }
            }
        },
        dateBeatValue:{
            get(){
                return this.value ? formatDate(new Date(this.value),'yyyy-MM-dd') : '';
            },
            set(val){
                if(this.value){
                    let date = new Date(this.value);
                    this.$emit('input',`${val} ${formatDate(date,'hh:mm')}`);
                }else{
                    this.$emit('input',`${val} ${this.formData.config.field == 'leaveStartDate' ? '08:30' :'18:30' }`)
                }
            }
        },
        timeValue:{
            get(){
                return this.value? formatDate(new Date(this.value),'hh:mm') : '';
            },
            set(val){   
                if(this.value){
                    let date = new Date(this.value);
                    this.$emit('input',`${formatDate(date,'yyyy-MM-dd')} ${val}`);
                }else{
                    this.$emit('input','')
                }
            }
        }
    }
}
</script>

<style lang="scss" scoped>
    ::v-deep .el-date-editor.el-input{
        width: 100%;
    }
    .date-cover{
        flex:1 1 auto;
        width: 0;
    }
    .date-g{
        padding: 0 10px;
    }
</style>