<template>
<div class="department">
    <my-tag-input
    :readonly="true" 
    :placeholder="placeholder"
    class="fxy-select scale-select"
    :value="tags"
    size="small"
    @change="suiteDataCheck"
    :key="guid"
    @focus="handleSelectEmp('人员筛选', selectType , 'id')"
    :props="{icon:'el-icon-arrow-down'}">
    </my-tag-input>
    <!-- 人员选择 -->
    <dep-role-modal
        :config="modalConfig"
        v-model="dialogVisible"
        @confirm="handleConfirm"
    ></dep-role-modal>
</div>
</template>
<script>
import depRoleModal from '@/components/dialog/DepRoleModal';
import {isArray,guid} from "@/utils"
export default {
    inject: ['root'],
    props:{
        value:{
            default:''
        },
        formData:{
            type:Object,
            default:()=>({}) 
        }
    },
    components: {
        depRoleModal:depRoleModal
    },
    data(){
        return{
            modalConfig:{
                type: 1,
                selectType: "radio",
                title: `选择人员`,
                checkStrictly: false,
                model: ""
              },
            dialogVisible:false
        }
    },
    computed:{
        tags(){
            return isArray(this.value) ? this.value.map(el=>{
                return el?.name || el?.label
            }) : []
        },
        checkList(){
            return this.value.length> this.value
        },
        selectType(){
            return this.formData?.config?.selectMode || 'radio'
        },
        placeholder(){
            return this.formData?.placeholder || '请选择客户'
        },
        label(){
            return this.formData?.config?.label || '客户'
        },
        guid(){
            return guid(32);
        },
        disabled(){
            return !!this.formData?.config?.expandParams?.disabled;;
        }
    },
    methods:{
        suiteDataCheck(){
            this.root.suiteDataCheck && this.root.suiteDataCheck(this.formData);
        },
        handleSelectEmp(title, selectType, value) {
            if(this.disabled) return;
            this.modalConfig = {
                type: 1,
                selectType,
                title,
                isStaff: 1,
                checkStrictly: true,
                model: value,
                checkList: isArray(this.value)
                    ? this.value
                    : [this.value],
                
            };
            this.dialogVisible = true;
        },
        handleConfirm(val) {
            this.$emit('input',val);
            this.dialogVisible = false;
        }
    }    
}
</script>

<style  scoped>

</style>