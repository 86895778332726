<template>
  <div
    class="apply-create-wrapper"
    v-loading="loading"
    :element-loading-text="loadingText"
    element-loading-background="rgba(255, 255, 255, 0.8)"
  >
    <div class="form-content r-flex r-col-top">
      <div class="form-cover r-flex-auto-w">
        <el-form ref="form" label-width="120px" size="small" label-position="top">
        <template v-for="(item, index) in formList">
          <div class="form-item-cover max" :key="index"  v-if="!item.config.isInstead && !item.config.isHide">
            <el-form-item
              :label="getItemLabel(item.config)"
              :label-width="item.config.tagIcon == 'tips' || item.config.tagIcon == 'edit' ? '0px' : '120px'"
              v-if="!item.config.notRender"
              :class="{ 'is-required': item.config.required }"
            > 
            <template #label>
              <span>{{ getItemLabel(item.config) }}</span>
              <span class="is-underline form-label main-color" v-if="item.config.expandParams.descEdit && item.config.expandParams.descEdit != '<p><br></p>'" @click="showEdit(item.config.expandParams.descEdit,item.config.expandParams.dialogTips)">{{ item.config.expandParams.dialogTips }}</span>
              <span class="form-label main-color" v-else>{{ item.config.expandParams.dialogTips }}</span>
            </template>
              <component
                :is="item.config.tagIcon"
                v-model="item.value"
                :formData="item"
                :formUid="formUid"
                @setFormData="setFormData"
                @getFormItem="getFormItem"
              ></component>
            </el-form-item>
            <div class="detailed-cover"  v-else-if="!item.config.isInfo">
                <template v-if="item.config.tagIcon == 'detailed' || item.config.groupItem">
                  <div class="form-item-title r-flex r-col-center r-row-bet">
                    <span>{{item.config.label}}</span>
                    <div class="suite-block-delete" v-if="item.config.addMode == 'checkbox'" @click="suiteBlockDelete(item.config.field,index,item.config.label)">
                      <i class="el-icon-delete"></i>
                    </div>
                  </div>
                </template>
                <div class="detailed-cover-item r-flex r-flex-wrap">
                  <template v-for="(_item,_index) in item.config.groupItem" >
                    <el-form-item
                      :label="getItemLabel(_item.config)"
                      label-position="right"
                      label-width="120px"
                      :key="_index"
                      :class="{ 'is-required': _item.config.required }"
                    >
                      <component
                        :is="_item.config.tagIcon"
                        v-model="_item.value"
                        :formData="_item"
                      ></component>
                    </el-form-item>
                  </template>
                </div>
                <div class="err-tips" v-if="item.config && item.config.groupItem && item.config.groupItem.length == 0">{{item.config.showTips}}</div>
                <template v-if="item.config.tagIcon == 'detailed' && item.config.blockAdd &&  item.config.addMode == 'checkbox'">
                  <el-button style="width:100%" type="primary" size="small" icon="el-icon-plus" plain @click="addBlock(item.config.field,index,-1,true)">新增{{item.config.label}}</el-button>
                </template>
            </div>
          </div>
        </template>
      </el-form>
      </div>
      <!-- 审批流程 -->
      <div class="flow-wrapper section">
        <div class="title">流程</div>
        <div class="flow-list">
          <template v-if="workFlowData.length > 0">
            <div
              class="flow-item"
              v-for="(item, index) in workFlowData"
              :key="index"
              :class="{ 'first-line': index == 0 && workFlowData.length > 1 }"
            >
              <div class="anchor">
                <div class="anchor-cover r-flex r-col-center r-row-center">
                  <div class="point"></div>
                </div>
              </div>
              <div
                class="flow-item-content r-flex r-col-top r-row-bet"
                :class="{ 'u-border-top': index > 0 }"
              >
                <div class="info">
                  <div class="info-t">{{ item.nodeName }}</div>
                  <div
                    class="info-b"
                    v-if="item.nodeUserList.length > 0 || item.type === 2"
                  >
                    {{ getWorkflowTips(item) }}
                  </div>
                  <div class="info-b" v-else-if="item.setType == 4">
                    {{ getWorkflowTips(item) }}
                  </div>
                  <div class="info-b" v-else>
                    <div
                      v-if="
                        item.noHanderAction == 1 || item.noHanderAction == 2
                      "
                      class="no-hander"
                    >
                      <span>未找到{{ item.nodeName }}</span>
                      ，{{
                        item.noHanderAction == 1
                          ? "已自动通过"
                          : "将自动转交审批管理员"
                      }}
                    </div>
                  </div>
                </div>
                <div
                  class="approvedBy r-flex r-flex-wrap r-col-center r-row-right"
                >
                  <div
                    class="p-item r-flex r-col-center r-row-left"
                    v-for="(_item, _index) in item.nodeUserList"
                    :key="_index"
                    v-bind:class="{
                      'or-sign': item.examineMode === 3,
                      'order-sign': item.examineMode === 1,
                    }"
                  >
                    <div
                      class="p-item-content r-flex r-row-center r-col-center"
                    >
                      <div class="p-avatar">
                        <img
                        v-avatar
                          :src="
                            (_item.avatar)
                              | $imageUrlAvatar
                          "
                          class="avatar"
                          mode="aspectFill"
                          v-if="_item.isSelect === 1"
                          @click="appendUser(item)"
                        >
                        <img
                          :src="
                            (_item.avatar)
                              | $imageUrlAvatar
                          "
                          v-avatar
                          class="avatar"
                          mode="aspectFill"
                          v-else
                        >
                        <i
                          class="del el-icon-delete"
                          v-if="_item.isSelect === 1"
                          @click="deleteNodeUser(index, _index)"
                        ></i>
                      </div>
                      <div class="p-name">{{ _item.name }}</div>
                    </div>
                  </div>
                  <div
                    class="p-item r-flex r-col-center r-row-left"
                    v-bind:class="{
                      'plus-item':
                        (item.type === 2 && item.ccSelfSelectFlag === 1) ||
                        item.setType === 4,
                      'or-sign': item.examineMode === 3,
                    }"
                    v-if="item.setType === 4 || item.ccSelfSelectFlag === 1"
                  >
                    <div
                      class="p-item-content r-flex r-row-center r-col-center"
                      @click="appendUser(item)"
                    >
                      <div class="plus-box">
                        <i class="el-icon-plus"></i>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </template>
          <template v-else>
            <div class="work-error">
              {{ msgTxt }}
            </div>
          </template>
        </div>
      </div>
    </div>
    <div class="fxy-drawer_footer">
      <el-button  @click="close" size="small"
        >取 消
      </el-button>
      <el-button type="primary" size="small" @click="formSubmit" :disabled="submitLock || !!isBreak || flowLoading"
        >提交
      </el-button>
    </div>
    <!-- 人员选择 -->
    <dep-role-modal
        :config="modalConfig"
        v-model="dialogVisible"
        @confirm="handleConfirm"
    ></dep-role-modal>
    <custom-role-model
      :config="customModalConfig"
      v-model="customDialogVisible"
      @confirm="customHandleConfirm"
    ></custom-role-model>
    <rich-text-show ref="richTextShow"></rich-text-show>
  </div>
</template>
<script>
/**
 * 表单申请页面
 */
import formCreateMixin from "@/mixins/approve/formCreate";
import workFlowMixin from "@/mixins/approve/formWorkflow";
import throttleMixins from "@/mixins/throttle";
import { getFormDetail, apply,approveAgainConfirm } from "@/api/approve";
import depRoleModal from '@/components/dialog/DepRoleModal';
import customRoleModel from "@/components/dialog/customRoleModal";
import richTextShow from "@/components/rich-text-show";
import { cloneDeep,isEqual,guid} from '@/utils';
const slotsFiles = require.context("./slots", false, /\.vue$/);
const keys = slotsFiles.keys() || [];
const _components = {};
keys.forEach((key) => {
  const tag = key.replace(/^\.\/(.*)\.\w+$/, "$1");
  const value = slotsFiles(key).default;
  _components[tag] = value;
});
export default {
  mixins: [formCreateMixin, workFlowMixin, throttleMixins],
  components: {
    ..._components,
    depRoleModal,
    customRoleModel,
    richTextShow
  },
  props:{
    templateId:{
      type:String,
      default:''
    },
    reSubmitModel:{
      type:Boolean,
      default:false
    },
    submitLock:{
      type:Boolean,
      default:false
    }
  },
  provide() {
    return {
      root: this
    };
  },
  data() {
    return {
      loading: true,
      loadingText: "正在加载中...",
      rules: [],
      form: {},
      info: {},
      salaryShow:false,
      flowActiveItem:null,
      dialogVisible:false,
      customDialogVisible:false,
      lock:false,
      modalConfig:{
        type: 1,
        selectType: "radio",
        title: `选择人员`,
        checkStrictly: false,
        model: ""
      },
      customModalConfig:{
        type: 1,
        selectType: "radio",
        title: `选择人员`,
        checkStrictly: false,
        model: ""
      },
      reSubmitLock:false,
      applyFormResetLock:false,
      inited:false,
      isReSubmit:false,
      isConfirm:false,
      formUid:''
    };
  },
  methods: {
    resetForm(){
      //当为报销套件且不是正常提交退出时保存文件
      if(this.suiteName == 'reimbursementSuite'){
        //数据不一致才缓存
        if(!this.isConfirm && !isEqual(this.formListBak,this.formList) && !this.isReSubmit){
          this.$store.commit('approveSetData',{
            'applyFormList':this.formList,
            'applyFormData':this.formData,
            'applyFormId':this.templateId
          });
        }
      }
      this.lock=true;
      this.loading=true;
      this.salaryShow=false;
      this.loadingText = "正在加载中...";
      this.workFlowData= [];
      this.msgTxt= '';
      this.nodeId= '';
      this.isBreak= 0;
      this.processControl= {};
      this.salaryDialogConfig={
        title:'工资单明细',
        date:'',
        depId:''
      };
      this.formItemList={};
      this.formList=[];
      this.formData=[];
      this.formListBak=[];
      this.suiteName='';
      this.isConfirm=false;
      this.suiteBlocks={};
      this.popupCover={};
      this.conditionOption=null;
      this.applyFormResetLock=false;
      this.reloadFormData={};
      this.isReSubmit=false;
      this.inited=false;
      //清除掉监听方法
      this.clearSetApplyFormDataEvent();
      //保证不会继续刷新
      let invId = setTimeout(()=>{
        this.lock=false;
        clearTimeout(invId);
      },500)
    },
    getFormData() {
      //记录下初始化状态
      let isInit = this.inited;
      if(!this.inited){
        this.inited = true;
        this.$eventBus.$on('setApplyFormData',(res)=>{
          this.formList = cloneDeep(res);
        })
      }
      getFormDetail({ templateId: this.templateId })
        .then((res) => {
          //表单信息
          this.info = res.data.info;
          if(this.applyFormResetLock){
            this.formList = [];
            this.workFlowData = [];
          }
          //初始化表单数据
          this.formInit(res.data.formGennerator);
          this.$nextTick(()=>{
            this.$eventBus.$emit('applyFormReady',this.formList);
            if(!this.applyFormResetLock){
              this.$emit('applyFormReady',this.formList)
            }
            //存在需要回流的数据
            this.$nextTick(()=>{
              this.reloadFormDataStart();
            })
            
          })
          //初始化
          if(!isInit){ 
            //检查缓存数据,3秒后执行
            let inv_id = setTimeout(()=>{
              clearTimeout(inv_id);
              this.applyCacheCheck();
            },1000)
          }

          //标记uid
          this.formUid = guid(20);
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    //提交数据
    async formSubmit() {
      let check = this.formUpdateCheck();
      if (!check) return;
      this.loadingText = "正在提交...";
      this.loading = true;
      //首先触发提交前校验
      await this.submitBefore();
      let formData = this.createUploadFormData();
      let options = {
        formList: formData,
        workCheckData: this.workFlowData,
        quiet:true
      }
      
      if(this.reSubmitModel){
        options.approveId = this.approveId;
      }else{
        options.templateId=this.info.id;
      };
      (this.reSubmitModel ? approveAgainConfirm: apply)(options)
        .then((res) => {
          this.$alert("申请单提交成功", "提交成功",{type:'success'});
          //标记提交状态
          this.isConfirm=true;
          this.$emit("confirm");
          //清空
          this.$store.commit('approveSetData',{
            'applyFormList':null,
            'applyFormData':null,
            'applyFormId':null
          });
        })
        .catch((err) => {
          console.log(err);
          this.$alert(err.message || "申请单提交失败", "提交失败",{type:'error'});
        })
        .finally(() => {
          [].includes
          this.loading = false;
        });
    },
    close(){
      this.$emit('close');
    },
    clearSetApplyFormDataEvent(){
      this.$eventBus.$off("setApplyFormData");
    },
    showEdit(content,title){
      this.$refs.richTextShow.open(content,title)
    }
  }
};
</script>

<style lang="scss" scoped>
.apply-create-wrapper {
  padding: 20px 0;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  .form-content {
    flex: 1 1 auto;
    height: 0;
    padding: 0 20px 0;
    overflow: hidden;
    overflow-y: auto;
    .form-cover{
      padding-right:40px;
      height:100%;
      overflow: hidden;
      overflow-y:auto;
    }
    ::v-deep .el-form--label-top .el-form-item__label{
      padding-bottom:3px;
    }
    ::v-deep .form-label{
      font-size:12px;
      margin-left:5px;
      &.is-underline{
        border-bottom:1px solid var(--primary);
        cursor: pointer;
        &:hover{
          opacity: .7;
        }
      }
    }
    .el-form{
      display: flex;
      flex-wrap:wrap;
    }
    .form-item-cover{
      width: 50%;
      &:nth-child(even){
        padding-left:20px;
      }
      &.max{
        width: 100%;
        padding-left:0!important;
      }
    }
    .detailed-cover{
      padding: 10px 10px;
      border:1px solid #eeeeee;
      border-radius:4px;
      margin-bottom:20px;
      .suite-block-delete{
        cursor: pointer;
        opacity: .7;
        i{
          color:#FF7171;
          font-size:18px;
        }
        &:hover{
          opacity: 1;
        }
      }
      ::v-deep .el-form-item{
        width: 50%;
      }
    }
    .form-item-title{
      padding-bottom:20px;
      span{
        font-size:16px;
        text-align: right;
        padding-right:12px;
        color:#666666;
      }
    }
    .err-tips{
      font-size:14px;
      color:#999999;
    }
    .flow-wrapper {
      max-width: 562px;
      flex:0.55;
      padding-left:40px;
      position: relative;
      height:100%;
      overflow: hidden;
      overflow-y:auto;
      &::before{
        content:'';
        position: absolute;
        top:10px;
        left:0;
        bottom:13px;
        width: 1px;
        background: #F4F4F4;
      }
      .title {
        font-size: 20px;
        line-height: 1;
        color: #000000;
      }

      .work-error {
        padding: 20px 0 15px;
        color: #666666;
        font-size: 22rpx;
      }

      .flow-list {
        .flow-item {
          position: relative;

          &:first-child {
            .anchor .anchor-cover {
              &::before,
              &::after {
                background: #fff;
              }
            }
          }

          &.first-line {
            .anchor .anchor-cover {
              &::before {
                background: #fff;
              }

              &::after {
                background: #d1d1d1;
              }
            }
          }

          &:last-child {
            .anchor .anchor-cover::after {
              background: #fff;
            }
          }

          .anchor {
            padding: 0 11px;
            height: 100%;
            margin-right: 25px;
            position: absolute;
            left: 0;
            top: 0;
            bottom: 0;

            .point {
              width: 11px;
              height: 11px;
              border-radius: 50%;
              background: #d1d1d1;
            }

            .anchor-cover {
              height: 100%;
              flex-direction: column;

              &::before {
                content: "";
                height: 39px;
                width: 2px;
                background: #d1d1d1;
              }

              &::after {
                content: "";
                flex: 1 1 auto;
                height: 0;
                width: 2px;
                background: #d1d1d1;
              }
            }
          }

          .flow-item-content {
            margin-left: 58px;
            padding: 18px 0 8px;
            &.u-border-top{
              border-top:1px solid #f1f1f1;
            }
            &:after {
              border-color: #d1d1d1;
            }

            .info {
              padding-top: 12px;

              .info-t {
                font-size: 14px;
                line-height: 1;
              }

              .info-b {
                font-size: 12px;
                color: #8f98a8;
                line-height: 1;
                padding-top: 7.5px;

                .no-hander {
                  text {
                    color: #ff4040;
                  }
                }
              }
            }

            .approvedBy {
              flex: 1 1 auto;
              width: 0;
              padding: 0 18px 0 5px;

              .p-item {
                .p-item-content {
                  flex-direction: column;
                }

                &::before {
                  content: "+";
                  font-size: 15px;
                  color: #8f98a8;
                  padding: 0 7.5px;
                }

                &.or-sign {
                  &::before {
                    content: "/";
                  }
                }

                &.order-sign {
                  &:before {
                    content: ">";
                  }
                }

                &:first-child {
                  &::before {
                    color: #fff;
                  }
                }
              }

              .plus-box {
                border: 1px dashed #b3b3b3;
                width: 40px;
                height: 40px;
                text-align: center;
                line-height: 40px;
                border-radius: 3px;
                cursor: pointer;
                i{
                  color:#B3B3B3;
                  font-size:18px;
                }
              }

              .p-avatar {
                width: 40px;
                height: 40px;
                margin: 0 auto;
                text-align: center;
                color: #fff;
                border-radius: 10px;
                position: relative;

                .avatar {
                  width: 40px;
                  height: 40px;
                  border-radius: 10px;
                }

                .del {
                  position: absolute;
                  top: -6px;
                  right: -6px;
                  z-index: 1;
                  cursor: pointer;
                }
              }

              .p-name {
                font-size:14px;
                line-height: 1;
                color: #8f98a8;
                padding-top: 8px;
                text-align: center;
                margin-bottom: 10px;
                width: 48px;
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
              }
            }
          }
        }
      }
    }
  }
  .fxy-drawer_footer {
    padding: 20px 20px 0;
    border-top: 1px solid #f6f6f6;
    text-align: right;
  }
}
@media (max-width: 1550px) {
  .apply-create-wrapper .form-content .form-item-cover{
    width: 100%;
    padding-left:0!important;
  }
}
</style>
