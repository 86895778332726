import AMapLoader from '@amap/amap-jsapi-loader';

export default function MapLoader() {
  window._AMapSecurityConfig = {
    securityJsCode: '9ba0bbe2c9100a4ff0149f944f736a78'
  }

  return AMapLoader.load({
    key: 'ff777e28f8c03d5765c7fecc7096f57d', // 申请好的Web端开发者Key，首次调用 load 时必填
    version: '2.0', // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
    AMapUI: {
      version: '1.1', // AMapUI 缺省 1.1
      plugins: [] // 需要加载的 AMapUI ui插件
    },
    plugins: ['AMap.Geocoder', 'AMap.AutoComplete', 'AMap.PlaceSearch', 'AMap.Scale', 'AMap.ToolBar'] // 需要使用的的插件列表，如比例尺'AMap.Scale'等
  });
}
