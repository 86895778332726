import question from "@/views/question";
import questionList from "@/views/question/list";
import questionStatistics from "@/views/question/statistics";
import category from "@/views/question/category";
export default {
    question,
    questionList,
    category,
    questionStatistics
}
  