<template>
<el-drawer
        :title="dialogTitle"
        :visible.sync="dialogShow"
        direction="rtl"
        @open="formInit"
        size="65%"
        @closeDrawer="close"
        @closed="resetForm"
        :wrapperClosable="false"
        append-to-body>
        <apply-create ref="applyForm" :templateId="templateId" @close="close" @confirm="confirm" :submitLock="submitLock" @applyFormReady="applyFormReady"></apply-create>
      </el-drawer>
</template>
<script>
import applyCreate from "./applyCreate";
export default {
  components:{
    applyCreate
  },
  data(){
    return {
      submitLock:false,
      templateId:'',
      dialogShow:false,
      dialogTitle:'发起审批单'
    }
  },
  methods: {
    handleOpen(options){
      this.submitLock = !!options?.submitLock;
      this.templateId = options?.templateId;
      this.dialogTitle = options?.dialogTitle || '发起审批单';
      this.dialogShow = true;
    },
    //初始化的时候合并一下选项
    formInit() {
      this.$nextTick(()=>{
       this.$refs.applyForm?.getFormData && this.$refs.applyForm.getFormData();
      })
    },
    resetForm(){
     this.$refs.applyForm?.resetForm && this.$refs.applyForm.resetForm();
    },
    close(astrict=false){
      this.dialogShow = false;
      this.$emit('astrict',astrict);
    },
    confirm(){
      this.$eventBus.$emit('applyConfirm');
      this.$emit('applyConfirm');
      this.close(true);
    },
    //校验一下表单数据
    suiteDataCheck(item=null){
      if(!item && this.$refs.applyForm?.formList){
        item = this.$refs.applyForm.formList.find(el=>{return el.isSuite});
      }
      this.$refs.applyForm?.suiteDataCheck && this.$refs.applyForm?.suiteDataCheck(item);
    },
    applyFormReady(list){
      this.$emit('applyFormReady',list);
    }
  },
};
</script>

<style lang="scss" scoped>

</style>
