<template>
  <r-drawer ref="rDialog" :dialogTitle="title" size="800px" @close="reset" @submit="submit"
    :dialogLoading="dialogLoading">
    <div class="dialog-content">
      <el-form size="small" label-position="top" :rules="rules" :model="form" ref="form">
        <el-form-item prop="title" label="合同名称">
          <el-input v-model="form.title" placeholder="请输入合同名称"></el-input>
        </el-form-item>
        <el-form-item prop="type" label="合同类型">
          <el-select v-model="form.type" filterable remote reserve-keyword placeholder="请输入关键词"
            :remote-method="remoteMethod" :loading="pidLoading" class="r-w-max" clearable :disabled="isEdit">
            <el-option :label="item.title" :value="item.id" v-for="item in contractTypes" :key="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item prop="partyA" label="甲方">
          <div class="r-flex">
            <el-select v-model="form.partyA" filterable remote reserve-keyword placeholder="请输入关键词"
              :remote-method="remoteMethodPartyA" :loading="pidLoading" class="r-w-max" clearable :disabled="isEdit"
              @change="handlePartyA">
              <el-option :label="item.title" :value="item.id" v-for="item in partyAList" :key="item.id"></el-option>
            </el-select>
            <el-button type="primary" class="r-ml-10" @click="addParty('partyA')">新建参与方</el-button>
          </div>
        </el-form-item>
        <el-form-item prop="partyAContact" label="甲方联系方式">
          <el-input v-model="form.partyAContact" placeholder="请输入甲方联系方式"></el-input>
        </el-form-item>
        <el-form-item prop="partyB" label="乙方">
          <div class="r-flex">
            <el-select v-model="form.partyB" filterable remote reserve-keyword placeholder="请输入关键词"
              :remote-method="remoteMethodPartyB" :loading="pidLoading" class="r-w-max" clearable :disabled="isEdit"
              @change="handlePartyB">
              <el-option :label="item.title" :value="item.id" v-for="item in partyBList" :key="item.id"></el-option>
            </el-select>
            <el-button type="primary" class="r-ml-10" @click="addParty('partyB')">新建参与方</el-button>
          </div>
        </el-form-item>
        <el-form-item prop="partyBContact" label="乙方联系方式">
          <el-input v-model="form.partyBContact" placeholder="请输入乙方联系方式"></el-input>
        </el-form-item>
        <el-form-item prop="date" label="签订日期">
          <el-date-picker v-model="form.date" value-format="yyyy-MM-dd" type="date" placeholder="请选择签订日期"
            :disabled="isEdit" :picker-options="{}" :clearable="false">
          </el-date-picker>
        </el-form-item>
        <el-form-item prop="startDate" label="开始日期">
          <el-date-picker v-model="form.startDate" value-format="yyyy-MM-dd" type="date" placeholder="请选择开始日期"
            :picker-options="{}" :clearable="false">
          </el-date-picker>
        </el-form-item>
        <el-form-item prop="period" label="结束日期">
          <el-date-picker v-model="form.period" value-format="yyyy-MM-dd" type="date" placeholder="请选择结束日期"
            :disabled="form.isLongTerm == 1" :picker-options="{}" :clearable="false">
          </el-date-picker>
          <el-checkbox v-model="form.isLongTerm" :disabled="isEdit" :true-label="1" :false-label="0"
            style="margin-left: 10px;">长期</el-checkbox>
        </el-form-item>
        <el-form-item prop="status" label="合同状态">
          <el-select v-model="form.status" filterable :loading="pidLoading" class="r-w-max" clearable>
            <el-option :label="'正常履约'" :value="1"></el-option>
            <el-option :label="'合同结束'" :value="2"></el-option>
            <el-option :label="'合同终止'" :value="3"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="村庄" prop="villageId">
          <el-cascader :options="cityList" v-model="form.villageId" class="r-w-max"
            :props="{ label: 'name', value: 'id', emitPath: false, checkStrictly: true }" filterable></el-cascader>
        </el-form-item>
        <el-form-item prop="content" label="合同内容">
          <el-input type="textarea" v-model="form.content" placeholder="请输入备注" :autosize="{ minRows: 6 }"></el-input>
        </el-form-item>
        <el-form-item prop="files" label="附件">
          <multiUploadFile v-model="form.annex" @change="checkFile"></multiUploadFile>
        </el-form-item>
        <el-form-item prop="amount" label="合同总金额">
          <el-input type="number" v-model="form.amount" placeholder="请输入合同总金额" :disabled="form.isNotAmount == 1"
            style="width: 200px;"></el-input>
          <el-checkbox v-model="form.isNotAmount" :disabled="isEdit" :true-label="1" :false-label="0"
            style="margin-left: 10px;">不涉及金额</el-checkbox>
        </el-form-item>
        <el-form-item prop="paymentMethod" label="付款方式">
          <el-select v-model="form.paymentMethod" filterable :loading="pidLoading" class="r-w-max" clearable>
            <el-option :label="'年付'" :value="1"></el-option>
            <el-option :label="'月付'" :value="2"></el-option>
            <el-option :label="'季付'" :value="3"></el-option>
            <el-option :label="'一次性结算'" :value="3"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item prop="employee" label="可见范围">
          <r-tag-select v-model="form.employee" labelKey="name" :placeholder="`请选择可见范围`" max
            @focus="handleEmployee('checkbox', '请选择可见范围', 'employee')" valueKey="id"
            :collapseTags="form.employee.length > 6"></r-tag-select>
        </el-form-item>
      </el-form>
    </div>
    <dep-role-modal :config="modalConfig" v-model="dialogVisible" @confirm="handleConfirm"></dep-role-modal>
    <update-party ref="updateParty"></update-party>
  </r-drawer>
</template>
<script>
import DepRoleModal from "@/components/dialog/DepRoleModal";
import { cloneDeep, isArray } from "@/utils";
import { contractDetail, contractPartyList, contractSave, contractTypeList, contractTypeSave } from "@/api/contract";
import multiUploadFile from "@/components/imageUpload/multiUploadFile";
import { getCityList } from "@/api/common";
import updateParty from "./update-party";
const defaultForm = {
  title: '',
  isLongTerm: 0,
  isNotAmount: 0,
  //可见范围人员
  employee: [],
  //附件
  annex: [],
  // 
  partyAContact: '',
  partyBContact: '',
}
export default {
  components: { DepRoleModal, multiUploadFile,updateParty },
  data() {
    return {
      form: cloneDeep(defaultForm),
      title: '',
      rules: {
        title: [
          { required: true, message: '请输入合同名称', trigger: ['change', 'blur'] }
        ],
        type: [
          { required: true, message: '请选择合同类型', trigger: ['change', 'blur'] }
        ],
        partyA: [
          { required: true, message: '请选择甲方', trigger: ['change', 'blur'] }
        ],
        // partyAContact:[
        //     { required: true, message: '请输入甲方联系方式', trigger: ['change','blur'] }
        // ],
        partyB: [
          { required: true, message: '请选择乙方', trigger: ['change', 'blur'] }
        ],
        // partyBContact:[
        //     { required: true, message: '请选择乙方联系方式', trigger: ['change','blur'] }
        // ],
        date: [
          { required: true, message: '请选择签订日期', trigger: ['change', 'blur'] }
        ],
        startDate: [
          { required: true, message: '请选择开始日期', trigger: ['change', 'blur'] }
        ],
        status: [
          { required: true, message: '请选择合同状态', trigger: ['change', 'blur'] }
        ],
        villageId: [
          { required: true, message: '请选择村', trigger: ['change', 'blur'] }
        ],
        paymentMethod: [
          { required: true, message: '请选择付款方式', trigger: ['change', 'blur'] }
        ],
        employee: [
          { required: true, message: '请选择可见范围', trigger: ['change', 'blur'] }
        ],
      },
      modalConfig: {},
      isEdit: false,
      pidLoading: false,
      dialogVisible: false,
      dialogLoading: false,
      contractTypes: [],
      partyAList: [],
      partyBList: [],
      cityList: [],
    }
  },
  methods: {
    open(form = null, title = '新建合同') {
      this.title = title;
      if (form) {
        this.form = Object.assign(cloneDeep(defaultForm), form);
      }
      this.remoteMethod();
      this.remoteMethodPartyA();
      this.remoteMethodPartyB();
      this.getCityList();
      this.$refs.rDialog.handleOpen();
      if (this.form.id) {
        this.dialogLoading = true;
        contractDetail({ id: this.form.id }).then(res => {
          this.form = Object.assign(cloneDeep(defaultForm), res.data);
          if (this.form.status == 0) {
            this.form.status = null;
          }
          if (this.form.payMethod == 0) {
            this.form.payMethod = null;
          }
          this.dialogLoading = false;
        })
      }
    },
    getCityList() {
      getCityList().then(res => {
        this.cityList = res.data;
      })
    },
    reset() {
      this.form = cloneDeep(defaultForm);
    },
    handlePartyA() {
      this.form.partyAContact = this.partyAList.find(item => item.id === this.form.partyA)?.contact || '';
    },
    handlePartyB() {
      this.form.partyBContact = this.partyBList.find(item => item.id === this.form.partyB)?.contact || '';
    },
    submit(done) {
      this.$refs['form'].validate((valid) => {
        if (valid) {
          let form = cloneDeep(this.form);
          if (form.isLongTerm == 1) {
            form.period = null;
          }
          if (form.isNotAmount == 1) {
            form.amount = null;
          }
          contractSave(form).then(res => {
            this.$message.success('提交成功');
            done(true);
            this.$emit('confirm');
          })
            .catch(err => {
              done(false);
            })
        } else {
          done(false);
        }
      });
    },
    cChange(val) {
      console.log(val)
    },
    remoteMethod(name) {
      this.pidLoading = true;
      contractTypeList({ keyword: name, page: 1, pageSize: 20 }).then(res => {
        this.contractTypes = res.data.list;
        if (this.contractTypes.findIndex(item => item.id === this.form.type) === -1 && this.form.type) {
          this.contractTypes.unshift({ id: this.form.type, title: this.form.typeName });
        }
      })
        .catch(err => {
          console.log(err)
        })
        .finally(() => {
          this.pidLoading = false;
        })
    },
    remoteMethodPartyA(name) {
      this.pidLoading = true;
      contractPartyList({ keyword: name, page: 1, pageSize: 20 }).then(res => {
        this.partyAList = res.data.list;
        if (this.partyAList.findIndex(item => item.id === this.form.partyA) === -1 && this.form.partyA) {
          this.partyAList.unshift({ id: this.form.partyA, title: this.form.partyAName });
        }
      })
        .catch(err => {
          console.log(err)
        })
        .finally(() => {
          this.pidLoading = false;
        })
    },
    remoteMethodPartyB(name) {
      this.pidLoading = true;
      contractPartyList({ keyword: name, page: 1, pageSize: 20 }).then(res => {
        this.partyBList = res.data.list;
        if (this.partyBList.findIndex(item => item.id === this.form.partyB) === -1 && this.form.partyB) {
          this.partyBList.unshift({ id: this.form.partyB, title: this.form.partyBName });
        }
      })
        .catch(err => {
          console.log(err)
        })
        .finally(() => {
          this.pidLoading = false;
        })
    },
    handleEmployee(selectType, title, model) {
      this.modalConfig = {
        type: 1,
        selectType,
        title,
        checkStrictly: true,
        model,
        checkList: this.form[model] || [],
        isStaff: 1,
        onlyStaff: 1
      };
      this.dialogVisible = true;
    },
    handleConfirm(val) {
      this.form[this.modalConfig['model']] = val;
      this.dialogVisible = false;
    },
    checkFile() {
      this.$refs.form.validateField('annex')
    },
    addParty(type){
      this.$refs.updateParty.open();
    }
  }
}
</script>

<style lang="scss" scoped>
.dialog-content {
  height: 250px;
}
</style>