<template>
    <div class="preview-cover r-flex r-row-center">
        <div class="wap" id="printable">
            <div @click="print" class="print-btn">打 印</div>
            <div class="hd">
                <div class="title">{{ title }}</div>
                <div class="date">发布时间：{{ date }}</div>
            </div>
            <div class="content content-wap" v-if="type == 'mobile'">{{ content }}</div>
            <div class="content" v-else-if="type == 'pc'" v-html="content"></div>
            <div class="thumb-cover" v-if="imgList.length > 0">
                <img :src="item | $staticFilter" alt="" v-for="item in imgList" :key="item">
            </div>
        </div>
    </div>
</template>
<script>
import moment from 'moment';
import DataStorage from '@/utils/dataStorage';
export default {
    data() {
        return {
            dialogTitle: '预览',
            form: {}
        }
    },
    computed: {
        type() {
            return this.form?.type;
        },
        content() {
            return this.form?.content || '';
        },
        title() {
            return this.form?.title || '';
        },
        imgList() {
            return this.form?.imgList || [];
        },
        date() {
            return moment().format('YYYY-MM-DD HH:mm');
        }
    },
    methods: {
        reset() {
            this.form = {};
        },
        print() {
            window.print();
        },
        init(){
            this.form = DataStorage.StorageGetter('previewForm') || {};
        }
    },
    created(){
        this.init();
    }



}
</script>

<style lang="scss">

.preview-cover {
    width: 100%;
    .print-btn {
        position: fixed;
        width: 60px;
        height: 60px;
        line-height: 60px;
        left:50%;
        transform: translateX(250px);
        top: 20%;
        background: var(--primary);
        color: #fff;
        text-align: center;
        border-radius: 50%;
        cursor: pointer;
        transition: opacity .3s;

        &:hover {
            opacity: .7;
        }
    }

    .wap {
        padding: 0 15px 20px;
        width: 440px;
        border: 1px solid #f5f5f5;
        border-radius: 10px;
        overflow: hidden;
        height: auto;
        box-shadow: 0 5px 16px rgba(0, 0, 0, .16);
        margin: 20px auto;

        .hd {
            padding: 10px 0;
            border-bottom: 1px solid #e5e5e5;
        }

        .date {
            padding-top: 10px;
            font-size: 13px;
            color: #999999;
        }

        .title {
            font-size: 17px;
            font-weight: bold;
        }

        .content {
            font-size: 14px;
            padding: 10px 0;
            &.content-wap{
                white-space: pre-wrap;
            }
            img {
                max-width: 100%;
                height: auto !important;
            }
        }

        .thumb-cover {
            padding-top:10px;
            img {
                display: block;
                margin-bottom: 10px;
            }

            img {
                max-width: 100%;
                height: auto !important;
            }
        }
    }
}
</style>
