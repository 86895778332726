<template>
<div class="t-form-input">
    <el-input :value="label" :placeholder="placeholder" disabled></el-input>
</div>
</template>
<script>
import {guid} from '@/utils'
export default {
 props:{
    value:{
        default:''
    },
    formData:{
        type:Object,
        default:()=>({})
    }
 },
 data(){
     return{   
        ref:guid()
     }
 },
 computed:{
    label(){
        return this.value.label || '自动获取...'
    },
    placeholder(){
        return this.formData?.placeholder || '请输入'
    }
 }



}
</script>

<style lang="scss" scoped>

</style>