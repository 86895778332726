<template>
<div class="form-phone-wrapper">
    <el-input v-model="inputValue" :placeholder="placeholder" @blur="check" :ref="ref"  @change="suiteDataCheck"></el-input>
</div>
</template>
<script>
import {isPhone,guid} from "@/utils"
export default {
    inject: ['root'],
    props:{
        value:{
            default:''
        },
        formData:{
            type:Object,
            default:()=>({})
        }
    },
    data(){
        return{
            ref:guid()
        }
    },
    computed:{
        inputValue:{
            get(){
                return this.value;
            },
            set(val){
                this.$emit('input',val);
            }
        },
        placeholder(){
            return this.formData?.placeholder || '请输入'
        }
    },
    methods:{
        suiteDataCheck(){
            this.root.suiteDataCheck && this.root.suiteDataCheck(this.formData);
        },
        //检查格式
        check(){
            if(!isPhone(this.value)){
                this.$message.error(`${this.formData.config?.label || '手机号码'}格式错误`)
                this.$refs[this.ref].focus();
            }
        }
    }
}
</script>

<style lang="scss" scoped></style>