/**
 * 工作流mixin
 */
import {getValues} from "@/utils/arr";

export default {
  data() {
    return {
      workFlowData: [],
      msgTxt: '',
      nodeId: '',
      isBreak: 0,
      processControl: {}
    }
  },
  watch: {
    formList: {
      handler: function (newVal, oldVal) {
        if (this.lock) return;
        this.throttle({
          key: 'formListChange',
          time: 400,
          callback: () => {
            this.formDataChange(newVal)
          }
        })
      },
      deep: true
    }
  },
  methods: {
    getWorkflowTips(data) {
      let num = data.nodeUserList.length
      //审批人
      if (data.type === 1) {
        if (((data.noHanderAction === 1 || data.noHanderAction === 2) && num === 0)) {
          return '请选择' + data.nodeName;
        }
        if (data.examineMode === 1) {
          return num > 1 ? num + '人依次审批' : num + '人审批'
        } else if (data.examineMode === 2) {
          return num > 1 ? num + '人会签' : num + '人审批'
        } else if (data.examineMode === 3) {
          return num > 1 ? num + '人或签' : num + '人审批'
        }
      } else if (data.type === 5) {
        if (((data.noHanderAction === 1 || data.noHanderAction === 2) && num === 0)) {
          return '请选择' + data.nodeName;
        }
        if (data.examineMode === 1) {
          return num > 1 ? num + '人依次执行' : num + '人执行'
        } else if (data.examineMode === 2) {
          return num > 1 ? num + '人会签' : num + '人执行'
        } else if (data.examineMode === 3) {
          return num > 1 ? num + '人或签' : num + '人执行'
        }
      } else if (data.type === 2) {
        if (data.ccSelfSelectFlag === 1) {
          if (num > 0) {
            return '抄送' + num + '人'
          }
          return '请选择' + data.nodeName
        }
        return '抄送' + num + '人'
      }

      return ''
    },
    //删除节点用户
    deleteNodeUser(index, idx) {
      this.workCheckData[index].nodeUserList.splice(idx, 1)
    },
    //确认添加人员
    handleConfirm(val) {
      if (this.flowActiveItem) {
        this.flowActiveItem.nodeUserList = val;
      }
      this.dialogVisible = false;
    },
    //定制添加人员
    customHandleConfirm(val) {
      if (this.flowActiveItem) {
        this.flowActiveItem.nodeUserList = val;
      }
      this.customDialogVisible = false;
    },
    //添加人员
    appendUser(item) {
      //发起人自选
      if (item.setType === 4 && (item.selectRange === 2 || item.selectRange === 3)) {
        this.flowActiveItem = item;
        this.customModalConfig = {
          type: 1,
          selectType: item.selectMode === 1 ? 'radio' : 'checkbox',
          title: '选择人员',
          isStaff: 1,
          checkStrictly: true,
          model: '',
          checkList: item.nodeUserList,
          treeData: item.defaultUserList.map(el => {
            return {
              name: el.name,
              id: el.employeeId,
              avatar: el.avatar,
              userId:el.userId
            }
          })
        };
        this.customDialogVisible = true;
      } else {
        if (item.ccSelfSelectFlag === 1) {
          let defaultUserId = getValues(item.defaultUserList, 'employeeId');
          item.nodeUserList = item.nodeUserList.map(el => {
            el.label = el.name;
            el.value = el.id;
            el.disabled = false;
            if (defaultUserId.indexOf(el.id) > -1) {
              el.disabled = true;
            }
            return el
          })
        } else {
          item.nodeUserList = item.nodeUserList.map(el => {
            el.label = el.name;
            el.value = el.id;
            return el;
          })
        }
        this.flowActiveItem = item;
        this.modalConfig = {
          type: 1,
          selectType: 'checkbox',
          title: '选择人员',
          isStaff: 1,
          onlyStaff:true,
          checkStrictly: true,
          model: '',
          checkList: item.nodeUserList,

        };
        this.dialogVisible = true;
        // this.$nextTick(() => {
        //     uni.$once('selectEmployee', (res) => {
        //         //清空选择
        //         uni.$off('selectEmployee');
        //         if (res.code === 500) return;
        //         //抄送
        //         item.nodeUserList = res.data.map(el => {
        //             return {
        //                 name: el.nickname || el.label,
        //                 id: el.userId || el.value,
        //                 avatar: el.avatar
        //             }
        //         })
        //     });
        // })
      }
    }
  },
}
