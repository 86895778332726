<template>
    <div class="kpi-wrapper r-page" @click="popCoverHide">
        <div class="r-filters">
            <div class="r-search">
                <el-form size="small" inline @submit.native.prevent>
                    <el-form-item>
                        <el-input placeholder="请输入问题名称关键词" v-model="query.keyword" @keydown.enter.native="search"
                            clearable></el-input>
                    </el-form-item>
                    <el-form-item>
                        <el-cascader v-model="query.villageId" :options="cityList" placeholder="请选择村庄"  style="width:380px"
                        :props="{ emitPath: false, value: 'id', label: 'name', checkStrictly: true,multiple: true }" :collapse-tags="true" @change="search" filterable></el-cascader>
                    </el-form-item>
                    <el-form-item>
                        <el-button type="primary" @click="search" icon="el-icon-search">搜索</el-button>
                    </el-form-item>      
                    <el-form-item>
                        <el-select v-model="query.status" @change="search">
                            <el-option v-for="item in statusList" :key="item.value" :label="item.label" :value="item.value"></el-option>
                        </el-select>
                    </el-form-item>
                </el-form>
            </div>
            <div class="r-toolbar">
                <el-form inline size="small">
                    <el-form-item style="margin-right:0">
                        <div class="r-flex">
                            <el-popover placement="bottom-end" width="400" trigger="manual" v-model="popShow"
                                @click.stop.native>
                                <div>
                                    <div class="r-pb-20">筛选条件</div>
                                    <el-form size="small">
                                        <el-form-item>
                                            <r-tag-select v-model="searchQuery.employee" class="r-w-max"
                                                :collapseTags="true" placeholder="请选择负责人"
                                                @focus="handleEmployee('checkbox', '请选择负责人', 'employee')"
                                                labelKey="name"></r-tag-select>
                                        </el-form-item>
                                        <el-form-item>
                                            <r-tag-select v-model="searchQuery.department" class="r-w-max"
                                                :collapseTags="true" placeholder="请选择负责部门"
                                                @focus="handleChangeSelect('checkbox', '请选择负责部门', 'department')"
                                                labelKey="name"></r-tag-select>
                                        </el-form-item>
                                        <el-form-item>
                                            <div class="r-flex">
                                                <el-date-picker class="r-w-max" v-model="searchQuery.date" type="daterange" range-separator="至"
                                                    value-format="yyyy-MM-dd" start-placeholder="计划开始日期" end-placeholder="计划结束日期">
                                                </el-date-picker>
                                            </div>
                                        </el-form-item>
                                        <el-form-item>
                                            <div class="r-flex r-row-right">
                                                <el-button @click="popHide">取消</el-button>
                                                <el-button type="primary" @click="searchConfirm">确认</el-button>
                                            </div>
                                        </el-form-item>
                                    </el-form>
                                </div>
                                <el-button slot="reference" class="sx-btn" @click="popOpen"
                                    :class="{ active: searchActive }">
                                    <r-icon name="shaixuan" type="task" size="16"></r-icon>
                                </el-button>
                            </el-popover>
                        </div>
                    </el-form-item>
                </el-form>
            </div>
        </div>
        <div class="r-content">
            <r-table ref="table" :loader="loader" :columns="columns" height="100%"
                :cell-class-name="cellClassName" @cell-click="detailOpen" row-key="id" @sortChange="sortChange">
                <template v-slot:statusStr="{ row }">
                    <span :style="{color:statusColors[row.status]}">{{ row.statusStr }}</span>
                </template>

                <template v-slot:action="{row}">
                  <el-popconfirm v-if="row.enableDelete == 1" title="确定要删除该问题吗？" @confirm="del(row)">
                    <el-link type="primary" slot="reference">删除</el-link>
                  </el-popconfirm>
                </template>
            </r-table>
        </div>
        <dep-role-modal :config="modalConfig" v-model="dialogVisible" @confirm="handleConfirm"></dep-role-modal>
        <detail ref="detail" @update="search"></detail>
    </div>
</template>
<script>
import {approveList, approveStatusList, questionDelete} from "@/api/question";
import { getCityList } from "@/api/common";
import DepRoleModal from "@/components/dialog/DepRoleModal";
import {  cloneDeep, isObjectNone } from "@/utils";
import detail from "./components/detail";
const defaultQuery = {
    keyword: '',
    //负责人
    employee: [],
    //负责部门
    department: [],
    orderBy: '',
    orderBySort: '',
    date: ['', ''],
    status:1,
    villageId:[]
}
export default {
    components: { DepRoleModal,detail},
    data() {
        return {
            cityList:[],
            modalConfig: {},
            dialogVisible: false,
            query: cloneDeep(defaultQuery),
            searchQuery: cloneDeep(defaultQuery),
            popShow: false,
            columns:[
                {
                    label:'问题名称',
                    prop:'title',
                    'min-width':'300px'
                },
                {
                    label:'状态',
                    prop:'statusStr',
                    'width':'100px'
                },
                {
                    label:'发现人员',
                    prop:'employeeName',
                    'min-width':'120px'
                },
                {
                    label:'创建时间',
                    prop:'createdAt',
                    'min-width':'160px'
                },{
                    label:'村庄',
                    prop:'villageName',
                    'min-width':'120px'
                },
                {
                    label:'负责部门',
                    prop:'responsibleDepartmentName',
                    'min-width':'150px'
                },
                {
                    label:'负责人',
                    prop:'responsibleEmployeeName',
                    'min-width':'130px'
                },
                {
                    label:'整改开始时间',
                    prop:'startTime',
                    'min-width':'130px',
                    sortable: 'custom'
                },
                {
                    label:'整改结束时间',
                    prop:'endTime',
                    'min-width':'130px',
                    sortable: 'custom'
                },
                {
                    label:'问题扣分',
                    prop:'deductScore',
                    'width':'100px'
                },
                {
                    label:'整改得分',
                    prop:'obtainScore',
                    'width':'100px'
                },
                {
                    label:'合计分数',
                    prop:'score',
                    'width':'100px'
                },
                {
                  label:'操作',
                  prop:'action',
                  'width':'130px',
                  fixed:'right'
                }
            ],
            statusColors:{
                1:'#AEAEAE',
                2:'#1D7FFE',
                3:'#1D7FFE',
                4:'#E48000',
                5:'#1E7E00',
                6:'#FF0000'
            },
            statusList:[]
        }
    },
    computed: {
        searchActive() {
            return this.query.employee.length > 0 || this.query.department.length > 0 || this.query.date[0] || this.query.date[1];
        }
    },
    methods: {
        del(row){
          questionDelete({id:row.id}).then(res=>{
            this.$message.success('删除成功');
            this.refresh();
          })
        },
        loader(page, pageSize) {
            let form = this.getFormData();
            return approveList({ page, pageSize, ...form }).then(res => {
                return res.data;
            })
        },
        refresh(page) {
            this.$refs.table.refresh(page || '');
        },
        search() {
            this.$refs.table.refresh(1);
        },
        handleChangeSelect(selectType, title, model) {
            this.modalConfig = {
                type: 1,
                selectType,
                title,
                checkStrictly: true,
                model,
                checkList: this.searchQuery[model] || [],
            };
            this.dialogVisible = true;
        },
        handleEmployee(selectType, title, model) {
            this.popLock = true;
            this.modalConfig = {
                type: 1,
                selectType,
                title,
                checkStrictly: true,
                model,
                checkList: this.searchQuery[model] || [],
                isStaff: 1,
                onlyStaff: 1
            };
            this.dialogVisible = true;
        },
        handleConfirm(val) {
            this.searchQuery[this.modalConfig['model']] = val;
            this.dialogVisible = false;
        },
        cellClassName({ row, column }) {
            return  column.property == 'title' ? 'hover--opacity' : '';
        },
        detailOpen(row, column) {
            if(column.property != 'title') return;
            this.$refs.detail.open(row,true);
        },
        sortChange(order) {
            this.query = Object.assign(this.query, order);
            this.$nextTick(() => {
                this.search();
            })
        },
        detailClose(isUpdate = false) {
            if (isUpdate) {
                this.refresh();
            }
        },
        tableRefresh() {
            this.$refs.table.columnsRefresh();
        },
        popOpen() {
            this.popShow = !this.popShow;
            if (this.popShow) {
                this.searchQuery = cloneDeep(this.query);
            }
        },
        popHide() {
            this.popShow = false;
        },
        popCoverHide() {
            this.popHide();
        },
        searchConfirm() {
            this.popHide();
            if(!this.searchQuery.date){
                this.searchQuery.date = ['',''];
            }
            this.query = cloneDeep(this.searchQuery);
            this.search();
        },
        getFormData(){
            let form = cloneDeep(this.query);
            form.completedDate = form.date[0] ? form.date : [];
            form.responsibleDepartmentId = form.department.map(el => el.id);
            form.responsibleEmployeeId = form.employee.map(el => el.id);
            return form;
        },
        approveStatusList(){
            approveStatusList().then(res=>{
                this.statusList = res.data.map(el=>{
                    return {
                        label:el.label,
                        value:el.status
                    }
                });
            })
        },
        getCityList() {
            getCityList().then(res => {
                this.cityList = res.data;
            })
        },
    },
    created() {
        if (!isObjectNone(this.$route.params)) {
            this.query = Object.assign(this.query, this.$route.params);
        }
        this.approveStatusList();
        this.getCityList();
    }

}
</script>

<style lang="scss" scoped>
.kpi-wrapper {
    .item-menu {
        opacity: 0;
        transition: opacity .2s;
        padding: 0px 5px;
        color: var(--primary);
    }

    .sx-btn {
        padding: 7px 15px;

        &.active {
            border-color: var(--primary);
            color: var(--primary);
        }
    }

    .table-expend {
        &.menu-show {
            .item-menu {
                opacity: 1;
            }
        }

        &:hover {
            .item-menu {
                opacity: 1;
            }
        }
    }

    .count-wrapper {
        font-size: 13px;
    }
}
</style>