<template>
    <div class="village">
        <el-input disabled placeholder="请选择村庄"></el-input>
    </div>
    </template>
    <script>
    
    export default {
    
        name:'village'
    
    
    }
    </script>
    
    <style lang="scss" scoped>
    </style>