<template>
  <div class="kpi-wrapper r-page" @click="popCoverHide">
    <div class="r-filters">
      <div class="r-search">
        <el-form size="small" inline @submit.native.prevent>
          <el-form-item>
            <el-input placeholder="请输入问题名称关键词" v-model="query.keyword" @keydown.enter.native="search"
              clearable></el-input>
          </el-form-item>
          <el-form-item label="时间区间">
            <el-date-picker
            @change="search"
            v-model="query.createdAt"
            type="daterange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            value-format="yyyy-MM-dd">
          </el-date-picker>
          </el-form-item> 
          <el-form-item label="文章分类">
            <el-select v-model="query.categoryId" :clearable="true" @change="search">
              <el-option v-for="item in categoryList" :key="item.id" :value="item.id" :label="item.name"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="可见部门">
            <r-tag-select v-model="query.departmentIds" :collapseTags="true" placeholder="请选择可见部门"
              @focus="handleChangeSelect('checkbox', '请选择可见部门', 'departmentIds')" labelKey="name"
              @remove-tag="search"></r-tag-select>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="search" icon="el-icon-search">搜索</el-button>
            <el-button type="primary" @click="handleAdd" icon="el-icon-plus">新增</el-button>
          </el-form-item>
        </el-form>
      </div>
    </div>
    <div class="r-content">
      <r-table ref="table" :loader="loader" :columns="tableColumns" height="100%" row-key="id" @sortChange="sortChange">
        <template v-slot:status="{ row }">
          <el-tag v-if="row.status == 1" size="mini">开启</el-tag>
          <el-tag v-if="row.status == 0" type="danger" size="mini">关闭</el-tag>
        </template>
        <template v-slot:isBanner="{ row }">
          <el-tag v-if="row.isBanner == 1" size="mini">是</el-tag>
          <el-tag v-if="row.isBanner == 0" type="danger" size="mini">否</el-tag>
        </template>   
        <template v-slot:type="{ row }">
          <el-tag v-if="row.type == 'mobile'" size="mini" type="warning">移动端</el-tag>
          <el-tag v-else type="success" size="mini">电脑端</el-tag>
        </template>
        <template v-slot:handle="{ row }">
          <el-button type="text" @click="handleEdit(row, '编辑文章')">编辑</el-button>
          <el-divider direction="vertical" v-if="row.enableDelete == 1"></el-divider>
          <el-popconfirm title="确定要删除该任务吗？" @confirm="handleDel(row)" v-if="row.enableDelete == 1">
            <el-link type="primary" slot="reference">删除</el-link>
          </el-popconfirm>
        </template>
      </r-table>
    </div>
    <dep-role-modal :config="modalConfig" v-model="dialogVisible" @confirm="handleConfirm"></dep-role-modal>
    <UpdateArticle ref="updateArticleForm" @confirm="tableRefresh"></UpdateArticle>
  </div>
</template>
<script>
import { del, list, categoryList } from "@/api/article";
import DepRoleModal from "@/components/dialog/DepRoleModal";
import { cloneDeep, isObjectNone } from "@/utils";
import UpdateArticle from "./components/update-article";
import department from "@/router/modules/department";

const defaultQuery = {
  keyword: '',
  departmentIds: [],
  categoryId: '',
  createdAt: ['', '']
}
export default {
  components: { DepRoleModal, UpdateArticle },
  data() {
    return {
      modalConfig: {},
      dialogVisible: false,
      query: cloneDeep(defaultQuery),
      searchQuery: cloneDeep(defaultQuery),
      popShow: false,
      columns: [
        {
          label: '文章标题',
          prop: 'title',
          'min-width': '300px'
        }, {
          label: '文章分类',
          prop: 'category.name',
          'width': '200px'
        },
        {
          label: '是否为banner',
          prop: 'isBanner',
          'width': '150px'
        },
        {
          label: '发布端',
          prop: 'type',
          'width': '100px'
        },{
          label: '创建时间',
          prop: 'createdAt',
          'width': '160px'
        },
        {
          label: '排序',
          prop: 'sort',
          'width': '100px'
        },
        {
          label: '状态',
          prop: 'status',
          'width': '100px'
        }
      ],
      statusColors: {
        1: '#AEAEAE',
        2: '#1D7FFE',
        3: '#1D7FFE',
        4: '#E48000',
        5: '#1E7E00',
        6: '#FF0000'
      },
      statusList: [],
      categoryList: []
    }
  },
  computed: {
    searchActive() {
      return this.query.employee.length > 0 || this.query.department.length > 0 || this.query.date[0] || this.query.date[1];
    },
    tableColumns() {
      const columns = this.columns.map((col, index) => {
        return {
          ...col,
          showOverflowTooltip: true,
          filterMethod: col.filters ? this.filterMethod : null,
        };
      });
      columns.push({
        label: "操作",
        type: "handle",
        prop: "handle",
        width: "160px",
        fixed: "right",
      });
      return columns;
    },
  },
  methods: {
    loader(page, pageSize) {
      let form = this.getFormData();
      return list({ page, pageSize, ...form }).then(res => {
        return res.data;
      })
    },
    refresh(page) {
      this.$refs.table.refresh(page || '');
    },
    search() {
      this.$refs.table.refresh(1);
    },
    handleChangeSelect(selectType, title, model) {
      this.modalConfig = {
        type: 1,
        selectType,
        title,
        checkStrictly: true,
        model,
        checkList: this.query[model] || [],
      };
      this.dialogVisible = true;
    },
    handleEmployee(selectType, title, model) {
      this.popLock = true;
      this.modalConfig = {
        type: 1,
        selectType,
        title,
        checkStrictly: true,
        model,
        checkList: this.searchQuery[model] || [],
        isStaff: 1,
        onlyStaff: 1
      };
      this.dialogVisible = true;
    },
    handleConfirm(val) {
      this.query[this.modalConfig['model']] = val;
      this.dialogVisible = false;
      this.search();
    },
    cellClassName({ row, column }) {
      return column.property == 'title' ? 'hover--opacity' : '';
    },
    detailOpen(row, column) {
      if (column.property != 'title') return;
      this.$refs.detail.open(row, true);
    },
    sortChange(order) {
      this.query = Object.assign(this.query, order);
      this.$nextTick(() => {
        this.search();
      })
    },
    detailClose(isUpdate = false) {
      if (isUpdate) {
        this.refresh();
      }
    },
    tableRefresh() {
      this.$refs.table.columnsRefresh();
    },
    popOpen() {
      this.popShow = !this.popShow;
      if (this.popShow) {
        this.searchQuery = cloneDeep(this.query);
      }
    },
    popHide() {
      this.popShow = false;
    },
    popCoverHide() {
      this.popHide();
    },
    searchConfirm() {
      this.popHide();
      this.query = cloneDeep(this.searchQuery);
      this.search();
    },
    getFormData() {
      let form = cloneDeep(this.query);
      form.createdAt = form.createdAt && form.createdAt[0] ? form.createdAt : '';
      form.departmentIds = form.departmentIds.map(el => el.id);
      return form;
    },
    handleAdd() {
      this.$refs.updateArticleForm.open({
        department: [{ depId: this.$store.state.userInfo.employee.department, depName: this.$store.state.userInfo.employee.departmentName, id: this.$store.state.userInfo.employee.department, name: this.$store.state.userInfo.employee.departmentName }]
      }, '新建文章')
    },
    handleEdit(row, title) {
      let item = cloneDeep(row);
      item.imgList = item.imgList ? JSON.parse(item.imgList) : [];
      item.imgList = item.imgList.map(el=>el.src);
      this.$refs.updateArticleForm.open(item, title)
    },
    handleDel(row) {
      del({ id: row.id }).then(res => {
        this.$message.success('删除成功');
        this.$refs.table.refresh();
      })
    },
    getCategoryList() {
      return categoryList({ page: 1, pageSize: 999 })
        .then(res => {
          this.categoryList = res.data.list;
        })
    }
  },
  created() {
    if (!isObjectNone(this.$route.params)) {
      this.query = Object.assign(this.query, this.$route.params);
    }

    this.getCategoryList();
  }

}
</script>

<style lang="scss" scoped>
.kpi-wrapper {
  .item-menu {
    opacity: 0;
    transition: opacity .2s;
    padding: 0px 5px;
    color: var(--primary);
  }

  .sx-btn {
    padding: 7px 15px;

    &.active {
      border-color: var(--primary);
      color: var(--primary);
    }
  }

  .table-expend {
    &.menu-show {
      .item-menu {
        opacity: 1;
      }
    }

    &:hover {
      .item-menu {
        opacity: 1;
      }
    }
  }

  .count-wrapper {
    font-size: 13px;
  }
}
</style>