<template>
<div class="form-edit">
    <div class="form-edit-content" v-html="defaultValue"></div>
</div>
</template>
<script>

export default {
    props:['value','formData'],
    inject: ['root'],
    computed:{
        defaultValue(){
            return this.formData.config?.expandParams.content || '';
        }
    }
}
</script>

<style lang="scss" scoped>
    ::v-deep .form-edit{
        &-content{
            video,img{
                max-width: 100%;
            }
        }
    }
</style>