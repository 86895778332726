<template>
    <div
      class="el-select"
      :class="[selectSize ? 'el-select--' + selectSize : '',max ? 'max' : '']"
      @click.stop="toggleMenu"
      v-clickoutside="handleClose">
      <div
        class="el-select__tags"
        ref="tags"
        :style="{ 'max-width': inputWidth - 32 + 'px', width: '100%' }"
        :class="{'auto-size':autoSize}">
        <span v-if="collapseTags && selected.length">
          <el-tag
            :closable="closable && !selectDisabled"
            :size="collapseTagSize"
            :hit="selected[0].hitState"
            type="info"
            @close="deleteTag($event, selected[0])"
            disable-transitions>
            <span class="el-select__tags-text">{{ selected[0].value[labelKey] }}</span>
          </el-tag>
          <el-tag
            v-if="selected.length > 1"
            :closable="false"
            :size="collapseTagSize"
            type="info"
            disable-transitions>
            <span class="el-select__tags-text">+ {{ selected.length - 1 }}</span>
          </el-tag>
        </span>
        <transition-group @after-leave="resetInputHeight" v-if="!collapseTags">
          <el-tag
            v-for="item in selected"
            :key="getValueKey(item)"
            :closable="closable && !selectDisabled"
            :size="collapseTagSize"
            :hit="item.hitState"
            type="info"
            @close="deleteTag($event, item)"
            disable-transitions>
            <span class="el-select__tags-text">{{ item.value[labelKey] }}</span>
          </el-tag>
        </transition-group>
        <input
        
        type="text"
        class="el-select__input"
        :class="[selectSize ? `is-${ selectSize }` : '']"
        disabled
        :autocomplete="autoComplete || autocomplete"
        @focus="handleFocus"
        @blur="softFocus = false"
        @keyup="managePlaceholder"
        @keydown="resetInputState"
        @keydown.down.prevent="handleNavigate('next')"
        @keydown.up.prevent="handleNavigate('prev')"
        @keydown.enter.prevent="selectOption"
        @keydown.esc.stop.prevent="visible = false"
        @keydown.delete="deletePrevTag"
        @keydown.tab="visible = false"
        @compositionstart="handleComposition"
        @compositionupdate="handleComposition"
        @compositionend="handleComposition"
        v-model="query"
        @input="debouncedQueryChange"
        v-if="filterable"
        :style="{ 'flex-grow': '1', width: inputLength / (inputWidth - 32) + '%', 'max-width': inputWidth - 42 + 'px' }"
        ref="input">
      </div>
      <el-input
      ref="reference"
      v-model="selectedLabel"
      type="text"
      :placeholder="currentPlaceholder"
      :name="name"
      :id="id"
      :autocomplete="autoComplete || autocomplete"
      :size="selectSize"
      :disabled="selectDisabled"
      :readonly="readonly"
      :validate-event="false"
      :class="{ 'is-focus': visible }"
      :tabindex="(multiple && filterable) ? '-1' : null"
      @focus="handleFocus"
      @blur="handleBlur"
      @input="debouncedOnInputChange"
      @keydown.native.down.stop.prevent="handleNavigate('next')"
      @keydown.native.up.stop.prevent="handleNavigate('prev')"
      @keydown.native.enter.prevent="selectOption"
      @keydown.native.esc.stop.prevent="visible = false"
      @keydown.native.tab="visible = false"
      @compositionstart="handleComposition"
      @compositionupdate="handleComposition"
      @compositionend="handleComposition"
      @mouseenter.native="inputHovering = true"
      @mouseleave.native="inputHovering = false">
      <template slot="prefix" v-if="$slots.prefix">
        <slot name="prefix"></slot>
      </template>
      <template slot="suffix">
        <i v-show="!showClose" :class="['el-select__caret', 'el-input__icon', 'el-icon-' + iconClass]"></i>
        <i v-if="showClose" class="el-select__caret el-input__icon el-icon-circle-close" @click="handleClearClick"></i>
      </template>
    </el-input>
    </div>
  </template>
  
<script>
import {Select} from "element-ui";
import { getValueByPath } from 'element-ui/src/utils/util';
export default {
    extends:Select,
    props:{
      labelKey:{
        type:String,
        default:'label'
      },
      max:{
        type:Boolean,
        default:false
      },
      closable:{
        type:Boolean,
        default:true
      },
      autoSize:{
        type:Boolean,
        default:false
      }
    },
    methods:{
      toggleMenu(){
        this.$emit('focus');
      },
      getValueKey(item) {
        if (Object.prototype.toString.call(item.value).toLowerCase() !== '[object object]') {
          return item.value;
        } else {
          return getValueByPath(item.value, this.valueKey);
        }
      }
    }
}
</script>

<style lang="scss" scoped>
.el-select{
  cursor: pointer;
  .el-select__tags > span{
    display: flex;
    overflow: hidden;
  }
  .el-select__tags.auto-size > span{
    overflow: visible;
    flex-wrap:wrap
  }
  &.max{
    width: 100%;
  }
}
</style>