<template>
    <div class="indicator-detail-sub r-flex r-flex-column">
        <div class="r-flex r-row-bet r-w-max r-pb-10">
            <div>
                <el-select size="mini" v-model="query.status" placeholder="请选择任务状态" class="r-w-max" multiple collapse-tags @change="search">
                        <el-option v-for="item in statusList" :key="item.value" :value="item.value"
                          :label="item.label"></el-option>
                      </el-select>
            </div>
            <div class="r-flex">
                <el-button type="warning" icon="el-icon-plus" size="mini" @click="batchApprove"
                v-if="$has('task-create')">批量新增任务</el-button>
                <el-button type="primary" icon="el-icon-plus" size="mini" @click="addApprove"
                v-if="$has('task-create')">新增任务</el-button>
                <el-button size="mini" type="danger" icon="el-icon-delete" class="r-ml-10" @click="batchDel"
                    v-if="$has('task-delete')" :loading="delLoading">批量删除<span v-if="selection.length">（已选：{{
                        selection.length }}条）</span></el-button>
                <el-button size="mini" type="success" v-if="$has('task-edit')" icon="el-icon-edit-outline" class="r-ml-10"
                    @click="batchEdit" :loading="delLoading">批量修改<span v-if="selection.length">（已选：{{ selection.length
                        }}条）</span></el-button>
            </div>
        </div>
        <div class="r-flex-auto-h r-w-max">
            <r-table ref="table" :loader="loader" height="100%" :columns="columns" @sortChange="sortChange"
                @cell-click="detailOpen" :cell-class-name="cellClassName" @selection-change="selectionChange"
                row-key="id">
                <template v-slot:handle="{ row }">
                    <el-link type="primary" @click="editTask(row)" v-if="$has('task-edit')">编辑</el-link>
                    <el-divider direction="vertical" v-if="$has('task-delete') && $has('task-edit')"></el-divider>
                    <el-popconfirm title="确定要删除该任务吗？" @confirm="delTask(row)">
                        <el-link type="primary" slot="reference" v-if="$has('task-delete')">删除</el-link>
                    </el-popconfirm>
                </template>
            </r-table>
        </div>
        <approveDetail ref="approveDetail" @close="approveUpdate"></approveDetail>
        <taskDetail ref="taskDetail" @close="approveUpdate"></taskDetail>
        <batch-edit ref="batchEdit" @success="batchEditSuccess"></batch-edit>
    </div>
</template>
<script>
import { getTaskList, taskDel, saveWeight,getTaskStatusEnums } from "@/api/approve";
import approveDetail from "@/components/approve/createForm";
import { getDateRange } from "@/utils";
import tableFixed from "@/mixins/tableFixed";
import batchEdit from "@/views/task/components/batch-edit"
export default {
    components: {
        approveDetail,
        taskDetail: () => import('@/components/approve/detail/applyDetailDialog'),
        batchEdit
    },
    mixins: [tableFixed],
    props: {
        data: {
            type: Object,
            default() {
                return {}
            }
        }
    },
    data() {
        return {
            columns: [],
            query: {
                orderBy: '',
                orderBySort: '',
                status:[]
            },
            nameProp: '',
            weightProp: '',
            employeeProp:'',
            delLoading: false,
            selection: [],
            statusList:[]
        }
    },
    methods: {
        loader(page, pageSize) {
            return getTaskList({ page, pageSize, indicatorId: this.data.id, ...this.query }).then(res => {
                this.columns = [{ type: 'selection', 'reserve-selection': true, width: '80px', hide: !this.$has('task-delete') }, ...this.getTableColumn(res.data.tableHeader), { fixed: 'right', 'min-width': '100px', label: '操作', prop: 'handle' }];
                this.tableColumnChange();
                return res.data;
            })
        },
        search(){
            this.$refs.table.refresh(1);
        },
        sortChange(order) {
            this.query = Object.assign(this.query, order);
            this.$nextTick(() => {
                this.$refs.table.refresh(1);
            })
        },
        detailOpen(row, column) {
            if (column.property != this.nameProp && column.property != this.weightProp) return;
            if (column.property == this.nameProp) {
                this.$refs.taskDetail.open(row.id);
            } else if (column.property == this.weightProp) {
                this.$prompt('请输入一个范围为0~100的整数', '权重', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    inputValue: row[this.weightProp],
                    inputValidator: (val) => {
                        let numReg = /^[0-9]+$/g;
                        if (!numReg.test(val)) {
                            return '权重只能是整数'
                        }
                        if (parseInt(val) < 0 || parseInt(val) > 100) {
                            return '权重范围为0~100'
                        }
                        return true;
                    },
                    beforeClose: (action, instance, done) => {
                        if (action === 'confirm') {
                            if (row.weight === parseInt(instance.inputValue)) {
                                this.$message.success('权重未发生更改');
                                done();
                                return;
                            }
                            saveWeight({
                                taskId: row.id,
                                weight: parseInt(instance.inputValue)
                            }).then(() => {
                                this.$message.success('权重修改成功');
                                this.$refs.table.refresh();
                                done();
                            }).catch(() => { })
                        } else {
                            done();
                        }
                    }
                }).then(({ value }) => { }).catch(() => { });
            }
        },
        getTableColumn(list) {
            return list.map(el => {
                if (el.field == 'name') {
                    this.nameProp = el.indicatorTemplateId;
                }
                if (el.field == 'weight') {
                    this.weightProp = el.indicatorTemplateId;
                }
                if(el.field == 'employee'){
                    this.employeeProp = el.indicatorTemplateId;
                }
                return {
                    prop: el.indicatorTemplateId,
                    label: el.label,
                    'min-width': el['min-width']
                }
            })
        },
        cellClassName({ row, column }) {
            return [this.weightProp, this.nameProp].includes(column.property) ? 'hover--opacity' : column.type == 'selection' ? 'r-child-center' : '';
        },
        refresh(page = '') {
            this.$refs.table.refresh(page);
        },
        addApprove() {
            let indicatorName = [...this.data.parentList.map(el => el.name), this.data.name].join('/');
            this.$refs.approveDetail.handleOpen({
                id: 0,
                title: '新建任务',
                indicatorId: this.data.id,
                task: true,
                indicatorName,
                indicatorDate: getDateRange(this.data.startTime, this.data.endTime),
                leader: this.data.leader || [],
                employee: this.data?.employee || [],
                department:this.data?.department || [],
            });
        },  
        batchApprove() {
            let indicatorName = [...this.data.parentList.map(el => el.name), this.data.name].join('/');
            this.$refs.approveDetail.handleOpen({
                id: 0,
                title: '新建任务',
                indicatorId: this.data.id,
                task: true,
                batchTask:true,
                indicatorName,
                indicatorDate: getDateRange(this.data.startTime, this.data.endTime),
                leader: this.data.leader || [],
                employee: this.data?.employee || [],
                department:this.data?.department || [],
            });
        },
        approveUpdate(isUpdate) {
            if (isUpdate) {
                this.$refs.table.refresh();
                this.$emit('refresh', 'detail')
            }
        },
        delTask(row) {
            taskDel({ taskId: row.id }).then(res => {
                this.$message.success('任务删除成功');
                this.$refs.table.refresh();
                this.$emit('refresh', 'detail');
            })
        },
        editTask(row) {
            this.$refs.approveDetail.handleOpen({
                id: row.id,
                title: '编辑任务',
                indicatorId: 0,
                task: true
            });
        },
        batchDel() {
            let num = this.selection.length;
            if (!num) {
                this.$message.error('至少选择一条任务');
                return;
            }
            this.$confirm(`确定要删除当前选中的${num}条任务吗？`, '提示', { type: 'warning' }).then(
                () => {
                    this.delLoading = true;
                    taskDel({ taskId: this.selection.map(el => el.id) }).then(res => {
                        this.selection = [];
                        //清空
                        this.$refs.table?.clearSelection && this.$refs.table.clearSelection();
                        this.$message.success(`已成功删除${num}条记录`);
                        this.$refs.table.refresh(1);
                        this.$emit('refresh', 'detail');
                    })
                        .finally(() => {
                            this.delLoading = false;
                        })
                }
            )
        },
        selectionChange(selection) {
            this.selection = selection;
        },
        batchEditSuccess() {
            this.selection = [];
            //清空
            this.$refs.table?.clearSelection && this.$refs.table.clearSelection();
            this.$refs.table.refresh();
        },
        batchEdit() {
            let num = this.selection.length;
            if (!num) {
                this.$message.error('至少选择一条任务');
                return;
            }
            //处理数据
            let selection = this.selection.map(el => {
                return {
                    name:el[this.nameProp],
                    employee:el[this.employeeProp],
                    parentList:this.data.name,
                    id:el.id
                }
            })
            this.$refs.batchEdit.open(selection)
        },
        getTaskStatusEnums(){
            getTaskStatusEnums({ type: 'all' }).then(res => {
                this.statusList = res.data;
            })
        }
    },
    created(){
        this.getTaskStatusEnums();
    }


}
</script>

<style lang="scss" scoped>
.indicator-detail-sub {
    height: 100%;
}
</style>