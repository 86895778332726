<template>
  <div class="form-upload-wrapper r-flex r-flex-wrap">
    <div class="form-upload-file-list">
      <div class="image-list">
      <el-upload
        class="upload-demo"
        :show-file-list="false"
        :http-request="handleUpload"
        :headers="fileUploadToken"
        :action="ossUploadUrl"
        :on-success="uploadSuccess"
        :on-error="imageUploadError"
        :accept="imageAccept"
        :before-upload="beforeThumbUpload"
        :on-progress="uploadProgress"
        ref="uploadImage"
        drag
        multiple
      >
        <div>
          <r-icon name="plus" size="32" color="#999"></r-icon>
        </div>
      </el-upload>
        <div
          class="image-item"
          v-for="(item, index) in imageValues"
          :key="item?.url || item?.path || item"
          @click="previewImageShow(value, index)"
          :id="link ? link+'-'+(index+1) : ''"
        >
          <el-image
            style="width: 100%; height: 100%"
            :src="(item?.path || item?.url || item) | imgUrl"
            fit="cover"
          ></el-image>
          <div class="close-btn" @click.stop="thumbRemove(index)">
            <i class="el-icon-close"></i>
          </div>
        </div>
      </div>
    </div>
    <image-show
      v-model="imagePreview"
      :imageList="imageList"
      :imageIndex="imageIndex"
    ></image-show>
  </div>
</template>
<script>
import { cloneDeep, isArray ,sortBy} from "@/utils";
import imageUpload from "@/mixins/imageUpload";
import imageShow from "@/components/imageShow";
import upload from '@/utils/ossUpload';
export default {
  props: ["value",'label','link'],
  mixins: [imageUpload],
  components:{imageShow},
  data() {
    return {
      imagePreview: false,
      imageIndex: 0,
      imageList:[],
      ossUploadUrl: process.env.VUE_APP_IMG_URL,
      count:0
    };
  },
  filters: {
    imgUrl(value) {
      if (!value) return "";
      if (value.indexOf("http:") != -1 || value.indexOf("https:") != -1) {
        return value;
      } else {
        return process.env.VUE_APP_IMG_URL + value;
      }
    },
  },
  computed: {
    imageValues() {
      return isArray(this.value) ? this.value : [];
    },
    title() {
      return this.label || "图片";
    },
  },
  methods: {
    uploadSuccess(res,file,fileList) {
      if(!res?.url){
        this.$message.error('图片上传失败，请稍后重试');
        this.updateProgress(file.uid,'fail');
        return false;
      }
      this.updateProgress(file.uid,'success',()=>{
        let list = isArray(this.value) ? cloneDeep(this.value) : [];
        //只取出成功的
        let uploadList = fileList.filter(el=>{
          return el.status == 'success';
        })
        uploadList = uploadList.map(el=>{
          return el.response.url.replace('http:','https:').replace(process.env.VUE_APP_IMG_URL,'');
        })
        list.push(...uploadList);
        this.$emit("input", list);
      });
    },
    thumbRemove(index) {
      let list = cloneDeep(this.value);
      list.splice(index, 1);
      this.$emit("input", list);
    },
    //图片预览
    previewImageShow(list, index) {
      this.imageList = list.map((el) => {
        return el.indexOf("http:") != -1 || el.indexOf("https:") != -1
          ? el
          : process.env.VUE_APP_IMG_URL + el;
      });
      this.imageIndex = index;

      this.$nextTick(() => {
        this.imagePreview = true;
      });
    },
    // 自定义上传操作
    handleUpload(op) {
      let dir = `images`
      upload(
        op.file,
        res => {
          let temp = {
            name: res.attachment,
            url: res.host + '/' + res.aliyunAddress
          }
          this.host = res.host
          op.onSuccess(temp)
        },
        err => {
          console.log(err);
          op.onError(err)
        },
        res => {
          op.onProgress(res)
        },
        dir
      )
    }
  }
};
</script>

<style lang="scss" scoped>
.form-upload-wrapper {
  ::v-deep .el-upload{
    margin-right: 10px;
    margin-bottom: 10px;
  }
  ::v-deep .el-upload-dragger {
    width: 100px;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    .el-icon-upload {
      margin: 12px 0 0;
      font-size: 45px;
    }
    .el-upload__text {
      font-size: 12px;
      color:#999999;
    }
  }
  .file-list,
  .image-list {
    padding-top: 10px;
  }
  .file-item {
    .file-item-cover {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      height: 40px;
      line-height: 40px;
      margin-bottom: 2px;
      background: rgba(0, 0, 0, 0.04);
      color: #666666;
      font-size: 14px;
      padding: 0 10px;
      cursor: pointer;
      .c-icon {
        width: 40px;
        height: 40px;
        line-height: 40px;
        text-align: center;
        font-size: 16px;
        padding-right: 5px;
        color: rgb(255, 74, 74);
        opacity: 0.8;
        &:hover {
          opacity: 1;
        }
      }
    }
  }
  .image-list {
    display: flex;
    flex-wrap: wrap;
  }

  .image-item {
    width: 100px;
    height: 100px;
    margin-right: 10px;
    margin-bottom: 10px;
    border-radius: 5px;
    cursor: zoom-in;
    overflow: hidden;
    position: relative;
    .close-btn {
      position: absolute;
      width: 80px;
      height: 40px;
      right: -70px;
      top: -32px;
      background: rgba(0, 0, 0, 0.6);
      text-align: center;
      padding-top: 8px;
      padding-left:12px;
      transform: rotate(45deg);
      cursor: pointer;
      transition: transform 0.3s;
      i {
        font-size: 20px;
        color: #fff;
        transform: rotate(-45deg);
      }
    }

    &:hover {
      .close-btn {
        transform: translateY(20px) translateX(-45px) rotate(45deg);
      }
    }
  }
}
</style>