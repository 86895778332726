<template>
<div class="form-upload-wrapper">
    <el-upload
    class="upload-demo"
    :http-request="handleUpload"
    :show-file-list	="false"
    :headers="headers"
    :action="ossUploadUrl"
    :on-success="uploadSuccess"
    :on-error="imageUploadError"
    :before-upload="beforeThumbUpload"
    :on-progress="uploadProgress"
    ref="uploadFile"
    multiple>
    <el-button icon="el-icon-upload" type="primary">点击上传{{label}}</el-button>
    </el-upload>
    <div class="form-upload-file-list">
        <div class="file-list">
                    <div class="file-item" v-for="(item,index) in fileList" :key="item.url">
                        <div class="file-item-cover">
                            <span>{{item.name}}</span>
                            <i class="el-icon-delete c-icon" @click="removeFile(index)"></i>
                        </div>
                    </div>
                </div>
    </div>
</div>
</template>
<script>
import {cloneDeep, isArray} from '@/utils';
import upload from '@/utils/ossUpload';
import {guid} from "@/utils";
import bigNumber from 'bignumber.js';
export default {
    props:['value','formData'],
    inject: ['root'],
    data(){
        return{
            imageUploadApi:process.env.VUE_APP_BASE_URL+`/api/upload/index`,
            ossUploadUrl: process.env.VUE_APP_IMG_URL,
            imageMaxSize:20,
            //进度条
            uploadProgressList:{},
            messageId:'',
            messageItem:''
        }

    },
    computed:{
        placeholder(){
            return this.formData?.placeholder || '请选择'
        },
        //需要上传的头部数据
        headers() {
            return {
                Authorization: this.$store.state.userInfo.token || "",
                companyId: this.$store.state.userInfo.companyId || "",
            };
        },
        fileList(){
            return isArray(this.value) ? this.value : [];
        },
        label(){
            return this?.formData?.config?.label || '文件'
        }
    },
    methods: {
        /**
         * 文件上传前的检查
         */
        beforeThumbUpload(file){
            if(!file) return false;
            //判断是否超出限制大小
            if(this.imageMaxSize && file.size && file.size/1024 > this.imageMaxSize * 1024){
                global.setTimeout(()=>{
                    this.$message({
                        type: 'error',
                        message: `${file.name} 大小超出限制，文件大小不能大于 ${this.imageMaxSize < 1 ? parseInt(this.imageMaxSize * 1000) + 'kb' : this.imageMaxSize + 'MB'}`,
                        duration:2500
                    });
                },200)
                return false;
            }

            //所有通过校验的元素添加一个待上传标记
            this.uploadProgressList[file.uid] = 'await'; 

            //此时进行上传处理
            if(!this.messageId){
                this.messageId = guid(20);
            }

        },
        //上传失败提示
        imageUploadError(err,file){
            console.log(err)
            this.updateProgress(file.uid,'fail');
            this.$message({
                message:`${file.name}上传失败，请检查网络是否正常，或稍后重试 ${err}`,
                type:'error'
            });
        },

        //检查返回的数据
        fileUploadCheck(res){
            if(res.code != 200){
                this.$message.error(res?.message);
                //过期
                if(res.code == 401){
                    //清除所有的用户状态
                    this.$store.commit('resetUserInfo');
                    this.$router.replace({path:'/login'});
                }
                return false;
            }
            return true;
        },
        uploadSuccess(res,file){
            if(!res?.url){
                this.$message.error('文件上传失败，请稍后重试');
                this.updateProgress(file.uid,'fail');
                return false;
            }
            this.updateProgress(file.uid,'success');
            let list = isArray(this.value) ?  cloneDeep(this.value) : [];
            let url = res.url.replace('http:','https:').replace(process.env.VUE_APP_IMG_URL,'');
            list.push({
                name:file.name,
                url
            });
            this.$emit('input',list);
        },
        //触发进度条事件
        uploadProgress(process,file,fileList){
            this.progressInit(process,file,fileList)
        },
        removeFile(index){
            let list = cloneDeep(this.value);
            list.splice(index,1);
            this.$emit('input',list);
        },
        // 自定义上传操作
        handleUpload(op) {
        let dir = `images`
        upload(
            op.file,
            res => {
            let temp = {
                name: res.attachment,
                url: res.host + '/' + res.aliyunAddress
            }
            this.host = res.host
            op.onSuccess(temp)
            },
            err => {
            console.log(err)
            op.onError(err)
            },
            res => {
            op.onProgress(res)
            },
            dir
        )
        },
        //触发进度条事件
        uploadProgress(process,file,fileList){
            this.progressInit(process,file,fileList)
        },
        /**
         * 
         * @param {*} process 进度
         * @param {*} file    当前上传的文件
         * @param {*} fileList 文件列表
         * 
         * 根据文件列表和当前文件进度计算出总的进度，当总进度为100时，清除并关闭进度条
         */
        progressInit(process,file,fileList){
            if(file.percentage == 100){
                this.$message.success('文件上传成功');
            }
            let num = bigNumber(0);
            fileList.forEach(el=>{
                num = num.plus(bigNumber(el.percentage))
            });

            num = num.div(bigNumber(fileList.length)).toFixed(2);
            //此时判断是否存在标签
            let message = document.getElementById(this.messageId);
            let messageText = document.getElementById(`${this.messageId}-message`);
            //存在就更新
            if(message){
                messageText.innerText = `共${fileList.length}个，当前进度：${num}%`;
                message.style.width = `${num}%`
            }else{
                this.messageItem = this.$notify({
                    title: '文件上传中',
                    dangerouslyUseHTMLString: true,
                    message: `<div class="notify-progress">
                        <p id="${this.messageId}-message">共${fileList.length}张</p>
                        <div class="notify-progress-block">
                            <div class="notify-progress-block-line" id="${this.messageId}">
                        </div>
                    </div>`,
                    duration: 0,
                    position:'top-right',
                    showClose:false,
                    iconClass:"el-icon-loading"
                });
            }
        },
        progressClose(){
            this.messageItem?.close && this.messageItem.close();
            let list = Object.keys(this.uploadProgressList);
            let success = 0;
            let fail = 0;
            list.forEach(el=>{
                if(this.uploadProgressList[el] == 'success'){
                    success++;
                }else if(this.uploadProgressList[el] == 'fail'){
                    fail++;
                }
            })
            this.$notify({
                title: `文件上传${list.length == success ? '全部成功' : list.length != fail ? '部分成功' : '全部失败'}`,
                message: `此次共上传${list.length}个，成功${success}个，失败${fail}个`,
                position:'top-right',
                duration:3000,
                type:list.length == fail ? 'error' : 'success'
            });
            //清空上传状态对象
            this.uploadProgressList = {};
        },
        /**
         * 针对上传进度发起更新
         * @param {Number|String} uid 上传文件的uid
         * @param {Boolean} status 当前文件的上传状态 await , success , fail
         */
        updateProgress(uid,status){
            //成功以后剔除对应的uid
            this.uploadProgressList[uid] = status;
            //检查是否还有未上传完毕的文件
            let check = Object.keys(this.uploadProgressList).filter(el=>{
                return this.uploadProgressList[el] == 'await'
            });

            if(check.length == 0){
                this.progressClose();
                //清空掉所有已上传文件列表
                this.$refs?.uploadFile?.clearFiles && this.$refs?.uploadFile?.clearFiles();
            }
        }
    }
}
</script>

<style lang="scss" scoped>
    .form-upload-wrapper{
        ::v-deep .el-upload-dragger{
            width: 240px;
            height:120px;
            .el-icon-upload{
                margin: 12px 0 8px;
                font-size:45px;
            }
            .el-upload__text{
                font-size:12px;
            }
        }
        .file-list,.image-list{
            padding-top:10px;
        }
        .file-item{
           .file-item-cover{
               display:flex;
               justify-content: space-between;
               align-items: center; 
               width: 100%;
               height:40px;
               line-height:40px;
               margin-bottom:2px;
               background: rgba(0,0,0,.04);
               color:#666666;
               font-size:14px;
               padding: 0 10px;
               cursor: pointer;
               span{
                flex:1 1 auto;
                width: 0;
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
               }
               .c-icon{
                   width: 40px;
                   height:40px;
                   line-height:40px;
                   text-align: center;
                   font-size:16px;
                   padding-right:5px;
                   color:rgb(255, 74, 74);
                   opacity: .8;
                   &:hover{
                       opacity: 1;
                   }
               }
           }
        }
    }
</style>