<template>
    <tag-select multiple v-model="selectValue" :labelKey="labelKey" :collapseTags="collapseTags" :placeholder="placeholder" :max="max" @focus="focus" @remove-tag="removeTag" :closable="itemClosable" :valueKey="valueKey" :size="size" :disabled="disabled" :autoSize="autoSize"></tag-select>
</template>
<script>
import {isArray} from "@/utils";
import tagSelect from './tag-select';
export default {
    name:"rTagSelect",
    components:{
        tagSelect
    },
    props:{
        value:{
            type:Array,
            default:[]
        },
        labelKey:{
            type:String,
            default:'label'
        }, 
        valueKey:{
            type:String,
            default:'id'
        },
        collapseTags:{
            type:Boolean,
            default:false
        },
        placeholder:{
            type:String,
            default:''
        },
        max:{
            type:Boolean,
            default:false
        },
        closable:{
            type:Boolean,
            default:true
        },
        readonly:{
            type:Boolean,
            default:false
        },
        size:{
            type:String,
            default:''
        },
        disabled:{
            type:Boolean,
            default:false
        },
        autoSize:{
            type:Boolean,
            default:false
        }
    },

    computed:{
        selectValue:{
            get(){
                return isArray(this.value) ? this.value : [];
            },
            set(val){
                this.$emit('input',val);
            }
        },
        itemClosable(){
            if(this.readonly){
                return false;
            }
            if(this.disabled){
                return false;
            }
            return this.closable;
        }
    },

    methods:{
        focus(){
            if(this.disabled || this.readonly) return;
            this.$emit('focus');
        },
        removeTag(tag){
            this.$emit('remove-tag',tag);
        }
    }

}
</script>

<style lang="scss" scoped>
    .max{
        width: 100%;
    }
</style>