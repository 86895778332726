<template>
    <div class="kpi-wrapper r-page">
        <div class="r-filters">
            <div class="r-search">
                <el-form size="small" inline @submit.native.prevent>
                    <el-form-item>
                        <el-input placeholder="请输入分类名称" v-model="query.keyword" @keydown.enter.native="search"
                            clearable></el-input>
                    </el-form-item>
                    <el-form-item>
                        <el-button type="primary" @click="search" icon="el-icon-search">搜索</el-button>
                    </el-form-item>
                </el-form>
            </div>
            <div class="r-toolbar">
                <el-button icon="el-icon-plus" size="small" type="primary" @click="add">新增分类</el-button>
            </div>
        </div>
        <div class="r-content">
            <r-table ref="table" :loader="loader" :columns="columns" height="100%"
                :cell-class-name="cellClassName" row-key="id" @sortChange="sortChange"
                :tree-props="{children: 'children', hasChildren: 'hasChildren'}">
                <template v-slot:action="{row}">
                    <el-link type="primary" @click="add(row.id)">新建子分类</el-link>
                    <el-divider direction="vertical"></el-divider>
                    <el-link type="primary" @click="edit(row)">编辑</el-link>
                    <el-divider direction="vertical"></el-divider>
                    <el-popconfirm title="确定要删除该分类吗？" @confirm="del(row)">
                        <el-link type="primary" slot="reference">删除</el-link>
                    </el-popconfirm>
                </template>
            </r-table>
        </div>
        <category-update ref="categoryUpdate" @confirm="refresh"></category-update>
    </div>
</template>
<script>
import { questionCategoryList,questionCategoryDelete} from "@/api/question";
import {  cloneDeep, isObjectNone } from "@/utils";
import categoryUpdate from "./components/update-category";
import { isObject } from "lodash";
const defaultQuery = {
    keyword: ''
}
export default {
    components:{
        categoryUpdate
    },
    data() {
        return {
            query: cloneDeep(defaultQuery),
            searchQuery: cloneDeep(defaultQuery),
            columns:[
                {
                    label:'问题名称',
                    prop:'title',
                    'min-width':'300px'
                },
                {
                    label:'创建时间',
                    prop:'createdAt',
                    'width':'200px'
                },
                {
                    label:'操作',
                    prop:'action',
                    'width':'200px'
                }
            ],
        }
    },
    methods: {
        loader(page, pageSize) {
            let form = this.getFormData();
            return questionCategoryList({ page, pageSize, ...form }).then(res => {
                return res.data;
            })
        },
        refresh(page) {
            this.$refs.table.refresh(page || '');
        },
        search() {
            this.$refs.table.refresh(1);
        },
        cellClassName({ row, column }) {
            return  column.property == 'title' ? 'hover--opacity' : '';
        },
        sortChange(order) {
            this.query = Object.assign(this.query, order);
            this.$nextTick(() => {
                this.search();
            })
        },
        tableRefresh() {
            this.$refs.table.columnsRefresh();
        },
        getFormData(){
            let form = cloneDeep(this.query);
            return form;
        },
        add(pid=''){
            if(isObject(pid)){
                pid = '';
            }
            this.$refs.categoryUpdate.open({pid});
        },
        edit(row){
            this.$refs.categoryUpdate.open(row,'编辑分类');
        },
        del(row){
            questionCategoryDelete({id:row.id}).then(res=>{
                this.$message.success('删除成功');
                this.refresh();
            })
        }
    },
    created() {
        if (!isObjectNone(this.$route.params)) {
            this.query = Object.assign(this.query, this.$route.params);
        };
    }

}
</script>

<style lang="scss" scoped>
.kpi-wrapper {
    .item-menu {
        opacity: 0;
        transition: opacity .2s;
        padding: 0px 5px;
        color: var(--primary);
    }

    .sx-btn {
        padding: 7px 15px;

        &.active {
            border-color: var(--primary);
            color: var(--primary);
        }
    }

    .table-expend {
        &.menu-show {
            .item-menu {
                opacity: 1;
            }
        }

        &:hover {
            .item-menu {
                opacity: 1;
            }
        }
    }

    .count-wrapper {
        font-size: 13px;
    }
}
</style>