<template>
  <div class="dialog__wrapper">
    <el-dialog
      :title="dialogTitle"
      :visible.sync="dialogShow"
      :destroy-on-close="true"
      custom-class="disabled-none"
      :top="top"
      :modal="true"
      :append-to-body="true"
      :close-on-click-modal="false"
      :props="{ label: 'name' }"
      width="60vw"
      @close="onClose(false)"
      @closed="onClosed"
      @open="handleOpen"
    >
      <div class="employee-wrapper">
        <div class="hd">
          <el-form inline size="mini" @submit.native.prevent>
            <el-form-item>
              <el-input
                placeholder="请输入人员名称"
                v-model="keyword"
                @input="searchLoop"
                @keydown.enter.native="modalSearch"
                clearable
              ></el-input>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="modalSearch">查询</el-button>
            </el-form-item>
            <el-form-item class="confirm">
              <el-button type="primary" @click="confirm">确认选择</el-button>
            </el-form-item>
          </el-form>
        </div>
        <div class="bd">
          <div class="employee-table">
            <my-table
              fit
              ref="table"
              :columns="columns"
              :page-size="20"
              :loader="loader"
              :pagination="{
                layout: 'total, ->, prev, pager, next',
              }"
              size="mini"
              border
              class="emp-table"
              :class="{ radio: selectType == 'radio' }"
              @select="selectSection"
              @select-all="selectSectionAll"
              :auto="false"
            >
              <template v-slot:nickname="{ row }">
                <span
                >{{
                    row.nickname
                  }}<span v-if="row.status == -1" class="quit"
                  >(离职)</span
                  ></span
                >
              </template>
            </my-table>
          </div>
          <div class="employee-selected">
            <div class="select-group">
              <p>
                已选人员<span v-if="selection.length"
              >（{{ selection.length }}人）</span
              >：
              </p>
              <div class="employee-selected-list">
                <transition-group name="list-complete" tag="div">
                  <div
                    class="item list-complete-item"
                    v-for="(item, index) in selection"
                    :key="index"
                  >
                    <div class="name">
                      <div class="avatar">
                        <img :src="item.avatar | $fxyImgUrl" alt=""/>
                      </div>
                      <span>{{ item.nickname || item.name || item.label }}</span>
                    </div>
                    <div class="delete" @click="delItem(index)">
                      <i class="el-icon-delete"></i>
                    </div>
                  </div>
                </transition-group>
              </div>
            </div>
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import dialogMixin from "@/mixins/dialogCommon";
import throttle from "@/mixins/throttle";
import {cloneDeep, isArray, readNodes} from "@/utils";
import {getDepByTree} from "@/api/dep";
import {setKeysValues, setTreeNodeChildrenKeysValues, getTreeNodeAllById, getValues} from "@/utils/arr";

export default {
  mixins: [dialogMixin, throttle],
  props: {
    top: {
      type: String,
      default: "8vh",
    },
    config: {
      type: Object,
      default() {
        return {}
      },
    },
  },
  data() {
    return {
      //当前人员的选择列表
      selection: [],
      //当前部门的选择列表
      depSelection: [],
      keyword: "",
      activeType: "employee",
      loading: false,
      defaultExpandedKeys: [],
      treeData: [],
      depTree: [],
      isHideDep: false
    };
  },
  methods: {
    modalSearch() {
      if (this.activeType == 'employee') {
        this.search();
      } else {
        this.$refs.tree.filter(this.keyword);
      }
    },
    //筛选部门
    filterNode(value, data) {
      if (!value) return true;
      return data.name.indexOf(value) !== -1;
    },
    onClosed() {
      if (this.$refs.table?.list) {
        this.$refs.table.list = [];
      }
      this.selection = [];
      this.depSelection = [];
      this.depTree = [];
      this.treeData = [];
      this.keyword = '';
    },
    handleOpen() {
      if (isArray(this.config.checkList)) {
        this.selection = this.config.checkList.filter(el => {
          return el.type == 2 || !el.type
        });
        this.depSelection = this.config.checkList.filter(el => {
          return el.type == 1
        });
      }
      this.$nextTick(() => {
        this.search();
      });
    },
    loader(page, pageSize) {
      return getEmployeeList({nickname: this.keyword, page, pageSize}).then(
        (res) => {
          res.data.list = res.data.list.map((el) => {
            el.depName = el?.dep?.name;
            return el;
          });
          let invId = setTimeout(() => {
            clearTimeout(invId);
            this.$nextTick(() => {
              this.tableSectionSelectChange();
            });
          }, 50);
          return res.data;
        }
      );
    },
    search() {
      this.$refs.table.refresh(1);
    },
    searchLoop() {
      this.throttle({
        key: "employeeModal",
        time: 400,
        callback: this.modalSearch,
      });
    },
    //选择全部
    selectSectionAll(selection) {
      let _selection = cloneDeep(this.selection);
      if (selection.length == 0) {
        //从选项中剔除当前所有项
        _selection = _selection.filter((el) => {
          return !this.$refs.table.list.find((_el) => {
            return _el.id == el.id;
          });
        });
      } else {
        selection.forEach((el) => {
          let item = _selection.find((_el) => {
            return _el.id == el.id;
          });
          if (!item) {
            _selection.push(el);
          }
        });
      }
      this.selection = _selection;
    },
    //选择人员
    selectSection(selection, row) {
      let _selection =
        this.selectType == "radio" ? [] : cloneDeep(this.selection);
      //判断是否选中
      let checked = selection.includes(row);
      if (checked) {
        _selection.push(row);
      } else {
        _selection = _selection.filter((el) => {
          return el.id != row.id;
        });
      }
      this.selection = _selection;
      if (this.selectType == "radio") {
        this.tableSectionSelectChange();
      }
    },
    delItem(index) {
      this.selection.splice(index, 1);
      this.tableSectionSelectChange();
    },
    tableSectionSelectChange() {
      //提取所有选项id
      let ids = this.selection.map((el) => el.id);
      this.$refs.table.list.forEach((el) => {
        if (ids.includes(el.id)) {
          this.$refs.table.toggleRowSelection(el, true);
        } else {
          this.$refs.table.toggleRowSelection(el, false);
        }
      });
    },
    //生成传递数据
    createConfirmData() {
      let data = this.selection.map((el) => {
        return {
          avatar: el.avatar,
          employeeId: el.id,
          id: el.id,
          name: el.nickname || el.name,
          parentId: "",
          type: 2,
          userId: el.userId,
          contactPhone: el.contactPhone,
        };
      });
      return [...data, ...this.depSelection];
    },
    //确认选择
    confirm() {
      this.config.checkList = this.createConfirmData();
      this.config.list = this.createConfirmData();
      this.$emit("confirm", cloneDeep(this.config));
      //允许刷新
      this.onClose(true);
    },
    handleDeleteNode(node) {
      this.depSelection.forEach((item, index) => {
        if (item.id === node.id) {
          setTreeNodeChildrenKeysValues(this.treeData, item.id, 'disabled', false);
          this.depSelection.splice(index, 1);
        }
      })
      this.$refs.tree.setCheckedNodes(this.depSelection);
    },
    //禁用操作
    selectable(row,index){
      return !this.disabledList.includes(row.id);
    }
  },
  computed: {
    dialogTitle() {
      return this.config?.title || "选择员工";
    },
    selectType() {
      return this.config?.selectType || "checkbox";
    },
    hideDepLock() {
      return !!this.config?.hideDepLock;
    },
    //是否单选人员
    onlyStaff() {
      return !!this.config?.onlyStaff;
    },
    disabledList(){
      return this.config?.disabled || [];
    },
    columns(){
      return [
        {
          type: "selection", 
          align: "center",
          selectable:this.selectable
        },
        {
          label: "员工",
          prop: "nickname",
          align: "center",
          "min-width": "120px",
        },
        {
          label: "所属部门",
          prop: "depName",
          align: "center",
          "min-width": "160px",
        },
        {label: "手机", prop: "phone", align: "center", "min-width": "120px"},
      ]
    }
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-dialog {
  min-width: 800px;
}

.employee-wrapper {
  .bd {
    display: flex;

    overflow: hidden;
  }

  .fxy-icon-wenjianjia {
    margin-right: 4px;
    color: #aee4fa;
    font-size: 17px;
    vertical-align: middle;
  }

  .dep-cover {
    border: 1px solid #eee;
    height: 100%;
    border-radius: 6px;
    overflow: hidden;
    overflow-y: auto;
    padding: 10px;
  }

  .employee-table,
  .employee-selected {
    height: 62vh;
    overflow: hidden;
  }

  .btn-group {
    position: relative;
    top: -1px;
    margin-right: 20px;
  }

  .employee-table {
    flex: 2;

    ::v-deep .el-table--border {
      border-radius: 6px;
    }
  }

  ::v-deep .el-form {
    display: flex;

    .confirm {
      margin-left: auto;
      margin-right: 0;
    }
  }

  .emp-table ::v-deep tr td:first-child .cell {
    padding-left: 14px;
  }

  .quit {
    color: #ff8080;
    opacity: 0.8;
    padding-left: 3px;
  }

  .radio ::v-deep tr th:first-child .cell {
    display: none;
  }

  .employee-selected {
    margin-left: 20px;
    flex: 1;
    border-radius: 6px;
    display: flex;
    flex-direction: column;

    p {
      padding-bottom: 10px;
    }

    .select-group {
      flex: 1;
      display: flex;
      flex-direction: column;

      &:nth-child(2) {
        margin-top: 20px;
      }
    }

    .employee-selected-list {
      flex: 1 1 auto;
      height: 0;
      border: 1px solid #eee;
      border-radius: 6px;
      overflow: hidden;
      overflow-y: auto;
      padding: 10px 0;
      position: relative;

      .item {
        padding: 5px 15px;
        display: flex;
        justify-content: space-between;
        width: 100%;
        align-items: center;

        .name {
          display: flex;
          align-items: center;

          .avatar {
            width: 25px;
            height: 25px;
            margin-right: 8px;

            img {
              width: 100%;
              height: 100%;
              border-radius: 50%;
            }

            &.dep {
              text-align: center;

              i {
                font-size: 20px;
              }
            }
          }
        }

        .delete {
          opacity: 0.6;
          cursor: pointer;

          i {
            font-size: 18px;
            color: rgb(237, 92, 92);
          }

          &:hover {
            opacity: 1;
          }
        }
      }
    }
  }
}
</style>
