<template>
    <div class="kpi-wrapper r-page">
        <div class="r-filters">
            <div class="r-search">
                <!-- <el-form size="small" inline @submit.native.prevent>
                    <el-form-item>
                        <el-input placeholder="请输入关键词" v-model.trim="query.keyword" @keydown.enter.native="search"
                            clearable></el-input>
                    </el-form-item>
                    <el-form-item>
                        <el-button type="primary" @click="search" icon="el-icon-search">搜索</el-button>
                    </el-form-item>
                </el-form> -->
            </div>
            <div class="r-toolbar">
                <el-form inline size="small">
                    <el-form-item>
                        <div class="r-flex">
                            <el-button type="primary" icon="el-icon-plus" @click="add">新建表单</el-button>
                        </div>
                    </el-form-item>
                </el-form>
            </div>
        </div>
        <div class="r-content">
            <div class="template-box">
                <div class="template-item" v-for="item in list" :key="item.id">
                    <div class="group-item-form">
                        <div class="r-flex template">
                            <div class="template-name r-flex-1">
                                <div class="r-flex form-icon">
                                    <div slot="reference" class="bg" style="background-color: rgb(64, 158, 255);"><i
                                            :class="item.icon"></i></div>
                                    <div class="title">
                                        <p class="txt">{{ item.title }}</p>
                                        <el-tooltip effect="dark" :content="item.remark" placement="top-start"
                                            v-if="item.remark">
                                            <p class="remark">{{ item.remark }}</p>
                                        </el-tooltip>
                                    </div>
                                </div>
                            </div>
                            <div class="use r-flex-auto-w">
                                <p class="template-txt">可见范围</p>
                                <div class="range">
                                    <div class="range-content">
                                        <el-popover placement="top-start" title="可见范围" width="600" trigger="hover"
                                            :content="item.permission | permission">
                                            <span class="range-content-cover" slot="reference">{{
                                                item.permission|permission }}</span>
                                        </el-popover>
                                    </div>
                                </div>
                            </div>
                            <div class="time r-flex-1">
                                <p class="template-txt">更新时间</p>
                                <p class="date-value">{{ item.updatedAt }}</p>
                            </div>
                            <div class="operation r-flex">
                                <el-button type="text" class="btn" @click="edit(item)">编辑
                                </el-button>
                                <!-- <div class="splitter"></div>
                                <el-button type="text" class="btn">复制表单
                                </el-button> -->
                                <div class="splitter"></div>
                                <el-button type="text" class="btn" @click="handleDelTemplate(item.id)">删除
                                </el-button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <approveDetail ref="approveDetail" @close="templateClose"></approveDetail>
    </div>
</template>
<script>
import approveDetail from "@/components/approve/createForm";
import { getList,delTemplate,del } from "@/api/approve"
export default {
    components: {
        approveDetail
    },
    filters: {
        permission(val) {
            return val.map(item => item.name || item.nickname).join("、")
        }
    },
    data() {
        return {
            list: []
        }
    },
    methods: {
        add() {
            this.$refs.approveDetail.handleOpen({
                approve: true,
                title:'新建表单'
            })
        },
        getList() {
            getList().then(res => {
                this.list = res.data;
            })
        },
        handleDelTemplate(tempId) {
            this.$confirm(
                ' 已发起的审批单将无法在后台查询,导出和删除，建议先做好备份。审批中的审批单无法继续审批，建议确认该模板无进行中的审批',
                '继续删除？',
                {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning',
                }
            ).then(() => {
                del({ id: tempId }).then((response) => {
                    this.getList();
                    this.$message.success('删除成功');
                });
            });
        },
        edit(item){
            this.$refs.approveDetail.handleOpen({
                approve: true,
                id: item.id,
                title:'编辑表单'
            })
        },
        templateClose(isUpdate){
            console.log(isUpdate)
            if(isUpdate){
                this.getList();
            }
        }
    },
    created() {
        this.getList()
    }
}
</script>

<style lang="scss" scoped>
.kpi-wrapper {
    .item-menu {
        opacity: 0;
        transition: opacity .2s;
        padding: 0px 5px;
        color: var(--primary);
    }

    ::v-deep .el-table__expand-icon {
        display: none !important;
    }

    .template-box {
        .template-item {
            border: 1px solid rgba(17, 31, 44, 0.08);
            margin-bottom: 10px;

            .form-icon {
                margin-right: 20px;

                .bg {
                    line-height: 1;
                    padding: 8px;
                    border-radius: 8px;
                    cursor: pointer;

                    i {
                        font-size: 20px;
                        color: #ffffff;
                    }
                }
            }

            .group {
                padding: 0 20px;
                height: 52px;
                display: flex;
                align-items: center;
                border-bottom: 1px solid rgba(17, 31, 44, 0.08);

                .name {
                    color: #111f2c;
                    font-size: 14px;
                    font-weight: bold;
                }

                .el-dropdown-link {
                    cursor: pointer;
                    color: #111f2c;
                    font-size: 14px;

                    .el-icon-setting {
                        padding-right: 5px;
                        font-size: 16px;
                    }
                }
            }

            .group-item-form {
                padding-left: 20px;

                .template {
                    height: 64px;

                    &:not(:last-child) {
                        border-bottom: 1px solid rgba(17, 31, 44, 0.08);
                    }

                    .template-name {
                        .title {
                            padding-left: 10px;

                            .txt {
                                overflow: hidden;
                                text-overflow: ellipsis;
                                white-space: nowrap;
                                font-size: 14px;
                                color: #111f2c;
                            }

                            .remark {
                                padding-top: 4px;
                                overflow: hidden;
                                max-width: 250px;
                                text-overflow: ellipsis;
                                white-space: nowrap;
                                font-size: 12px;
                                color: rgba(17, 31, 44, 0.72);
                            }
                        }
                    }

                    .date-value {
                        font-size: 12px;
                        color: #111f2c;
                        padding-top: 4px;
                    }

                    .template-txt {
                        font-size: 12px;
                        color: rgba(17, 31, 44, 0.72);
                    }

                    .operation {
                        width: auto;
                        padding: 0 20px;

                        .btn {
                            font-size: 12px;
                            padding: 0;
                        }

                        .splitter {
                            height: 12px;
                            width: 1px;
                            background-color: rgba(17, 31, 44, 0.1);
                            margin: 3px 12px;
                        }
                    }
                }
            }
        }
    }

    .range-content {
        display: block;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        padding-right: 30px;
    }

    .arrow-icon {
        transition: all .3s;
        transform: rotate(0deg);

        &.open {
            transform: rotate(90deg);
        }
    }

    ::v-deep .highlight {
        color: #5eff00;
    }

    .sx-btn {
        padding: 7px 15px;

        &.active {
            border-color: var(--primary);
            color: var(--primary);
        }
    }

    .status-item {
        padding: 12px 10px;
        width: 100%;
    }

    .table-expend {
        &.menu-show {
            .item-menu {
                opacity: 1;
            }
        }

        &:hover {
            .item-menu {
                opacity: 1;
            }
        }
    }

    .count-wrapper {
        font-size: 13px;
    }
}
</style>