<template>
    <div class="kpi-wrapper r-page">
        <div class="r-filters">
            <div class="r-search">
                <el-form size="small" inline>
                    <el-form-item label="" label-width="0">
                        <el-select v-model="query.timeType" @change="timeChange" clearable>
                            <el-option label="发起时间" value="createdAt"></el-option>
                            <el-option label="完成时间" value="completeTime"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="" label-width="0" v-if="query.timeType === 'createdAt'">
                        <div class="r-flex">
                            <el-date-picker
                            v-model="query.createdAt[0]"
                            type="date"
                            value-format="yyyy-MM-dd"
                            placeholder="选择开始日期" @change="search">
                            </el-date-picker>
                            <span class="r-pl-5 r-pr-5">至</span>
                            <el-date-picker
                             @change="search"
                            v-model="query.createdAt[1]"
                            value-format="yyyy-MM-dd"
                            type="date"
                            placeholder="选择结束日期">
                            </el-date-picker>
                        </div>
                    </el-form-item>
                    <el-form-item label="" label-width="0" v-if="query.timeType === 'completeTime'">
                        <div class="r-flex">
                            <el-date-picker
                             @change="search"
                            v-model="query.completeTime[0]"
                            value-format="yyyy-MM-dd"
                            type="date"
                            placeholder="选择开始日期">
                            </el-date-picker>
                            <span class="r-pl-5 r-pr-5">至</span>
                            <el-date-picker
                             @change="search"
                            v-model="query.completeTime[1]"
                            value-format="yyyy-MM-dd"
                            type="date"
                            placeholder="选择结束日期">
                            </el-date-picker>
                        </div>
                    </el-form-item>
                    <el-form-item label="申请人">
                        <r-tag-select v-model="query.applyEmployee" :collapseTags="true" placeholder="请选择申请人"
                            @focus="handleEmployee('checkbox', '请选择申请人', 'applyEmployee')" labelKey="name"
                            @remove-tag="search"></r-tag-select>
                    </el-form-item>
                    <el-form-item label="审批状态">
                        <el-select v-model="query.status" @change="search" clearable>
                            <el-option v-for="item in statusOptions" :key="item.value" :label="item.label"
                                :value="item.value"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item>
                        <el-input placeholder="请输入关键词" v-model="query.keyword" @keydown.enter.native="search" clearable>
                            <i slot="suffix" class="el-input__icon el-icon-search hover--opacity" @click="search"></i>
                        </el-input>
                    </el-form-item>
                </el-form>
            </div>
        </div>
        <div class="r-content">
            <r-table ref="table" :loader="loader" height="100%" :columns="columns" :cell-class-name="cellClassName"
                @cell-click="detailOpen" row-key="id" @sortChange="sortChange">
                <template v-slot:status="{ row }">
                    {{ row | filterStatusType }}
                </template>
                <template v-slot:updatedAt="{ row }">
                    {{ row.status == 1 || row.status == 2 ? row.updatedAt : "" }}
                </template>
                <template v-slot:village="{ row }">
                    <span>{{ row.village | villageFilter }}</span>
                </template>
                <template v-slot:action="{ row }">
                    <el-link type="primary" @click='handleDetail(row)'>查看</el-link>
                    <el-divider direction="vertical" v-if="$has('approve/del')"></el-divider>
                    <el-link type="primary" @click='handleDelete(row)' v-if="$has('approve/del')">删除</el-link>
                </template>
            </r-table>
        </div>
        <dep-role-modal :config="modalConfig" v-model="dialogVisible" @confirm="handleConfirm"></dep-role-modal>
        <approveCreate ref="approveCreate"></approveCreate>
        <applyListDialog ref="applyListDialog" @close="applyDetailReset"></applyListDialog>
        <apply-detail-dialog ref="applyDetailDialog" @close="applyDetailReset"
            @resetList="resetList"></apply-detail-dialog>
    </div>
</template>
<script>
import { getApproveDashboard,delApprove } from "@/api/approve";
import DepRoleModal from "@/components/dialog/DepRoleModal";
import approveCreate from "@/components/approve/detail/applyCreateDialog";
import applyListDialog from "@/components/approve/detail/applyListDialog";
import applyDetailDialog from "@/components/approve/detail/applyApproveDetailDialog";
export default {
    components: { DepRoleModal, approveCreate, applyListDialog, applyDetailDialog },
    data() {
        return {
            columns: [
                { label: "操作", prop: "action", width: '120px' },
                { label: "状态", prop: "status", showOverflowTooltip: true },
                { label: "审批编号", prop: "number", width: "200px", showOverflowTooltip: true },
                { label: "村庄", prop: "village", 'min-width':  "150px", showOverflowTooltip: true },
                { label: "表单名称", prop: "title", showOverflowTooltip: true, 'min-width': '140px' },
                { label: "申请人", prop: "employee.nickname", showOverflowTooltip: true },
                {
                    label: "发起时间",
                    prop: "createdAt",
                    sortable: "custom",
                    'min-width': '140px',
                    showOverflowTooltip: true
                },
                {
                    label: "完成时间",
                    prop: "completeTime",
                    sortable: "custom",
                    'min-width': '140px',
                    showOverflowTooltip: true
                },
                {
                    label: "当前审批人",
                    prop: "checkNodeUser",
                    'min-width': '140px',
                    showOverflowTooltip: true
                }
            ],
            modalConfig: {},
            dialogVisible: false,
            query: {
                name: '',
                applyEmployee: [],
                status: '',
                timeType:'createdAt',
                completeTime:['',''],
                createdAt: ["", ""]
            },
            typeOptions: [
                { label: "已发起", value: "STATUS_LAUNCH" },
                { label: "待处理", value: "STATUS_WAIT" },
                { label: "已处理", value: "STATUS_COMPLETE" },
                { label: "抄送给我的", value: "STATUS_CC" },
            ],
            statusOptions: [
                { label: "审批中", value: 0 },
                { label: "通过", value: 1 },
                { label: "驳回", value: 2 },
                { label: "撤回", value: 4 },
            ],
            queryType: "STATUS_WAIT",
            pageList: []
        }
    },
    filters: {
        filterStatusType(val) {
            if (val.status === 0) {
                return "审批中";
            } else if (val.status === 1) {
                return "通过";
            } else if (val.status === 2) {
                return "驳回";
            } else if (val.status === 4) {
                return "已撤回";
            }
        },
        filterType(val, options) {
            let res = options.find((el) => {
                return el.value === val;
            });
            return res.label;
        },
        villageFilter(val){
            let _val = val || [];
            return _val.map(item => item?.label || item).join('/');
        }
    },
    methods: {
        approveUpdate(isUpdate) {
            if (isUpdate) {
                this.$refs.table.refresh();
            }
        },
        loader(page, pageSize) {
            return getApproveDashboard({ page, pageSize, ...this.query }).then(res => {
                this.queryType = this.query.type;
                this.pageList = res.data.list;
                return res.data;
            })
        },
        refresh(page) {
            this.$refs.table.refresh(page);
        },
        search() {
            this.$refs.table.refresh(1);
        },
        add() {
            this.$refs.update.open();
        },
        handleChangeSelect(selectType, title, model) {
            this.modalConfig = {
                type: 1,
                selectType,
                title,
                checkStrictly: true,
                model,
                checkList: this.query[model] || [],
            };
            this.dialogVisible = true;
        },
        handleEmployee(selectType, title, model) {
            this.modalConfig = {
                type: 1,
                selectType,
                title,
                checkStrictly: true,
                model,
                checkList: this.query[model] || [],
                isStaff: 1,
                onlyStaff: 1
            };
            this.dialogVisible = true;
        },
        handleConfirm(val) {
            this.query[this.modalConfig['model']] = val;
            this.dialogVisible = false;

            this.search();
        },
        cellClassName({ row, column }) {
            return column.property == 'name' ? 'hover--opacity' : '';
        },
        detailOpen(row, column) {
            if (column.property != 'name') return;
            this.$refs.applyDetail.open(row.id);
        },
        sortChange(order) {
            this.query = Object.assign(this.query, order);
            this.$nextTick(() => {
                this.search();
            })
        },
        handleDetail(row) {
            this.$refs.applyDetailDialog.handleOpen({ id: row.id, list: this.pageList })
        },
        add() {
            this.$refs.applyListDialog.handleOpen();
        },
        applyDetailReset(update) {
            if (update) {
                this.search();
            }
        },
        resetList() {
            this.$refs.table.refresh();
        },
        handleDelete(row) {
            this.$confirm(
                "此操作为删除申请单，审批编号为【" +
                row.number +
                "】的申请单, 是否继续?",
                "删除提示",
                {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning",
                }
            )
                .then(() => {
                    delApprove({
                        approveId: row.id,
                    })
                        .then((response) => {
                            this.$refs.table.refresh(1);
                            this.$message.success("删除成功");
                        })
                        .catch(() => {
                            this.loading = false;
                        });
                })
                .catch(() => { });
        },
        timeChange(val){
            if(val == 'completeTime'){
                this.query.createdAt = ['',''];
            }else{
                this.query.completeTime = ['',''];
            }
        }
    }


}
</script>

<style lang="scss" scoped>
::v-deep .el-form-item--mini.el-form-item, .el-form-item--small.el-form-item{
    margin-bottom:10px;
}
::v-deep .el-input--suffix .el-input__inner {
    padding-right:55px;
}
</style>