<template>
    <div class="map-dialog">
        <r-dialog ref="tDialog" :dialog-title="dialogTitle" size="900px" @opened="init" @close="resetForm" @submit="handleSubmit">
            <div class="map-address t-flex"><el-input type="textarea" resize="none" v-model="form.address"
                    id="mapDialogPickerInput" ref="focus5" placeholder="拖动地图获取详细地址"></el-input></div>
            <div class="map-container">
                <div id="map-select-container" class="map-container"></div>
            </div>
        </r-dialog>
    </div>
</template>
<script>
import { cloneDeep } from "@/utils";

import MapLoader from "@/utils/MapLoader";
export default {
    data() {
        return {
            form: {
                latitude: '',
                longitude: '',
                address: ''
            },
            dialogTitle: '定位范围选择',
            mapLoaded: false,
            map: null,
            mapInit:false,
            success:null
        }
    },
    methods: {
        //地图初始化
        init() {
            if (!this.mapLoaded) {
                MapLoader()
                    .then((AMap) => {
                        this.mapLoaded = true;
                        this.initMap();
                    })
                    .catch((e) => { });
            }else{
                this.initMap();
            }
        },
        poiPickerReady(PositionPicker) {
            let positionPicker = new PositionPicker({
                mode: "dragMap",
                map: this.map,
            });
            positionPicker.on("success", (positionResult) => {
                if (!this.mapInit) {
                    this.mapInit = true;
                    return;
                }
                this.$set(this.form, "address", positionResult.address);
                this.$set(this.form, "latitude", positionResult.position.lat);
                this.$set(this.form, "longitude", positionResult.position.lng);
            });

            positionPicker.on("fail", function (positionResult) { });
            positionPicker.start();
        },
        PoiPicker(PoiPicker) {
            const poiPicker = new PoiPicker({
                city: "海口",
                input: "mapDialogPickerInput",
            });
            const marker = new window.AMap.Marker();
            //选取了某个POI
            poiPicker.on("poiPicked", (poiResult) => {
                let poi = poiResult.item;
                marker.setPosition(poi.location);
                this.map.setCenter(marker.getPosition());
            });
        },
        open(options) {
            let { form, dialogTitle,success } = options || {};
            if (form) {
                this.form = Object.assign(this.form, form);
            }
            if (dialogTitle) {
                this.dialogTitle = dialogTitle;
            }
            if(success){
                this.success = success;
            }
            this.$refs.tDialog.handleOpen();
        },
        resetForm() {
            this.form = {
                latitude: '',
                longitude: '',
                address: ''
            };
            this.map = null;
            this.success = null;
            this.mapInit = false;
        },
        initMap() {
            this.$nextTick(() => {
                let config = {
                    zoom: 14,
                };
                if (
                    this.form?.longitude &&
                    this.form?.longitude != "0.000000" &&
                    this.form?.latitude &&
                    this.form?.latitude != "0.000000"
                ) {
                    config.center = [this.form?.longitude, this.form?.latitude];
                }
                // 初始化地图数据
                this.map = new AMap.Map("map-select-container", config);
                this.map.addControl(new window.AMap.Scale());
                this.map.addControl(new window.AMap.ToolBar());
                // map ui
                window.AMapUI.loadUI(["misc/PositionPicker"], this.poiPickerReady);
                window.AMapUI.loadUI(["misc/PoiPicker"], this.PoiPicker);
            })
        },
        handleSubmit(done){
            if(!this.form.address){
                this.$message.error('请拖动地图选择坐标及地址');
                done(false);
                return;
            }
            this.success && this.success(cloneDeep(this.form));
            done(true);
        }
    }
}
</script>

<style lang="scss" scoped>
.map-address {
    font-size: 16px;
    color: #999999;
    padding: 10px 0;
    white-space: nowrap;
}

.map-container {
    height: 400px;
}
</style>