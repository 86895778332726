<template>
  <div class="add-node-btn-box">
    <div class="add-node-btn">
      <el-popover placement="right-start" v-model="visible" :disabled="workflowDisabled || node.type == 8 || node.type == 7">
        <div class="add-node-popover-body">
          <div class="add-node-popover-item approver" @click="addType(1)">
            <div class="r-flex">
              <div class="item-wrapper">
                <span class="iconfont"></span>
              </div>
              <div class="add-node-popover-item_txt">审批人</div>
            </div>
          </div>
          <div class="add-node-popover-item notifier" @click="addType(2)">
            <div class="r-flex">
              <div class="item-wrapper">
                <span class="iconfont"></span>
              </div>
              <div class="add-node-popover-item_txt">抄送人</div>
            </div>
          </div>
          <div class="add-node-popover-item handle" @click="addType(3)" v-if="!scoreLock && nodeConfig.type == 1 && !isApprove">
            <div class="r-flex">
              <div class="item-wrapper">
                <i class="fxyfont fxy-icon-zhihangren-01-copy"></i>
              </div>
              <div class="add-node-popover-item_txt">评分人</div>
            </div>
          </div>
          <!--          <div class="add-node-popover-item condition" @click="addType(4)">-->
          <!--            <div class="r-flex">-->
          <!--              <div class="item-wrapper">-->
          <!--                <span class="iconfont"></span>-->
          <!--              </div>-->
          <!--              <div class="add-node-popover-item_txt">条件分支</div>-->
          <!--            </div>-->
          <!--          </div>-->
        </div>
        <div class="btn" :class="{disabled:workflowDisabled || node.type == 8 || node.type == 7}" type="button" slot="reference">
          <span class="iconfont"></span>
        </div>
      </el-popover>
    </div>
  </div>
</template>
<script>
export default {
  name: 'addNode',
  props: ["childNodeP","node"],
  data() {
    return {
      visible: false
    }
  },
  inject: ['id','task','approve'],
  computed:{
    workflowDisabled(){
      return !this.$store.state.approve?.editStatus?.workflow;
    },
    scoreLock(){
      return !!this.$store.state.approve?.scoreLock;
    },
    nodeConfig(){
      return this.node || {};
    },
    isApprove(){
      return !!this.approve?.approve;
    }
  },
  methods: {
    findScore(childNode) {
      if (childNode.type == 5) {
        return true
      }
      if (childNode.childNode != undefined) {
        return this.findScore(childNode.childNode)
      }
      return false
    },
    addType(type) {
      this.visible = false;
      if (type != 4) {
        var data;
        if (type == 1) {
          data = {
            "nodeName": "审核人",
            "error": true,
            "type": 1,
            "setType": 1,
            "selectMode": 0,
            "selectRange": 0,
            "directorLevel": 1,
            "examineMode": 1,
            "noHanderAction": 1,
            "examineEndDirectorLevel": 0,
            "childNode": this.childNodeP,
            "nodeUserList": []
          }
        } else if (type == 2) {
          data = {
            "nodeName": "抄送人",
            "type": 2,
            "ccSelfSelectFlag": 1,
            "childNode": this.childNodeP,
            "nodeUserList": []
          }
        } else if (type == 3) {
          if (this.$store.state.approve.form?.workflow.nodeConfig?.childNode) {
            let childNode = this.$store.state.approve.form?.workflow.nodeConfig?.childNode
            if(this.findScore(childNode)){
             return this.$message.error('评分人节点只能添加一次！');
            }
          }
          data = {
            "nodeName": "评分人",
            "error": true,
            "type": 5,
            "setType": 1,
            "selectMode": 0,
            "selectRange": 0,
            "directorLevel": 1,
            "examineMode": 2,
            "noHanderAction": 1,
            "examineEndDirectorLevel": 0,
            "childNode": this.childNodeP,
            "nodeUserList": []
          }
        }
        this.$emit("update:childNodeP", data)
      } else {
        this.$emit("update:childNodeP", {
          "nodeName": "路由",
          "type": 4,
          "childNode": null,
          "conditionNodes": [{
            "nodeName": "条件1",
            "error": true,
            "type": 3,
            "priorityLevel": 1,
            "conditionList": [],
            "nodeUserList": [],
            "childNode": this.childNodeP,
          }, {
            "nodeName": "条件2",
            "type": 3,
            "priorityLevel": 2,
            "conditionList": [],
            "nodeUserList": [],
            "childNode": null
          }]
        })
      }
    }
  }
}
</script>
<style lang="scss" scoped>

.fxyfont {
  font-size: 16px;
  line-height: 40px;
}
</style>
