/**
 * 
 * 节流通用函数mixin
 * 
 */


 const throttleMixin = {


    data(){

        return{

            throttleInv:{}

        }


    },


    methods: {
        
        /**
         * 
         * 节流使用的key 此值是唯一的，字符串
         * 
         *  time 节流时间 默认300
         * 
         *  执行的函数
         * 
         */

        throttle({key,time,callback}){

            if(!key){
  
              console.error('节流必须存在key值');
  
              return;
  
            }
  
            if(this.throttleInv[key]){
  
              global.clearTimeout(this.throttleInv[key]);
  
            }
  
            this.throttleInv[key] = global.setTimeout(()=>{
  
              callback && callback();
  
            },time || 200)
  
        }


    },


 }



 export default throttleMixin;
