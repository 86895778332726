<template>
<div class="form-number-wrapper">
    <el-input :disabled="disabled" :value="numberValue" @input="setValue" :placeholder="placeholder"></el-input>
</div>
</template>
<script>

export default {
    inject: ['root'],
    props:{
        value:{
            default:''
        },
        formData:{
            type:Object,
            default:()=>({})
        }
    },
    computed:{
        numberValue(){
            return this.formData?.realityValue ||  this.value;
        },
        disabled(){
            return this.formData?.config?.field == 'duration' || !!this.formData?.config?.expandParams?.disabled;
        },
        placeholder(){
            return this.formData?.config?.field == 'duration' ? '自动获取' : this.formData?.placeholder ? this.formData?.placeholder :  '请输入';
        }
    },
    methods:{
        suiteDataCheck(){
            this.root.suiteDataCheck && this.root.suiteDataCheck(this.formData);
        },
        setValue(val){
            val = val.toString().replace(/[^\d.]/g, "");
            val = /^[0]+\d+/.test(val) ? val.replace(/^[0]+/, "") : val;
            val = val.replace(/\.{2,}/g, ".");
            this.$emit("input", val);
        }
    }
}
</script>

<style lang="less" scoped>

</style>