<template>
<div class="form-checkbox-wrapper">
    <el-checkbox-group v-model="selectValue" @change="suiteDataCheck">
        <el-checkbox :label="item.value" v-for="(item,index) in options" :key="index">{{item.label}}</el-checkbox>
    </el-checkbox-group>
</div>
</template>
<script>
export default {
    props:['value','formData'],
    inject: ['root'],
    computed:{
        selectValue:{
            get(){
                return this.value
            },
            set(val){
                return this.$emit('input',val);
            }
        },
        options(){
            return this.formData?.slot?.options || []
        },
        placeholder(){
            return this.formData?.placeholder || '请选择'
        }
    },
    methods:{
        suiteDataCheck(){
            this.root.suiteDataCheck && this.root.suiteDataCheck(this.formData);
        },
    }
}
</script>

<style lang="scss" scoped>
    ::v-deep .el-select{
        width: 100%;
    }
</style>