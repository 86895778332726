<template>
    <r-drawer ref="rDialog" :dialogTitle="title" size="800px" @close="reset" @submit="submit">
        <div class="dialog-content">
            <el-form size="small" label-position="top" :rules="rules" :model="form" ref="form">
              <el-form-item prop="date" label="收款日期">
                <el-date-picker v-model="form.date" value-format="yyyy-MM-dd" type="date"
                                placeholder="请选收款日期" :disabled="isEdit"
                                :picker-options="{}" :clearable="false">
                </el-date-picker>
              </el-form-item>
              <el-form-item prop="money" label="收款金额">
                <el-input type="number" v-model="form.money" placeholder="请输入收款金额"></el-input>
              </el-form-item>
              <el-form-item prop="remark" label="备注">
                <el-input type="textarea" v-model="form.remark" placeholder="请输入备注" :autosize="{ minRows: 6}"></el-input>
              </el-form-item>
              <el-form-item prop="annex" label="附件">
                <multiUploadFile v-model="form.annex" @change="checkFile" :accept="'.jpg, .jpeg, .png, .doc, .xls, .ppt, .pdf, .docx, .xlsx, .pptx'"></multiUploadFile>
              </el-form-item>
            </el-form>
        </div>
    </r-drawer>
</template>
<script>
import { cloneDeep,isArray } from "@/utils";
import {
  contractReceivedSave,
  contractTaskSave,
} from "@/api/contract";
import multiUploadFile from "@/components/imageUpload/multiUploadFile";
const defaultForm = {
    title:'',
    //可见范围人员
    employee: [],
}
export default {
    components: { multiUploadFile },
    data() {
        return {
            form: cloneDeep(defaultForm),
            title:'',
            rules:{
                date:[
                    { required: true, message: '请选择收款日期', trigger: ['change','blur'] }
                ],
                money:[
                    { required: true, message: '请输入收款金额', trigger: ['change','blur'] }
                ]
            },
            modalConfig: {},
            isEdit: false,
            pidLoading: false,
            dialogVisible: false,
        }
    },
    methods: {
        open(form=null,title='新建合同') {
            this.title = title;
            if(form){
                this.form = Object.assign(cloneDeep(defaultForm),form);
            }
            this.$refs.rDialog.handleOpen();
            // if (this.form.id) {
            //   contractDetail({id: this.form.id}).then(res => {
            //     this.form = Object.assign(cloneDeep(defaultForm),res.data);
            //   })
            // }
        },
        reset(){
            this.form = cloneDeep(defaultForm);
        },
        submit(done){
            this.$refs['form'].validate((valid) => {
                if (valid) {
                    let form = cloneDeep(this.form);
                    contractReceivedSave(form).then(res=>{
                        this.$message.success('提交成功');
                        done(true);
                        this.$emit('confirm');
                    })
                    .catch(err=>{
                        done(false);
                    })
                } else {
                    done(false);
                }
            });
        },
        cChange(val){
            console.log(val)
        },
        handleEmployee(selectType, title, model) {
          this.modalConfig = {
            type: 1,
            selectType,
            title,
            checkStrictly: true,
            model,
            checkList: this.form[model] || [],
            isStaff: 1,
            onlyStaff: 1
          };
          this.dialogVisible = true;
        },
        handleConfirm(val) {
          this.form[this.modalConfig['model']] = val;
          this.dialogVisible = false;
        },
        checkFile(){
          this.$refs.form.validateField('annex')
        }
    }
}
</script>

<style lang="scss" scoped>
.dialog-content {
    height: 250px;
}
</style>